require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/1eb59ac2e4e5eddf50a43a2942d476ca/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1eb59ac2e4e5eddf50a43a2942d476ca'] = function fn_1eb59ac2e4e5eddf50a43a2942d476ca(Page_Data) {
var contact;


  Page_Data['residentContactFullNameOne'] = undefined;
  Page_Data['residentContactCellPhoneOne'] = undefined;
  Page_Data['residentContactHomePhoneOne'] = undefined;
  Page_Data['residentContactFullNameTwo'] = undefined;
  Page_Data['residentContactCellPhoneTwo'] = undefined;
  Page_Data['residentContactHomePhoneTwo'] = undefined;
  Page_Data['residentContactFullNameThree'] = undefined;
  Page_Data['residentContactCellPhoneThree'] = undefined;
  Page_Data['residentContactHomePhoneThree'] = undefined;
  Page_Data['residentContactFullNameFour'] = undefined;
  Page_Data['residentContactCellPhoneFour'] = undefined;
  Page_Data['residentContactHomePhoneFour'] = undefined;
  Page_Data['residentContactFullNameFive'] = undefined;
  Page_Data['residentContactCellPhoneFive'] = undefined;
  Page_Data['residentContactHomePhoneFive'] = undefined;

return 
}

})
define('./functions/206a4a5e45efaff98b863be918e683dc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'] = async function fn_206a4a5e45efaff98b863be918e683dc(Page_Data) {
  await (async function(Page_Data) {
  	document.addEventListener('click', e => {

  	  const classes = e.target.className.split(" ");

  	  let checkBoxClicked = false;

  	  for (let i = 0; i < classes.length; i++) {
  	    if (classes[i].substring(0, 3) === "jss") {
  	      checkBoxClicked = true;
  	    }
  	  }

  	  if (!(classes.includes("actionBlock") || classes.includes("actionContent") || classes.includes("actionButton") || classes.includes("actionText") || classes.includes("actionIcon") || classes.includes("bl-checkbox-label"))) {
  	    Page_Data.isUserRecordActionDrawerOpen = false;
  	    Page_Data.isDeviceRecordActionDrawerOpen = false;
  	    Page_Data.isCurfewRecordActionDrawerOpen = false;
  	    Page_Data.isOrganizationRecordActionDrawerOpen = false;
  	    Page_Data.isViewResidentActionDrawerOpen = false;
  	    Page_Data.isDeviceInfoBoxOpen = false;
  	    Page_Data.isResidentInfoBoxOpen = false;
  	    Page_Data.isTimeDrawerOneOpen = false;
  	    Page_Data.isTimeDrawerTwoOpen = false;
  	    Page_Data.isDayDrawerOpen = false;
  	  }

  	  if (!(classes.includes("menuBlock") || classes.includes("menuContent") || classes.includes("menuButton") || classes.includes("menuText") || classes.includes("menuIcon") || classes.includes("menuPicture") || classes.includes("bl-checkbox-label"))) {
  	    Page_Data.isUserMenuOpen = false;
  	  }

  	  if (!(classes.includes("filterBlock") || classes.includes("filterContent") || classes.includes("filterButton") || classes.includes("filterText") || classes.includes("filterIcon") || classes.includes("bl-checkbox-label")) && !checkBoxClicked) {
  	    Page_Data.isResidentRecordFilterDrawerOpen = false;
  	    Page_Data.isDeviceRecordFilterDrawerOpen = false;
  	  }
  	})
  })(Page_Data);

}

})
define('./functions/256db7f8ee15af9d778314d97f563562/code.js', () => { 

BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'] = async function fn_256db7f8ee15af9d778314d97f563562(text) {
return (await (async function(text) {
	const replaceSpecialCharacters = text.replaceAll(/[^a-zA-Z0-9 -]/g, "");

	return replaceSpecialCharacters
})(text))
}

})
define('./functions/2dc3266507a474ba2f5a291d2361a812/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2dc3266507a474ba2f5a291d2361a812'] = async function fn_2dc3266507a474ba2f5a291d2361a812(zipCode) {
var bareNumber, text;


  bareNumber = (await (async function(zipCode) {
  	return zipCode.replace(/[^0-9]+/g, "")
  })(zipCode));
  if (bareNumber.length > 5) {
    return (bareNumber.slice(0, 5));
  } else {
    return bareNumber;
  }

return 
}

})
define('./functions/3552157be2fff99d18ceeb6b2715c8b3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3552157be2fff99d18ceeb6b2715c8b3'] = function fn_3552157be2fff99d18ceeb6b2715c8b3(App_Data) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(App_Data, 'applicationId')) && (getObjectProperty(App_Data, 'residentApplicationName')) && (getObjectProperty(App_Data, 'pillarApplicationName')) && (getObjectProperty(App_Data, 'mdmGroups')) && (getObjectProperty(App_Data, 'mdmToken'))) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/36435bc8e7f437df45a6682ebdbf7388/code.js', () => { 

BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'] = function fn_36435bc8e7f437df45a6682ebdbf7388(email) {
return ((new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$', '')).test(email))
}

})
define('./functions/3c26f63ed44ba06a52e69b84db7a8b7b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3c26f63ed44ba06a52e69b84db7a8b7b'] = function fn_3c26f63ed44ba06a52e69b84db7a8b7b(dob) {
var days, difference, dob, today;


  today = (new Date((new Date())).getTime());
  dob = (new Date(dob).getTime());
  difference = today - dob;
  days = 1000 * 3600 * 24;

return (Math.floor((difference / days) / 365))
}

})
define('./functions/3c37569726d2e37c1909af054741f424/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3c37569726d2e37c1909af054741f424'] = async function fn_3c37569726d2e37c1909af054741f424(id, variant, previousValue) {
var bareNumber;


  bareNumber = (await (async function(phoneNumber) {
  	return phoneNumber.replace(/[^0-9]+/g, "")
  })(id));
  if (variant == 'remove') {
    return bareNumber;
  } else if (variant == 'create') {
    if (bareNumber.length <= 2) {
      return bareNumber;
    } else if (bareNumber.length > 2) {
      return ([bareNumber.slice(0, 2),'-',bareNumber.slice(2, 9)].join(''));
    }
  }

return 
}

})
define('./functions/3db5c5b391911d4a26f66632339fc5e5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3db5c5b391911d4a26f66632339fc5e5'] = async function fn_3db5c5b391911d4a26f66632339fc5e5(App_Data, Page_Data) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Page_Data['hasOnboardStarted'] = undefined;
  Page_Data['getQRCodeError'] = undefined;
  Page_Data['residentIntakeVariant'] = 'device';
  await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(Page_Data, 'selectedItemResident.objectId')),[`pillar`]: (getObjectProperty(Page_Data, 'residentPillarRadio')) }) );
  await BackendlessUI.Functions.Custom['fn_9b44aef25264690eed0615df9af44168'](Page_Data, App_Data, (getObjectProperty(App_Data, 'mdmGroups')));
  if (getObjectProperty(Page_Data, 'isQRSuccessful')) {
    while (!(getObjectProperty(Page_Data, 'hasStoppedPolling'))) {
      try {
        Page_Data['selectedItemResident'] = ((await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(Page_Data, 'selectedItemResident.objectId')),'\''].join(''))).setPageSize(10)))[0]);
        if ((getObjectProperty(Page_Data, 'selectedItemResident.deviceStatus')) == 'ONBOARDSTARTED') {
          Page_Data['hasStoppedPolling'] = true;
          Page_Data['refreshResidentTable'] = true;
          Page_Data['successModalVariant'] = 'assignedDevice';
          Page_Data['isSuccessModalOpen'] = true;
        } else {
          await new Promise(r => setTimeout(r, 3000 || 0));
        }

      } catch (error) {
        Page_Data['hasStoppedPolling'] = true;
        Page_Data['getQRCodeError'] = true;
        if (getObjectProperty(App_Data, 'residentApplicationName')) {
          await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](null, (['Error adding ',(getObjectProperty(App_Data, 'residentApplicationName')).toLowerCase(),' device'].join('')), 'failure');
        }

      }
    }
  }

return 
}

})
define('./functions/3f169535b81df1cad6dabe419d59aab3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3f169535b81df1cad6dabe419d59aab3'] = async function fn_3f169535b81df1cad6dabe419d59aab3(Page_Data, deviceId) {
var response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    Page_Data['deviceStatusError'] = false;
    Page_Data['isDeviceStatusLoading'] = true;
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/DeviceStatus`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(deviceId)));
    if (getObjectProperty(response, 'deviceID')) {
      Page_Data['isDeviceStatusLoading'] = false;
      Page_Data['deviceStatusInfo'] = response;
    } else {
      throw (new Error(''))
    }

  } catch (error) {
    Page_Data['isDeviceStatusLoading'] = false;
    Page_Data['deviceStatusError'] = true;

  }

return 
}

})
define('./functions/419a5da59f8ecf4150695a570f593a75/code.js', () => { 

BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75'] = function fn_419a5da59f8ecf4150695a570f593a75(militaryTime) {
var hour;


  if (militaryTime == 12) {
    return '12:00 PM';
  } else if (militaryTime == 24 || militaryTime == 0) {
    return '12:00 AM';
  } else {
    if (militaryTime == 1 || militaryTime == 13) {
      hour = 1;
    } else if (militaryTime == 2 || militaryTime == 14) {
      hour = 2;
    } else if (militaryTime == 3 || militaryTime == 15) {
      hour = 3;
    } else if (militaryTime == 4 || militaryTime == 16) {
      hour = 4;
    } else if (militaryTime == 5 || militaryTime == 17) {
      hour = 5;
    } else if (militaryTime == 6 || militaryTime == 18) {
      hour = 6;
    } else if (militaryTime == 7 || militaryTime == 19) {
      hour = 7;
    } else if (militaryTime == 8 || militaryTime == 20) {
      hour = 8;
    } else if (militaryTime == 9 || militaryTime == 21) {
      hour = 9;
    } else if (militaryTime == 10 || militaryTime == 22) {
      hour = 10;
    } else if (militaryTime == 11 || militaryTime == 23) {
      hour = 11;
    }
    if (hour) {
      if (militaryTime <= 11) {
        return (String(hour) + String(':00 AM'));
      } else if (militaryTime >= 13) {
        return (String(hour) + String(':00 PM'));
      }
    }
  }

return 
}

})
define('./functions/4d0b6f63e09985a12656f99b68cbc84d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4d0b6f63e09985a12656f99b68cbc84d'] = function fn_4d0b6f63e09985a12656f99b68cbc84d(webAddress) {
return ((new RegExp('^(www\\.)[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$', '')).test(webAddress))
}

})
define('./functions/5da19a0685ee9e7b97339f9e3dd69c3b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b'] = async function fn_5da19a0685ee9e7b97339f9e3dd69c3b(variant, email, orgName, changedValue, selectedFilterArray, userRole, pillar) {
var defaultClause, filterArray, attribute, listOfAttributes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  filterArray = [];
  if (variant == 'resident' && userRole == 'admin') {
    defaultClause = ['orgName = \'',orgName,'\''].join('');
    if (changedValue && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        for (var attribute_index in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index];
          addItemToList(filterArray, ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\''].join(''));
      }
    } else {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        filterArray = [];
        for (var attribute_index2 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index2];
          addItemToList(filterArray, ([defaultClause,' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'resident') {
    defaultClause = ['orgName = \'',orgName,'\''].join('');
    if (changedValue && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        for (var attribute_index3 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index3];
          addItemToList(filterArray, ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\''].join(''));
      }
    } else {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        filterArray = [];
        for (var attribute_index4 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index4];
          addItemToList(filterArray, ([defaultClause,' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'allResidentsInPillar') {
    return (['pillar = \'',pillar,'\' and organizationId.orgName = \'',orgName,'\' '].join(''));
  } else if (variant == 'device') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and deviceIMEI is not null'].join('');
    if (changedValue && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        for (var attribute_index5 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index5];
          addItemToList(filterArray, ([defaultClause,' and deviceIMEI LIKE \'%',changedValue,'%\' and ',attribute].join('')));
          addItemToList(filterArray, ([defaultClause,' and deviceStatus LIKE \'%',changedValue,'%\' and ',attribute].join('')));
          addItemToList(filterArray, ([defaultClause,' and residentId.nameInUse LIKE \'%',changedValue,'%\' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return ([defaultClause,' and deviceIMEI LIKE \'%',changedValue,'%\' OR ',defaultClause,' and deviceStatus LIKE \'%',changedValue,'%\' OR ',defaultClause,' and residentId.nameInUse LIKE \'%',changedValue,'%\''].join(''));
      }
    } else {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        filterArray = [];
        for (var attribute_index6 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index6];
          addItemToList(filterArray, ([defaultClause,' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'user') {
    if (userRole == 'super') {
      defaultClause = ['email LIKE \'%',changedValue,'%\' OR name LIKE \'%',changedValue,'%\' OR userRole LIKE \'%',changedValue,'%\' OR phoneNumber LIKE \'%',changedValue,'%\' OR status LIKE \'%',changedValue,'%\' OR orgName LIKE \'%',changedValue,'%\''].join('');
      if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
        return defaultClause;
      } else {
        return '';
      }
    } else if (userRole == 'admin') {
      listOfAttributes = ['name', 'userRole', 'phoneNumber', 'status', 'inviteStatus', 'email'];
      defaultClause = ['orgName = \'',orgName,'\' and email != \'',email,'\''].join('');
      if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
        for (var attribute_index7 in listOfAttributes) {
          attribute = listOfAttributes[attribute_index7];
          addItemToList(filterArray, ([defaultClause,' and ',attribute,' LIKE \'%',changedValue,'%\''].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'organization') {
    defaultClause = ['orgName LIKE \'%',changedValue,'%\' OR billingEmail LIKE \'%',changedValue,'%\' OR billingName LIKE \'%',changedValue,'%\' OR billingPhone LIKE \'%',changedValue,'%\' OR status LIKE \'%',changedValue,'%\''].join('');
    if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      return defaultClause;
    } else {
      return '';
    }
  } else if (variant == 'pillars') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and pillar = \'',pillar,'\''].join('');
    if (defaultClause) {
      return defaultClause;
    } else {
      return '';
    }
  } else if (variant == 'curfew') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and pillar = \'',pillar,'\''].join('');
    if (defaultClause) {
      return defaultClause;
    } else {
      return '';
    }
  } else if (variant == 'activeCurfew') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and pillar = \'',pillar,'\' and status = \'ACTIVE\''].join('');
    if (defaultClause) {
      return defaultClause;
    } else {
      return '';
    }
  } else if (variant == 'allActiveCurfews') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and status = \'ACTIVE\''].join('');
    if (defaultClause) {
      return defaultClause;
    } else {
      return '';
    }
  }

return 
}

})
define('./functions/67c6f852cf5ce414b74b8f0b9401b852/code.js', () => { 

BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852'] = async function fn_67c6f852cf5ce414b74b8f0b9401b852(senderEmail, senderName, recipientEmail, template_id, dynamic_template_data) {
var error, sendGridResponse;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    sendGridResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/Sendgrid/sendgrid`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'to': [({ [`email`]: recipientEmail })],'from': ({ [`name`]: senderName,[`email`]: senderEmail }),'template_id': template_id,'dynamic_template_data': dynamic_template_data }));
    if ((getObjectProperty(sendGridResponse, 'status')) == 'success') {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    return error;

  }

return 
}

})
define('./functions/6d4580c12cbae4a32f38d054d2dc9e71/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'] = function fn_6d4580c12cbae4a32f38d054d2dc9e71(phoneNumber) {
var phoneNumber;

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (phoneNumber.indexOf('+') !== -1) {
    phoneNumber = phoneNumber.slice(2, phoneNumber.length);
  } else {
    phoneNumber = phoneNumber;
  }
  if (((new RegExp('^[0-9+]+$', '')).test(phoneNumber)) && getValueLength(phoneNumber) == 10) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/71a2dca5099d57aac701dfa99e2c9eb1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'] = function fn_71a2dca5099d57aac701dfa99e2c9eb1(password) {
  if ((new RegExp('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$', '')).test(password)) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/7654dccaafd23dd3a20bcb51bca43a86/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7654dccaafd23dd3a20bcb51bca43a86'] = async function fn_7654dccaafd23dd3a20bcb51bca43a86(email, uniqueId, Page_Data) {
  try {
    await Backendless.Hive('passwordCache').KeyValueStore(email).set(({ [`key`]: uniqueId }), { ttl: 172800, condition: 'Always' });
    return true;

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'Error sending email', 'failure');
    return false;

  }

return 
}

})
define('./functions/77bfd63e533a9b4c5577e5fe31a4b5ca/code.js', () => { 

BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'] = async function fn_77bfd63e533a9b4c5577e5fe31a4b5ca(Page_Data, message, status) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(Page_Data, 'hasToastError'))) {
    Page_Data['hasToastError'] = true;
    Page_Data['isToastVisible'] = true;
    Page_Data['toastMessage'] = message;
    Page_Data['toastClasses'] = ['toast'];
    await new Promise(r => setTimeout(r, 30 || 0));
    Page_Data['toastClasses'] = ['toast', (typeof status === 'string') ? status : '', 'show'];
    await new Promise(r => setTimeout(r, 4000 || 0));
    Page_Data['toastClasses'] = ['toast'];
    await new Promise(r => setTimeout(r, 500 || 0));
    delete Page_Data['toastClasses'];
    delete Page_Data['toastMessage'];
    Page_Data['hasToastError'] = false;
    Page_Data['isToastVisible'] = false;
  }

}

})
define('./functions/7e3d62cb9ecfca7bbe1d85a6a421c66f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7e3d62cb9ecfca7bbe1d85a6a421c66f'] = function fn_7e3d62cb9ecfca7bbe1d85a6a421c66f(pillarStart) {
var days, difference, pillarStart, today;


  today = (new Date((new Date())).getTime());
  pillarStart = (new Date(pillarStart).getTime());
  difference = today - pillarStart;
  days = 1000 * 60 * 60 * 24;

return (Math.ceil(difference / days))
}

})
define('./functions/85382ee7628e1f2abdf9560ba62487cc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_85382ee7628e1f2abdf9560ba62487cc'] = function fn_85382ee7628e1f2abdf9560ba62487cc(Page_Data) {
  Page_Data['isUserMenuOpen'] = false;
  Page_Data['isResidentRecordActionDrawerOpen'] = false;
  Page_Data['isResidentRecordFilterDrawerOpen'] = false;
  Page_Data['isDeviceRecordActionDrawerOpen'] = false;
  Page_Data['isDeviceRecordFilterDrawerOpen'] = false;
  Page_Data['isUserRecordActionDrawerOpen'] = false;
  Page_Data['isUserRecordFilterDrawerOpen'] = false;

}

})
define('./functions/86e80235f72a49fc4fa8cc92d83c4b9e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'] = function fn_86e80235f72a49fc4fa8cc92d83c4b9e(Page_Data) {
  Page_Data['changedValueResident'] = undefined;
  Page_Data['changedValueUser'] = undefined;
  Page_Data['changedValueDevice'] = undefined;
  Page_Data['pageEnter'] = false;
  Page_Data['isViewResidentDetailsOpen'] = undefined;
  Page_Data['isViewDetailsOpen'] = undefined;
  Page_Data['residentDetailsSelection'] = undefined;
  Page_Data['curfewPillarSelectionBar'] = '1';
  Page_Data['supportGuideSelection'] = 'admin';
  Page_Data['noResultsScreen'] = false;

return 
}

})
define('./functions/8d46c0c781a168eb2601b8313797f5df/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8d46c0c781a168eb2601b8313797f5df'] = async function fn_8d46c0c781a168eb2601b8313797f5df(date, variant) {
var bareNumber, text;


  bareNumber = (await (async function(date) {
  	return date.replace(/[^0-9]+/g, "")
  })(date));
  if (variant == 'remove') {
    return bareNumber;
  } else if (variant == 'create') {
    if (date.length == 3 && date.slice(-1) == '/' || date.length == 4 && date.charAt(2) == '/' && bareNumber.length == 2) {
      return (String(bareNumber) + String('/'));
    } else if (bareNumber.length <= 2) {
      return bareNumber;
    } else if (date.length == 6 && date.slice(-1) == '/' && bareNumber.length == 4 || date.length == 7 && date.charAt(5) == '/' && bareNumber.length == 4) {
      return ([bareNumber.slice(0, 2),'/',bareNumber.slice(2, bareNumber.length),'/'].join(''));
    } else if (bareNumber.length == 3 || bareNumber.length == 4) {
      return ([bareNumber.slice(0, 2),'/',bareNumber.slice(2, bareNumber.length)].join(''));
    } else if (false) {
    } else if (false) {
    } else if (false) {
    } else if (bareNumber.length >= 5) {
      return ([bareNumber.slice(0, 2),'/',bareNumber.slice(2, 4),'/',bareNumber.slice(4, 8)].join(''));
    } else if (false) {
    }
  }

return 
}

})
define('./functions/966857bb865419826be74c3759378f6e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_966857bb865419826be74c3759378f6e'] = async function fn_966857bb865419826be74c3759378f6e() {
return (await Backendless.Request.post(`${Backendless.appPath}/services/HeadwindMDM/getUserToken`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send())
}

})
define('./functions/9807a958b9f47818617415e4438b5e47/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47'] = function fn_9807a958b9f47818617415e4438b5e47(string, num) {
function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (string && getValueLength(string) > num) {
    return (String(string.slice(0, num)) + String('...'));
  } else {
    return string;
  }

return 
}

})
define('./functions/98e6d7676a68625bb37c0ec89d66dff7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_98e6d7676a68625bb37c0ec89d66dff7'] = async function fn_98e6d7676a68625bb37c0ec89d66dff7(Page_Data) {
var activeOrganizations, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Page_Data['userRecordsUserRoleOptions'] = [({ [`label`]: 'Administrator',[`value`]: 'admin' }), ({ [`label`]: 'Case Manager',[`value`]: 'manager' })];
  Page_Data['organizationRecordsStateOptions'] = (await Promise.all(('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI WY'.split(' ')).map(async item => {; return ({ [`label`]: item,[`value`]: item });})));
  try {
    activeOrganizations = (await Promise.all((await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause('status = \'ACTIVE\'').setProperties(['orgName', 'objectId']).setPageSize(100))).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'orgName')),[`value`]: (getObjectProperty(item, 'objectId')) });})));
    Page_Data['organizationOptions'] = activeOrganizations;

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](null, 'Error fetching organizations.', 'failure');

  }

return 
}

})
define('./functions/9b44aef25264690eed0615df9af44168/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9b44aef25264690eed0615df9af44168'] = async function fn_9b44aef25264690eed0615df9af44168(Page_Data, App_Data, mdmGroups) {
var mdmGroups, applicationId, mdmToken, deviceId, qrCodeKey, getDeviceResponse, deviceTableResponse, headwindResponse, groupObject, configurationId, headwindData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function createQRCode() {
  try {
    if (getObjectProperty(App_Data, 'currentUser.organizationId.headwindData')) {
      headwindData = (JSON.parse((getObjectProperty(App_Data, 'currentUser.organizationId.headwindData'))));
      qrCodeKey = (getObjectProperty((headwindData[((getObjectProperty(Page_Data, 'residentPillarRadio')) - 1)]), 'qrCodeKey'));
      configurationId = (getObjectProperty((headwindData[((getObjectProperty(Page_Data, 'residentPillarRadio')) - 1)]), 'id'));
      if (qrCodeKey) {
        if (!(getObjectProperty(Page_Data, 'selectedItemResident.deviceId'))) {
          if ((getObjectProperty(App_Data, 'currentUser.organizationId.orgName')) == 'Cyber Dive Debug DND' || (getObjectProperty(App_Data, 'currentUser.userRole')) == 'super') {
            if (applicationId == '27CFF991-B8B6-1C94-FF36-C32CD79DF700') {
              deviceId = String('CD-') + String(generateUUID().long());
            } else if (applicationId == 'E4EE1C33-B133-4862-ABD0-9EC9EEB54D19') {
              deviceId = String('CDS-') + String(generateUUID().long());
            } else if (applicationId == 'D6A48049-5336-470D-8A65-A38826AFB907') {
              deviceId = String('CDPP-') + String(generateUUID().long());
            }
            groupObject = ({ [`id`]: '2',[`name`]: 'CyberDive' });
          } else {
            if (applicationId == '27CFF991-B8B6-1C94-FF36-C32CD79DF700') {
              deviceId = (generateUUID().long());
            } else if (applicationId == 'E4EE1C33-B133-4862-ABD0-9EC9EEB54D19') {
              deviceId = String('CDS-') + String(generateUUID().long());
            } else if (applicationId == 'D6A48049-5336-470D-8A65-A38826AFB907') {
              deviceId = String('CDPP-') + String(generateUUID().long());
            }
            groupObject = ({ [`id`]: (getObjectProperty(mdmGroups, (String(getObjectProperty(App_Data, 'currentUser.organizationId.orgName')) + String('.id')))),[`name`]: (getObjectProperty(App_Data, 'currentUser.organizationId.orgName')) });
          }
          headwindResponse = (await Backendless.Request.put(`${Backendless.appPath}/services/HeadwindMDM/createDevice`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'number': deviceId,'token': mdmToken,'configurationId': configurationId,'groups': [groupObject] }));
          if ((getObjectProperty(headwindResponse, 'status')) == 'OK') {
            deviceTableResponse = (await Backendless.Data.of('Devices').save( ({ [`deviceId`]: deviceId,[`deviceStatus`]: 'ONBOARDPENDING' }) ));
            await Backendless.Data.of('Residents').setRelation((getObjectProperty(Page_Data, 'selectedItemResident.objectId')), 'deviceId', [deviceTableResponse]);
            await Backendless.Data.of('Devices').setRelation(deviceTableResponse, 'organizationId', [(getObjectProperty(App_Data, 'currentUser.organizationId.objectId'))]);
            await Backendless.Data.of('Devices').setRelation(deviceTableResponse, 'residentId', [(getObjectProperty(Page_Data, 'selectedItemResident.objectId'))]);
          } else {
            throw (new Error(''))
          }
        } else {
          getDeviceResponse = (await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/getDeviceInfo`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify(mdmToken),'deviceId': JSON.stringify((getObjectProperty(Page_Data, 'selectedItemResident.deviceId'))) }).send());
          if ((getObjectProperty(getDeviceResponse, 'status')) == 'OK') {
            deviceId = (getObjectProperty(Page_Data, 'selectedItemResident.deviceId'));
          } else {
            throw (new Error(''))
          }
        }
        if (deviceId) {
          Page_Data['QRCodeImage'] = (['https://mdm.aquax.co/rest/public/qr/',qrCodeKey,'?group=',(getObjectProperty(mdmGroups, (String(getObjectProperty(App_Data, 'currentUser.organizationId.orgName')) + String('.id')))),'&create=0&deviceId=',deviceId].join(''));
          Page_Data['refreshUser'] = true;
          Page_Data['refreshResidentTable'] = true;
          Page_Data['refreshResident'] = true;
          Page_Data['getQRCodeError'] = false;
          Page_Data['isLoadingQRCode'] = false;
          Page_Data['isQRSuccessful'] = true;
        }
      }
    } else {
      throw (new Error(''))
    }

  } catch (error) {
    Page_Data['getQRCodeError'] = true;
    Page_Data['isLoadingQRCode'] = false;
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'Error displaying QR code.', 'failure');

  }
}

/**
 * Describe this function...
 */
async function refreshMDMToken() {
  try {
    await BackendlessUI.Functions.Custom['fn_f15eaeb413dd69197945a3bae68dcbde']();
    mdmToken = (getObjectProperty(((await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'mdmToken\'').setPageSize(10)))[0]), 'value'));
    await createQRCode();

  } catch (error) {
    Page_Data['isLoadingQRCode'] = false;
    Page_Data['getQRCodeError'] = true;
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'Error displaying QR code.', 'failure');

  }
}


  Page_Data['getQRCodeError'] = false;
  Page_Data['isLoadingQRCode'] = true;
  mdmToken = (getObjectProperty(App_Data, 'mdmToken'));
  applicationId = (getObjectProperty(App_Data, 'applicationId'));
  mdmGroups = (getObjectProperty(App_Data, 'mdmGroups'));
  try {
    await createQRCode();

  } catch (error) {
    await refreshMDMToken();

  }

return 
}

})
define('./functions/a3492c0a3c3e3c5e02e3736d07768491/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a3492c0a3c3e3c5e02e3736d07768491'] = function fn_a3492c0a3c3e3c5e02e3736d07768491(taxId) {
function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (((new RegExp('^[0-9-]+$', '')).test(taxId)) && getValueLength(taxId) == 10) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/a65e994797048d593245ed62a80fb31f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f'] = function fn_a65e994797048d593245ed62a80fb31f(emailType, linkItems, App_Data) {
var domainName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(App_Data, 'applicationId')) == '27CFF991-B8B6-1C94-FF36-C32CD79DF700') {
    domainName = 'app.aquax.co';
  } else if ((getObjectProperty(App_Data, 'applicationId')) == 'E4EE1C33-B133-4862-ABD0-9EC9EEB54D19') {
    domainName = 'zingylunch-us.backendless.app';
  } else if ((getObjectProperty(App_Data, 'applicationId')) == 'D6A48049-5336-470D-8A65-A38826AFB907') {
    domainName = 'socialdinosaurs-us.backendless.app';
  }
  if (emailType == 'invite') {
    if ((function () { return BackendlessUI.isProd })()) {
      return (['https://',domainName,'/?page=Register&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&adminEmail=',(getObjectProperty(linkItems, 'admin_email')),'&key=',(getObjectProperty(linkItems, 'key'))].join(''));
    } else {
      return (['https://',domainName,'/api/files/ui-builder/containers/default/index.html?page=Register&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&adminEmail=',(getObjectProperty(linkItems, 'admin_email')),'&key=',(getObjectProperty(linkItems, 'key'))].join(''));
    }
  } else if (emailType == 'created') {
    if ((function () { return BackendlessUI.isProd })()) {
      return (['https://',domainName,'/?page=Login'].join(''));
    } else {
      return (['https://',domainName,'/api/files/ui-builder/containers/default/index.html?page=Login'].join(''));
    }
  } else if (emailType == 'forgotPassword') {
    if ((function () { return BackendlessUI.isProd })()) {
      return (['https://',domainName,'/?page=NewPassword&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&key=',(getObjectProperty(linkItems, 'key'))].join(''));
    } else {
      return (['https://',domainName,'/api/files/ui-builder/containers/default/index.html?page=NewPassword&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&key=',(getObjectProperty(linkItems, 'key'))].join(''));
    }
  }

return 
}

})
define('./functions/a7ad4c1fd5d18ca6bff3eb611aeee2ac/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'] = function fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac(string) {
return ((new RegExp('^\\s*$', '')).test(string))
}

})
define('./functions/b9b8c396542304a434a3b2fe738b799a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b9b8c396542304a434a3b2fe738b799a'] = async function fn_b9b8c396542304a434a3b2fe738b799a(App_Data, Page_Data, selectedResident) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Page_Data['residentIntakeForm'] = (JSON.parse((getObjectProperty(selectedResident, 'residentIntakeForm'))));
  if ((getObjectProperty(selectedResident, 'deviceStatus')) == 'ASSIGNED') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Device', ({ [`changedValueDevice`]: (getObjectProperty(selectedResident, 'deviceIMEI')) }));
  } else {
    Page_Data['selectedItemResident'] = selectedResident;
    Page_Data['isNewResident'] = false;
    Page_Data['hasStoppedPolling'] = undefined;
    Page_Data['hasOnboardStarted'] = undefined;
    Page_Data['residentIntakeStep'] = undefined;
    Page_Data['recordTableVariant'] = 'resident';
    Page_Data['isAddRecordModalOpen'] = true;
    Page_Data['hasAcknowledged'] = false;
    Page_Data['scrollToTop'] = true;
    if ((await (async function(string) {
    	const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)

    	return isNumeric(string);
    })((getObjectProperty(selectedResident, 'pillar'))))) {
      Page_Data['residentPillarRadio'] = (getObjectProperty(selectedResident, 'pillar'));
      Page_Data['residentIntakeVariant'] = 'device';
      await BackendlessUI.Functions.Custom['fn_3db5c5b391911d4a26f66632339fc5e5'](App_Data, Page_Data);
    } else {
      Page_Data['residentPillarRadio'] = 1;
      Page_Data['residentIntakeVariant'] = 'pillar';
    }
  }

return 
}

})
define('./functions/be3b54de9a4b651795a9bc82609b657e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_be3b54de9a4b651795a9bc82609b657e'] = async function fn_be3b54de9a4b651795a9bc82609b657e(adminContacts) {
var contact;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (await Promise.all(adminContacts.map(async contact => {  if (getObjectProperty(contact, 'cellPhone')) {
    if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(contact, 'cellPhone')), 'remove'))))) {
      contact['mobileError'] = true;
    }
  }
  if (getObjectProperty(contact, 'homePhone')) {
    if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(contact, 'homePhone')), 'remove'))))) {
      contact['homeError'] = true;
    }
  }
  if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'fullName')))) && (getObjectProperty(contact, 'fullName'))) {
    if (((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'cellPhone')))) || !(getObjectProperty(contact, 'cellPhone'))) && ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'homePhone')))) || !(getObjectProperty(contact, 'homePhone')))) {
      contact['contactError'] = true;
    }
  } else if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'fullName')))) && !(getObjectProperty(contact, 'fullName'))) {
    if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'cellPhone')))) || (getObjectProperty(contact, 'cellPhone')) || !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'homePhone')))) || (getObjectProperty(contact, 'homePhone'))) {
      contact['contactError'] = true;
    }
  }
  if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'fullName')))) && !(getObjectProperty(contact, 'fullName')) && ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'cellPhone')))) || !(getObjectProperty(contact, 'cellPhone'))) && ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(contact, 'homePhone')))) || !(getObjectProperty(contact, 'homePhone')))) {
    contact = false;
  }
; return contact;})))
}

})
define('./functions/bf5f02f26768af5f26df44a3c143b235/code.js', () => { 

BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235'] = async function fn_bf5f02f26768af5f26df44a3c143b235(orgName, pillar, Page_Data) {
var hasNoErrors, fcmResponse, curfewArray, resident, residentArray, curfew, activeCurfews;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    activeCurfews = (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('activeCurfew', null, orgName, null, null, null, pillar))).setPageSize(10)));
    residentArray = (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('allResidentsInPillar', null, orgName, null, null, null, pillar))).setPageSize(10)));
    if (activeCurfews && activeCurfews.length > 0) {
      curfewArray = (await Promise.all(activeCurfews.map(async curfew => {; return ({ [`dayArray`]: (getObjectProperty((JSON.parse((getObjectProperty(curfew, 'days')))), 'dayArray')),[`startTime`]: (getObjectProperty((JSON.parse((getObjectProperty(curfew, 'times')))), 'startTime')),[`endTime`]: (getObjectProperty((JSON.parse((getObjectProperty(curfew, 'times')))), 'endTime')) });})));
      hasNoErrors = true;
      for (var resident_index in residentArray) {
        resident = residentArray[resident_index];
        fcmResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleB2BFireBaseMessaging`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'token': (getObjectProperty(resident, 'FCMToken')),'variant': 'updateCurfew','curfewArray': curfewArray }));
        if ((getObjectProperty(fcmResponse, 'statusCode')) != '200') {
          hasNoErrors = false;
          break;
        }
      }
      if (hasNoErrors) {
        return true;
      } else {
        return false;
      }
    }

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'Failed to refresh curfews.', 'failure');

  }

return 
}

})
define('./functions/c022336da0dd1fcbccef0e5929a35855/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c022336da0dd1fcbccef0e5929a35855'] = async function fn_c022336da0dd1fcbccef0e5929a35855(App_Data) {
var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getCurrentUserInfo() {
  try {
    App_Data['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](null, 'There was a problem loading the user information.', 'failure');

  }
}


  if (!(getObjectProperty(App_Data, 'currentUser'))) {
    currentUser = (await Backendless.UserService.getCurrentUser(false));
    if (!currentUser) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
    } else if (currentUser && (getObjectProperty(currentUser, 'userRole')) == 'super') {
      await getCurrentUserInfo();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', undefined);
    } else {
      await getCurrentUserInfo();
    }
  }

return 
}

})
define('./functions/c16fa4da6fdc2a0ab06c37eb5691c2e2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c16fa4da6fdc2a0ab06c37eb5691c2e2'] = function fn_c16fa4da6fdc2a0ab06c37eb5691c2e2(zip) {
var organizationApiResponse;

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (((new RegExp('^\\d+$', '')).test(zip)) && getValueLength(zip) == 5) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/c66ba9f6afd59bbb0a0624bbe1fb4ed9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'] = async function fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9(Page_Data) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(Page_Data, currentPage) {
  	addEventListener("resize", (event) => {
  	  if(event.currentTarget.innerWidth <= 1024 || event.currentTarget.innerHeight <= 600 ){
  	    Page_Data.switchToDesktop = true;
  	  } else {
  	    Page_Data.switchToDesktop = false;
  	  }
  	});
  })(Page_Data, (getObjectProperty(Page_Data, 'currentPage')));

return 
}

})
define('./functions/d48a6d3a366b0ef43b6e0b4bab07fa46/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d48a6d3a366b0ef43b6e0b4bab07fa46'] = function fn_d48a6d3a366b0ef43b6e0b4bab07fa46(Page_Data) {
var contact;


  Page_Data['residentContactFullNameOne'] = undefined;
  Page_Data['residentContactCellPhoneOne'] = undefined;
  Page_Data['residentContactHomePhoneOne'] = undefined;
  Page_Data['residentContactFullNameTwo'] = undefined;
  Page_Data['residentContactCellPhoneTwo'] = undefined;
  Page_Data['residentContactHomePhoneTwo'] = undefined;
  Page_Data['residentContactFullNameThree'] = undefined;
  Page_Data['residentContactCellPhoneThree'] = undefined;
  Page_Data['residentContactHomePhoneThree'] = undefined;
  Page_Data['residentContactFullNameFour'] = undefined;
  Page_Data['residentContactCellPhoneFour'] = undefined;
  Page_Data['residentContactHomePhoneFour'] = undefined;
  Page_Data['residentContactFullNameFive'] = undefined;
  Page_Data['residentContactCellPhoneFive'] = undefined;
  Page_Data['residentContactHomePhoneFive'] = undefined;

return 
}

})
define('./functions/d50ff552eac48bf53ee4dedc6cfda438/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'] = async function fn_d50ff552eac48bf53ee4dedc6cfda438(App_Data, Page_Data, orgName) {
var config;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  var config_list = (await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setPageSize(10)));
  for (var config_index in config_list) {
    config = config_list[config_index];
    if ((getObjectProperty(config, 'key')) == 'applicationId') {
      App_Data['applicationId'] = (getObjectProperty(config, 'value'));
    } else if ((getObjectProperty(config, 'key')) == 'contactAmounts') {
      App_Data['contactAmounts'] = (getObjectProperty((JSON.parse((getObjectProperty(config, 'value')))), (String(getObjectProperty(App_Data, 'currentUser.organizationId.orgName')) + String('.contactAmount'))));
    } else if ((getObjectProperty(config, 'key')) == 'residentApplicationNames') {
      App_Data['residentApplicationName'] = (getObjectProperty((JSON.parse((getObjectProperty(config, 'value')))), (String(getObjectProperty(App_Data, 'currentUser.organizationId.orgName')) + String('.name'))));
    } else if ((getObjectProperty(config, 'key')) == 'pillarApplicationNames') {
      App_Data['pillarApplicationName'] = (getObjectProperty((JSON.parse((getObjectProperty(config, 'value')))), (String(getObjectProperty(App_Data, 'currentUser.organizationId.orgName')) + String('.name'))));
    } else if ((getObjectProperty(config, 'key')) == 'mdmGroups') {
      App_Data['mdmGroups'] = (JSON.parse((getObjectProperty(config, 'value'))));
    } else if ((getObjectProperty(config, 'key')) == 'mdmToken') {
      App_Data['mdmToken'] = (getObjectProperty(config, 'value'));
    }
  }

return 
}

})
define('./functions/d897f147d4c500e22bcf6e99a55e0d31/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d897f147d4c500e22bcf6e99a55e0d31'] = function fn_d897f147d4c500e22bcf6e99a55e0d31(PAGE_DATA) {
  PAGE_DATA['curfewTableCount'] = undefined;
  PAGE_DATA['userTableCount'] = undefined;
  PAGE_DATA['residentTableCount'] = undefined;
  PAGE_DATA['orgTableCount'] = undefined;

return 
}

})
define('./functions/e1b4ed7b0fa7f3872bddf226a82a4b7e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e1b4ed7b0fa7f3872bddf226a82a4b7e'] = async function fn_e1b4ed7b0fa7f3872bddf226a82a4b7e(Page_Data, email, uniqueId) {
var toast;

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  try {
    await Backendless.Hive('emailCache').KeyValueStore(email).set(({ [`key`]: uniqueId }), { ttl: 172800, condition: 'Always' });
    return true;

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'Error sending email', 'failure');
    return false;

  }

return 
}

})
define('./functions/ebd5de0ec38f9456d8b93d611c7eab5a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ebd5de0ec38f9456d8b93d611c7eab5a'] = async function fn_ebd5de0ec38f9456d8b93d611c7eab5a(Page_Data, App_Data) {
var mdmToken, location2, address, residentObjectId, deviceId, getDeviceLocationResponse, getDeviceIdResponse;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshMDMToken() {
  try {
    await BackendlessUI.Functions.Custom['fn_f15eaeb413dd69197945a3bae68dcbde']();
    mdmToken = (getObjectProperty(((await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'mdmToken\'').setPageSize(10)))[0]), 'value'));
    await getDeviceLocation();

  } catch (error) {
    Page_Data['isMapLoading'] = false;
    Page_Data['locationError'] = true;

  }
}

/**
 * Describe this function...
 */
async function getDeviceLocation() {
  getDeviceIdResponse = (await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/DeviceId`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify(mdmToken),'deviceNumber': JSON.stringify(deviceId) }).send());
  if ((getObjectProperty(getDeviceIdResponse, 'status')) == 'OK') {
    getDeviceLocationResponse = (await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/DeviceLocation`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify(mdmToken),'deviceId': (getObjectProperty(getDeviceIdResponse, 'data.id')) }).send());
    if ((getObjectProperty(getDeviceLocationResponse, 'status')) == 'OK') {
      Page_Data['selectedDeviceLocation'] = [({ [`lng`]: (getObjectProperty(getDeviceLocationResponse, 'data.lon')),[`lat`]: (getObjectProperty(getDeviceLocationResponse, 'data.lat')) })];
      location2 = (await Backendless.Request.get(`${Backendless.appPath}/services/AWSApis/getDeviceLocation`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'lng': JSON.stringify((getObjectProperty(getDeviceLocationResponse, 'data.lon'))),'lat': JSON.stringify((getObjectProperty(getDeviceLocationResponse, 'data.lat'))),'variant': JSON.stringify('reverseGeo') }).send());
      Page_Data['isMapLoading'] = false;
      if (location2) {
        await Backendless.Data.of('Residents').save( ({ [`objectId`]: residentObjectId,[`lastLocation`]: location2 }) );
      }
    } else {
      throw (new Error(''))
    }
  } else {
    throw (new Error(''))
  }
}


  Page_Data['selectedDeviceLocation'] = undefined;
  Page_Data['isMapLoading'] = true;
  mdmToken = (getObjectProperty(App_Data, 'mdmToken'));
  if ((getObjectProperty(Page_Data, 'currentPage')) == 'Resident' || (getObjectProperty(Page_Data, 'currentPage')) == 'Device') {
    if ((getObjectProperty(Page_Data, 'currentPage')) == 'Device') {
      deviceId = (getObjectProperty(Page_Data, 'selectedItemDevice.deviceId'));
      residentObjectId = (getObjectProperty(Page_Data, 'selectedItemDevice.residentId.objectId'));
    } else if ((getObjectProperty(Page_Data, 'currentPage')) == 'Resident') {
      deviceId = (getObjectProperty(Page_Data, 'selectedItemResident.deviceId'));
      residentObjectId = (getObjectProperty(Page_Data, 'selectedItemResident.objectId'));
    }
    Page_Data['locationError'] = false;
    if (mdmToken) {
      try {
        await getDeviceLocation();

      } catch (error) {
        await refreshMDMToken();

      }
    }
  } else if ((getObjectProperty(Page_Data, 'currentPage')) == 'Organization' || (getObjectProperty(Page_Data, 'currentPage')) == 'User') {
    if ((getObjectProperty(Page_Data, 'currentPage')) == 'Organization') {
      address = [(getObjectProperty(Page_Data, 'selectedItemOrganization.address')),' ',(getObjectProperty(Page_Data, 'selectedItemOrganization.city')),', ',(getObjectProperty(Page_Data, 'selectedItemOrganization.state')),' ',(getObjectProperty(Page_Data, 'selectedItemOrganization.zipCode'))].join('');
    } else if ((getObjectProperty(Page_Data, 'currentPage')) == 'User') {
      address = [(getObjectProperty(Page_Data, 'selectedItemUser.orgAddress')),' ',(getObjectProperty(Page_Data, 'selectedItemUser.orgCity')),', ',(getObjectProperty(Page_Data, 'selectedItemUser.orgState')),' ',(getObjectProperty(Page_Data, 'selectedItemUser.orgZipCode'))].join('');
    }
    Page_Data['locationError'] = false;
    try {
      location2 = (await Backendless.Request.get(`${Backendless.appPath}/services/AWSApis/getDeviceLocation`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'address': JSON.stringify(address),'variant': JSON.stringify('geo') }).send());
      if ((getObjectProperty(location2, 'status')) == 'OK') {
        Page_Data['selectedDeviceLocation'] = [({ [`lng`]: (getObjectProperty(((getObjectProperty(location2, 'results'))[0]), 'geometry.location.lng')),[`lat`]: (getObjectProperty(((getObjectProperty(location2, 'results'))[0]), 'geometry.location.lat')) })];
      } else {
        throw (new Error(''))
      }
      Page_Data['isMapLoading'] = false;

    } catch (error) {
      Page_Data['isMapLoading'] = false;
      Page_Data['locationError'] = true;

    }
  }

return 
}

})
define('./functions/f15eaeb413dd69197945a3bae68dcbde/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f15eaeb413dd69197945a3bae68dcbde'] = async function fn_f15eaeb413dd69197945a3bae68dcbde() {
var newToken, config, configurations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    newToken = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/HeadwindMDM/getUserToken`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'id_token'));
    configurations = (await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setPageSize(10)));
    if (newToken && configurations) {
      for (var config_index in configurations) {
        config = configurations[config_index];
        if ((getObjectProperty(config, 'key')) == 'mdmToken') {
          await Backendless.Data.of('Configurations').save( ({ [`objectId`]: (getObjectProperty(config, 'objectId')),[`value`]: newToken }) );
        }
      }
    } else {
      throw (new Error(''))
    }

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'Error refreshing MDM token.', 'failure');

  }

return 
}

})
define('./functions/f282814277b3c0aa13f33d3b2cda111b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'] = async function fn_f282814277b3c0aa13f33d3b2cda111b(phoneNumber, variant) {
var bareNumber;


  if (phoneNumber) {
    bareNumber = (await (async function(phoneNumber) {
    	return phoneNumber.replace(/[^0-9]+/g, "")
    })(phoneNumber));
    if (variant == 'remove') {
      return bareNumber;
    } else if (variant == 'create') {
      if (phoneNumber.length == 5) {
        return bareNumber;
      } else if (bareNumber.length == 3) {
        return (['(',bareNumber,')-'].join(''));
      } else if (bareNumber.length > 3 && bareNumber.length <= 6) {
        return (['(',bareNumber.slice(0, 3),')-',bareNumber.slice(3, bareNumber.length)].join(''));
      } else if (bareNumber.length > 6) {
        return (['(',bareNumber.slice(0, 3),')-',bareNumber.slice(3, 6),'-',bareNumber.slice(6, 10)].join(''));
      } else {
        return bareNumber;
      }
    }
  }

return 
}

})
define('./pages/404/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('', undefined);

  },
  /* handler:onClick *//* content */
}));

define('./pages/404/components/6e82b3e98cd61decb70b8493fcfd6c29/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e82b3e98cd61decb70b8493fcfd6c29', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e82b3e98cd61decb70b8493fcfd6c29', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Curfew/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_c022336da0dd1fcbccef0e5929a35855'](___arguments.context.appData);
  if (!(await BackendlessUI.Functions.Custom['fn_3552157be2fff99d18ceeb6b2715c8b3'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')));
  }
  await BackendlessUI.Functions.Custom['fn_98e6d7676a68625bb37c0ec89d66dff7'](___arguments.context.pageData);
  ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: 1 });
  ___arguments.context.pageData['currentPage'] = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  ___arguments.context.pageData['curfewPillarSelectionBar'] = 1;
  await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Curfew/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getCurrentUserInfo() {
  try {
    ___arguments.context.pageData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'There was a problem loading the user information.', 'failure');

  }
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', true);
  }
  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if (currentUser) {
    if (getObjectProperty(___arguments.context.pageData, 'refreshUser')) {
      await getCurrentUserInfo();
      ___arguments.context.pageData['refreshUser'] = undefined;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;


  if ((function () { return BackendlessUI.isProd })()) {
    classes = ['dashboardPage', 'dashboard_display_none'];
  } else {
    classes = ['dashboardPage'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Curfew/components/f25656fc6fccd2c1732f6216cb8984db/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25656fc6fccd2c1732f6216cb8984db', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25656fc6fccd2c1732f6216cb8984db', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Curfew/components/3ffbfa061eb6962af60b836dfca4e15d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ffbfa061eb6962af60b836dfca4e15d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ffbfa061eb6962af60b836dfca4e15d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Curfew/components/799abf183752a5d17c5e6ce2c3eb381f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799abf183752a5d17c5e6ce2c3eb381f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799abf183752a5d17c5e6ce2c3eb381f', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Curfew/components/d1ee767a225b09c8be56b395c7158a5b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1ee767a225b09c8be56b395c7158a5b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1ee767a225b09c8be56b395c7158a5b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Curfew/components/5e1eda18457d1214140137363c96422d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    ___arguments.context.pageData['isSupportDrawerOneOpen'] = false;
  } else {
    ___arguments.context.pageData['isSupportDrawerOneOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Curfew/components/d6c3b549b345eb750d61e05da694d8f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['supportGuideSelection'] = 'admin';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Curfew/components/d14cc726548d75559a5f88d25078b35f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['supportGuideSelection'] = 'manager';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Curfew/components/f4e3431a8ee5f175dafc9ca1c95e09ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Curfew/components/9bfc106e638c9404219eca1d442c5f7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Curfew/components/b654715969d283f96f591c87c71d1aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Curfew/components/a34f8d73b6e88304bf151a46865e54ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Curfew/components/9632a44ca7adb2c6b6f8be192ea6af28/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'supportGuide' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9632a44ca7adb2c6b6f8be192ea6af28', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9632a44ca7adb2c6b6f8be192ea6af28', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Curfew/components/f22ffaf8518d876867984e9a50eb1a1d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f22ffaf8518d876867984e9a50eb1a1d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f22ffaf8518d876867984e9a50eb1a1d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Curfew/components/2c3c38c5109a029b53d41f141b471bfc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c3c38c5109a029b53d41f141b471bfc', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c3c38c5109a029b53d41f141b471bfc', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var pillarFourResidents, pillarThreeResidents, pillarTwoResidents, pillarOneResidents, unassignedResidents, resident, pillar;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function refreshResidentGraph() {
  pillarOneResidents = [];
  pillarTwoResidents = [];
  pillarThreeResidents = [];
  pillarFourResidents = [];
  unassignedResidents = [];
  var resident_list = (await Backendless.Data.of('Residents').find(Backendless.DataQueryBuilder.create().setWhereClause((['organizationId.orgName = \'',(getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')),'\''].join(''))).setRelationsDepth(2).setPageSize(10)));
  for (var resident_index in resident_list) {
    resident = resident_list[resident_index];
    pillar = (getObjectProperty((JSON.parse((getObjectProperty(resident, 'residentIntakeForm')))), 'pillarInfo.pillar'));
    if (!pillar) {
      addItemToList(unassignedResidents, resident);
    } else if (pillar == 1) {
      addItemToList(pillarOneResidents, resident);
    } else if (pillar == 2) {
      addItemToList(pillarTwoResidents, resident);
    } else if (pillar == 3) {
      addItemToList(pillarThreeResidents, resident);
    } else if (pillar == 4) {
      addItemToList(pillarFourResidents, resident);
    }
  }
  ___arguments.context.pageData['residentPillarCount'] = ({ [`unassigned`]: unassignedResidents.length,[`pillar1`]: pillarOneResidents.length,[`pillar2`]: pillarTwoResidents.length,[`pillar3`]: pillarThreeResidents.length,[`pillar4`]: pillarFourResidents.length });
}


  await BackendlessUI.Functions.Custom['fn_c022336da0dd1fcbccef0e5929a35855'](___arguments.context.appData);
  await refreshResidentGraph();
  if (!(await BackendlessUI.Functions.Custom['fn_3552157be2fff99d18ceeb6b2715c8b3'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')));
  }
  await BackendlessUI.Functions.Custom['fn_98e6d7676a68625bb37c0ec89d66dff7'](___arguments.context.pageData);
  ___arguments.context.pageData['currentPage'] = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  ___arguments.context.pageData['activeCurfewCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['status = \'ACTIVE\' and organizationId.orgName = \'',(getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')),'\''].join('')))));
  await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Dashboard/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getCurrentUserInfo() {
  try {
    ___arguments.context.appData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'There was a problem loading the user information.', 'failure');

  }
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', true);
  }
  if (getObjectProperty(___arguments.context.pageData, 'refreshUser')) {
    await getCurrentUserInfo();
    ___arguments.context.pageData['refreshUser'] = undefined;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/29f332ccc3a2fc6843efbb0cbeb33cf6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    if (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar1')) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar1')) == 1) {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar1')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName'))].join(''));
      } else {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar1')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
      }
    } else {
      return (['0 ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/59729869aeceb984843641a85a674b7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    if (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar2')) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar2')) == 1) {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar2')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName'))].join(''));
      } else {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar2')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
      }
    } else {
      return (['0 ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/a329d0fb651e45ea3c9eb81d8726ccd2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    if (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar3')) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar3')) == 1) {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar3')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName'))].join(''));
      } else {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar3')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
      }
    } else {
      return (['0 ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/018bb38a954e9c2b586eaf580634b101/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    if (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar4')) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar4')) == 1) {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar4')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName'))].join(''));
      } else {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar4')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
      }
    } else {
      return (['0 ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/7eefdfc25b9b19ae5b2ad66965727d87/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var count, pillarCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentPillarCount')) {
    count = (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar1')) + (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar2')) + (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar3')) + (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar4')) + (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.unassigned'));
    if (count > 0) {
      if (count == 1) {
        return (['(',count,' Record)'].join(''));
      } else {
        return (['(',count,' Records)'].join(''));
      }
    } else {
      return '0 Records';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/98ec45086304fe081a214845c4788f7a/bundle.js', [], () => ({
  /* content */
  /* handler:datasetsLogic */
  ['datasetsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return [({ [`label`]: String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 1'),[`data`]: [(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar1'))],[`borderColor`]: '#8657B5',[`backgroundColor`]: '#8657B5' }), ({ [`label`]: String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 2'),[`data`]: [(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar2'))],[`borderColor`]: '#2EC8EE',[`backgroundColor`]: '#2EC8EE' }), ({ [`label`]: String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 3'),[`data`]: [(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar3'))],[`borderColor`]: '#FE378C',[`backgroundColor`]: '#FE378C' }), ({ [`label`]: String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 4'),[`data`]: [(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.pillar4'))],[`borderColor`]: '#FFB000',[`backgroundColor`]: '#FFB000' }), ({ [`label`]: 'Unassigned',[`data`]: [(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.unassigned'))],[`borderColor`]: 'rgba(230, 101, 79, 1)',[`backgroundColor`]: 'rgba(230, 101, 79, 1)' })];
  }

  },
  /* handler:datasetsLogic */
  /* content */
}))

define('./pages/Dashboard/components/4d7b07aad852cb1763476d1ecfcd6cb4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'noResultsScreen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d7b07aad852cb1763476d1ecfcd6cb4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d7b07aad852cb1763476d1ecfcd6cb4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/017cd9b6a49c12cecb0b6d5fe8ec0d7c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var activeCurfews;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeCurfews = (getObjectProperty(___arguments.context.pageData, 'activeCurfewCount'));
  if (activeCurfews) {
    return activeCurfews;
  } else {
    return '0';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/7ffadc1d40b1452a6eb54692e33d381a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    if (getObjectProperty(___arguments.context.pageData, 'residentPillarCount.unassigned')) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentPillarCount.unassigned')) == 1) {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.unassigned')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName'))].join(''));
      } else {
        return ([(getObjectProperty(___arguments.context.pageData, 'residentPillarCount.unassigned')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
      }
    } else {
      return (['0 ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/d8bb02ea4f88477a7c24ec11f57a00f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Resident', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/9dcf928a722478ffaf6cb827dec7506f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (['Total ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/74eb47e24178ae0dabc6e0937f9b103e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 1'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/dc6824ff6ccf96e665d8579e60b25826/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 2'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/e5e5290817aeb368cd5724ffe99345fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 3'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/bf890ea90b829a1e8226f96b0326541b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 4'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Device/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_c022336da0dd1fcbccef0e5929a35855'](___arguments.context.appData);
  if (!(await BackendlessUI.Functions.Custom['fn_3552157be2fff99d18ceeb6b2715c8b3'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')));
  }
  await BackendlessUI.Functions.Custom['fn_98e6d7676a68625bb37c0ec89d66dff7'](___arguments.context.pageData);
  ___arguments.context.pageData['refreshDeviceTable'] = true;
  ___arguments.context.pageData['currentPage'] = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Device/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getCurrentUserInfo() {
  try {
    ___arguments.context.pageData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'There was a problem loading the user information.', 'failure');

  }
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', true);
  }
  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if (currentUser) {
    if (getObjectProperty(___arguments.context.pageData, 'refreshUser')) {
      await getCurrentUserInfo();
      ___arguments.context.pageData['refreshUser'] = undefined;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;


  if ((function () { return BackendlessUI.isProd })()) {
    classes = ['dashboardPage', 'dashboard_display_none'];
  } else {
    classes = ['dashboardPage'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Device/components/08dc45693d9ee7f544ab002bd6cf7c82/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('08dc45693d9ee7f544ab002bd6cf7c82', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('08dc45693d9ee7f544ab002bd6cf7c82', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var currentUser, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  if (error) {
    ___arguments.context.appData['toastState'] = ({ [`status`]: (getObjectProperty(error, 'status')),[`message`]: (getObjectProperty(error, 'message')) });
  }
}


  currentUser = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.pageData['pageVariant'] = 'resetPassword';
  await BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'](___arguments.context.appData, ___arguments.context.pageData, null);
  if ((getObjectProperty(___arguments.context.pageData, 'error')) == 'login') {
    await setError(({ [`status`]: 'failure',[`message`]: 'There was an error logging into your dashboard.' }));
  }
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Management', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);
    }
  }
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/ForgotPassword/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var isLoading, button, uniqueID, emailInput, user, toastError;

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  button['label'] = (isLoading ? 'Please wait...' : 'Continue');
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function setError(toastError) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}


  await isButtonLoading(true);
  emailInput = (getObjectProperty(___arguments.context.pageData, 'email'));
  user = (
      await (async function(userIdentity){
        var userColumns = await Backendless.UserService.describeUserClass()
        var identityColumn = userColumns.find(column => column.identity)
        var whereClause = `${identityColumn.name} = '${userIdentity}'`
        var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
        var users = await Backendless.Data.of(Backendless.User).find(query)

        return users[0]
     })(emailInput)
  );
  if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'](emailInput))) {
    await setError(({ [`status`]: 'failure',[`message`]: 'Please enter a valid email.' }));
    return ;
  } else if (!user) {
    await setError(({ [`status`]: 'failure',[`message`]: 'Email doesn\'t match our records.' }));
    return ;
  } else {
    try {
      uniqueID = (generateUUID().long());
      await BackendlessUI.Functions.Custom['fn_7654dccaafd23dd3a20bcb51bca43a86'](emailInput, uniqueID, ___arguments.context.pageData);
      console.log((await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('forgotPassword', ({ [`user_email`]: emailInput,[`key`]: uniqueID }), ___arguments.context.appData)));
      await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', emailInput, 'd-f5785c08d0cc40c9812079f53ef167f6', ({ [`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('forgotPassword', ({ [`user_email`]: emailInput,[`key`]: uniqueID }), ___arguments.context.appData)) }));
      await isButtonLoading(false);
      ___arguments.context.pageData['pageVariant'] = 'success';

    } catch (error) {
      await setError(({ [`status`]: 'failure',[`message`]: 'There was an error sending the password recovery email.' }));

    } finally {
      await isButtonLoading(false);

    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, emailInput, isButtonLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  emailInput = (getObjectProperty(___arguments.context.pageData, 'email'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  if (isButtonLoading) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
    ___arguments.context.pageData['isSubmitButtonDisabled'] = true;
  } else {
    isDisabled = false;
    if (!emailInput || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](emailInput))) {
      isDisabled = true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
      ___arguments.context.pageData['isSubmitButtonDisabled'] = true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = '#3871FF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = '#FFFFFF';
      ___arguments.context.pageData['isSubmitButtonDisabled'] = false;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/98819e5abc884848ea0f2d26ffd3d031/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'resetPassword') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('98819e5abc884848ea0f2d26ffd3d031', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('98819e5abc884848ea0f2d26ffd3d031', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/79857a9dfa0154cb4f77a2477d3ac66f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'success') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79857a9dfa0154cb4f77a2477d3ac66f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79857a9dfa0154cb4f77a2477d3ac66f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/9401959f40491e17a85632bd153e50fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/ForgotPassword/components/3ebf9fabe0cdb1fcdd4998df4f5845e8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Password Reset Link Sent to ') + String(getObjectProperty(___arguments.context.pageData, 'email')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/a99924d5a9adb28f36db7d9ac3953aef/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a99924d5a9adb28f36db7d9ac3953aef', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a99924d5a9adb28f36db7d9ac3953aef', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var currentUser, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  if (error) {
    ___arguments.context.appData['toastState'] = ({ [`status`]: (getObjectProperty(error, 'status')),[`message`]: (getObjectProperty(error, 'message')) });
  }
}


  ___arguments.context.pageData['viewingPassword'] = undefined;
  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if ((getObjectProperty(___arguments.context.pageData, 'error')) == 'login') {
    await setError(({ [`status`]: 'failure',[`message`]: 'There was an error logging into your dashboard.' }));
  }
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Management', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);
    }
  }
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Login/components/6d7b09fc8c04707fa6291e69b80c05d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('ForgotPassword', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Login/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var username, isLoading, button, toastError, currentUser, updatedUser, loginAttempts, user, password;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function isDeactivated() {
  if ((getObjectProperty((
      await (async function(userIdentity){
        var userColumns = await Backendless.UserService.describeUserClass()
        var identityColumn = userColumns.find(column => column.identity)
        var whereClause = `${identityColumn.name} = '${userIdentity}'`
        var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
        var users = await Backendless.Data.of(Backendless.User).find(query)

        return users[0]
     })(username)
  ), 'status')) == 'DEACTIVATED') {
    return true;
  }
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  button['label'] = (isLoading ? 'Please wait...' : 'LOG IN');
}

/**
 * Describe this function...
 */
async function setError(toastError) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}

/**
 * Describe this function...
 */
async function login() {
  try {
    currentUser = (await Backendless.UserService.login( username, password, true  ));
    if (currentUser) {
      updatedUser = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`loginAttempts`]: 0 })) ));
    }

  } catch (error) {
    loginAttempts = 1 + loginAttempts;
    if (loginAttempts == 2) {
      await setError(({ [`status`]: 'failure',[`message`]: 'Failed to login. You have 1 attempt left.' }));
    } else {
      await setError(({ [`status`]: 'failure',[`message`]: ['Failed to login. You have ',3 - loginAttempts,' attempts left.'].join('') }));
    }
    if (3 - loginAttempts == 0) {
      await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`loginAttempts`]: loginAttempts,[`status`]: 'LOCKED' })) );
    } else {
      await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`loginAttempts`]: loginAttempts })) );
    }
    await isButtonLoading(false);

  }
}


  await isButtonLoading(true);
  username = (getObjectProperty(___arguments.context.pageData, 'username'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  if (username && password) {
    try {
      user = (
          await (async function(userIdentity){
            var userColumns = await Backendless.UserService.describeUserClass()
            var identityColumn = userColumns.find(column => column.identity)
            var whereClause = `${identityColumn.name} = '${userIdentity}'`
            var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
            var users = await Backendless.Data.of(Backendless.User).find(query)

            return users[0]
         })(username)
      );
      if (user) {
        loginAttempts = (getObjectProperty(user, 'loginAttempts'));
        if (loginAttempts >= 3) {
          await setError(({ [`status`]: 'failure',[`message`]: 'This account has been locked. Please contact customer support.' }));
        } else {
          if (await isDeactivated()) {
            await setError(({ [`status`]: 'failure',[`message`]: 'This account has been deactivated. Please contact support or your admin.' }));
          } else {
            await login();
            if (updatedUser) {
              ___arguments.context.appData['currentUser'] = undefined;
              if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', undefined);
              } else {
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);
              }
            }
          }
        }
      } else {
        await setError(({ [`status`]: 'failure',[`message`]: 'Failed to login. Incorrect user name or password.' }));
      }

    } catch (error) {
      await setError(({ [`status`]: 'failure',[`message`]: 'Failed to login. Incorrect user name or password.' }));

    }
  } else {
    await setError(({ [`status`]: 'failure',[`message`]: 'Please enter your email and password to login.' }));
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, password, username, isButtonLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  username = (getObjectProperty(___arguments.context.pageData, 'username'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  if (isButtonLoading) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
    ___arguments.context.pageData['isLoginButtonDisabled'] = true;
  } else {
    isDisabled = false;
    if (!username || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](username))) {
      isDisabled = true;
    } else if (!password || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](password))) {
      isDisabled = true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
      ___arguments.context.pageData['isLoginButtonDisabled'] = true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = '#3871FF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = '#FFFFFF';
      ___arguments.context.pageData['isLoginButtonDisabled'] = false;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Login/components/d416e33637324fbaab9151cf89fd0f22/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ___arguments.context.pageData['viewingPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('08a637cb5f6979e7ff4329cdbf129917'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4edd272bd7fb7f6f22eae3525232662'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('08a637cb5f6979e7ff4329cdbf129917'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4edd272bd7fb7f6f22eae3525232662'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Login/components/060f161cbd5e958295b3b832a73b3305/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      try {
    await (async function(App_Data) {
    	// Function to initialize and open Intercom chat
    	function initializeIntercom() {
    	  window.intercomSettings = {
    	    api_base: "https://api-iam.intercom.io",
    	    app_id: "nuppfi6g"
    	  };

    	  // Ensure Intercom widget is loaded
    	  (function() {
    	    var w = window;
    	    var ic = w.Intercom;

    	    if (typeof ic === "function") {
    	      // If Intercom is already available, update and show chat
    	      ic("update", window.intercomSettings);
    	      ic("show"); // Open chat immediately
    	    } else {
    	      // Load Intercom script
    	      var d = document;
    	      var i = function() {
    	        i.c(arguments);
    	      };
    	      i.q = [];
    	      i.c = function(args) {
    	        i.q.push(args);
    	      };
    	      w.Intercom = i;

    	      // Create script element
    	      var l = function() {
    	        var s = d.createElement("script");
    	        s.type = "text/javascript";
    	        s.async = true;
    	        s.src = "https://widget.intercom.io/widget/nuppfi6g";
    	        s.onload = function() {
    	          // After the script is fully loaded
    	          w.Intercom("update", window.intercomSettings);
    	          w.Intercom("show"); // Open chat after loading
    	        };
    	        var x = d.getElementsByTagName("script")[0];
    	        x.parentNode.insertBefore(s, x);
    	      };

    	      // Check when to load the script
    	      if (document.readyState === "complete") {
    	        l();
    	      } else if (w.attachEvent) {
    	        w.attachEvent("onload", l);
    	      } else {
    	        w.addEventListener("load", l, false);
    	      }
    	    }
    	  })();
    	}

    	// Initialize and open the Intercom chat
    	initializeIntercom();

    })(___arguments.context.appData);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load Intercom chat.', 'failure');

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Login/components/a99924d5a9adb28f36db7d9ac3953aef/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a99924d5a9adb28f36db7d9ac3953aef', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a99924d5a9adb28f36db7d9ac3953aef', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user, passwordKey, passwordCache;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['viewingPassword'] = undefined;
  ___arguments.context.pageData['viewingConfirmPassword'] = undefined;
  try {
    passwordCache = (await Backendless.Hive('passwordCache').KeyValueStore.get([(getObjectProperty(___arguments.context.pageData, 'userEmail'))]));
    passwordKey = (getObjectProperty((getObjectProperty(passwordCache, (getObjectProperty(___arguments.context.pageData, 'userEmail')))), 'key'));
    if (passwordKey == null) {
      ___arguments.context.pageData['pageVariant'] = 'expired';
    } else {
      user = (
          await (async function(userIdentity){
            var userColumns = await Backendless.UserService.describeUserClass()
            var identityColumn = userColumns.find(column => column.identity)
            var whereClause = `${identityColumn.name} = '${userIdentity}'`
            var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
            var users = await Backendless.Data.of(Backendless.User).find(query)

            return users[0]
         })((getObjectProperty(___arguments.context.pageData, 'userEmail')))
      );
      if ((getObjectProperty(___arguments.context.pageData, 'key')) == passwordKey) {
        ___arguments.context.pageData['pageVariant'] = 'newPassword';
        ___arguments.context.pageData['currentUser'] = user;
      } else {
        ___arguments.context.pageData['pageVariant'] = 'expired';
      }
    }

  } catch (error) {
    ___arguments.context.pageData['pageVariant'] = 'expired';

  }
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/NewPassword/components/fdc847332f171793625ca41f662e6956/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var toastError, currentUser, updatedUser, password, confirmPassword, isLoading, button;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(toastError) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('fdc847332f171793625ca41f662e6956'));
  button['label'] = (isLoading ? 'Please wait...' : 'Continue');
}


  await isButtonLoading(true);
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (password == confirmPassword) {
    try {
      updatedUser = (await Backendless.Request.post(`${Backendless.appPath}/services/UpdateUser/UserUpdate`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ [`objectId`]: (getObjectProperty(currentUser, 'objectId')),[`password`]: password }))));
      if (updatedUser) {
        await Backendless.Hive('passwordCache').KeyValueStore.delete([(getObjectProperty(currentUser, 'email'))]);
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(___arguments.context.pageData, 'email')), 'd-69dbf2f59c624797bf4ad5d59c36ea09', null);
        ___arguments.context.pageData['pageVariant'] = 'success';
        await setError(({ [`status`]: 'success',[`message`]: 'We\'ve successfully updated your password!' }));
      }

    } catch (error) {
      await setError(({ [`status`]: 'failure',[`message`]: 'There was an error updating your password' }));

    }
  } else {
    await setError(({ [`status`]: 'failure',[`message`]: 'The passwords do not match.' }));
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, confirmPassword, password, isButtonLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  if (isButtonLoading) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['color'] = 'rgba(56, 113, 255, 0.5)';
    ___arguments.context.pageData['isButtonDisabled'] = true;
  } else {
    isDisabled = false;
    if (!password || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](password))) {
      isDisabled = true;
    } else if (!confirmPassword || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](confirmPassword))) {
      isDisabled = true;
    } else if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](password)) || !(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](confirmPassword))) {
      isDisabled = true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['color'] = 'rgba(56, 113, 255, 0.5)';
      ___arguments.context.pageData['isButtonDisabled'] = true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['background-color'] = '#3871FF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['color'] = 'white';
      ___arguments.context.pageData['isButtonDisabled'] = false;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/ade165fcabdbac285279b0dd8e363e95/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'expired') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ade165fcabdbac285279b0dd8e363e95', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ade165fcabdbac285279b0dd8e363e95', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/a5adb18b63cd1dc47eec93f4be386bb5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'newPassword') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a5adb18b63cd1dc47eec93f4be386bb5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a5adb18b63cd1dc47eec93f4be386bb5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/53be4648d79c18bc31f4209ca48eae71/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'success') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('53be4648d79c18bc31f4209ca48eae71', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('53be4648d79c18bc31f4209ca48eae71', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/22ac9618ba2e155a90170d48fbef65b4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['passwordError'] = false;
  ___arguments.context.pageData['password'] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](___arguments.value))) {
    ___arguments.context.pageData['passwordError'] = true;
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['input_grey_border'];
  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    removeItemInList(classesList, 'input_grey_border', '');
    addItemToList(classesList, 'input_blue_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/NewPassword/components/64fb55f900a3d61be948bb25ef6ece53/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'email'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/NewPassword/components/0f0d2b23fb0df0c4edbe2033d97de7f4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/NewPassword/components/66ca704c1dff615153b36d0743f6e62b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('66ca704c1dff615153b36d0743f6e62b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('66ca704c1dff615153b36d0743f6e62b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/4ddbddbab81614c773a6fcaabdb65958/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['confirmPassword'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewPassword/components/c4626f55741d68db8cc8a93c52ffad48/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://mail.google.com/mail/u/0/?f=1&source=mailto&to=support@aquax.co&fs=1&tf=cm', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/NewPassword/components/dd718e27dc3bfea66fae7d1fe6172c84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ___arguments.context.pageData['viewingPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('de9f3f69076fc27bcd0278c6e52f04e5'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('22ac9618ba2e155a90170d48fbef65b4'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('de9f3f69076fc27bcd0278c6e52f04e5'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('22ac9618ba2e155a90170d48fbef65b4'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['view_password'];
  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    removeItemInList(classesList, 'view_password', '');
    addItemToList(classesList, 'view_password_blue');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/NewPassword/components/e1e9c0ce198bb3cc4d16cf66fce1221b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ___arguments.context.pageData['viewingConfirmPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingConfirmPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89458375a97832ea417a7883db22a27a'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4ddbddbab81614c773a6fcaabdb65958'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89458375a97832ea417a7883db22a27a'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4ddbddbab81614c773a6fcaabdb65958'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/24b61a6890f1974d261df9c9c6137536/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Password Reset Successful for account ') + String(getObjectProperty(___arguments.context.pageData, 'userEmail')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/NewPassword/components/aa0388bf1e82677b9e2d0b918578c37b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('aa0388bf1e82677b9e2d0b918578c37b', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('aa0388bf1e82677b9e2d0b918578c37b', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Organization/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_c022336da0dd1fcbccef0e5929a35855'](___arguments.context.appData);
  if (!(await BackendlessUI.Functions.Custom['fn_3552157be2fff99d18ceeb6b2715c8b3'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')));
  }
  await BackendlessUI.Functions.Custom['fn_98e6d7676a68625bb37c0ec89d66dff7'](___arguments.context.pageData);
  ___arguments.context.pageData['currentPage'] = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Organization/components/1122cbfb4556cb323d49f3d8f3b55023/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1122cbfb4556cb323d49f3d8f3b55023', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1122cbfb4556cb323d49f3d8f3b55023', false);
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Organization/components/b46596fe564be412cdfe918acca19025/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var tableCount, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'orgTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'orgTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/e5494c6b73a1dd11216ebdea401ee5bf/bundle.js', [], () => ({
  /* content */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', []);
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueOrg'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
  if (!(getObjectProperty(___arguments.context.pageData, 'pageEnter'))) {
    ___arguments.context.pageData['pageEnter'] = true;
    ___arguments.context.pageData['orgTableCount'] = (await Backendless.Data.of('Organizations').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null)))));
    if ((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) == 0) {
      ___arguments.context.pageData['noResultsScreen'] = true;
    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    var orgSearchCount, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'orgTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* content */
}))

define('./pages/Organization/components/7a8b1208417a657feb319f73f7167d9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['addOrganizationStep'] = 1;
  ___arguments.context.pageData['organizationUpdateObject'] = ({  });
  ___arguments.context.pageData['recordTableVariant'] = 'addOrganization';
  ___arguments.context.pageData['selectedItemOrganization.state'] = 'State';
  ___arguments.context.pageData['scrollToTop'] = true;
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/fbc785d3931ac5a38061bbf35ad558b3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var orgSearchResults, changedValued, currentUser, changedValue;


  ___arguments.context.pageData['isTableLoading'] = true;
  ___arguments.context.pageData['changedValueOrg'] = ___arguments.value;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', []);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, ___arguments.value, null, null))).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['orgTableCount'] = (await Backendless.Data.of('Organizations').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, ___arguments.value, null, null)))));
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Organization/components/bedeb0f286cae93f0a72f21a81ee8fe8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'created')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'created')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/d0f3b942abb79f4deb1d0d598a791a1f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'billingEmail'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/be85ed0c5b8edf53ef1649a64cfbffba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['recordsSelection'] = 'organizations';
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['selectedItemOrganization'] = ___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2');
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['isViewDetailsOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/a471323c4b73e22b26e974c6c8cc75d3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a471323c4b73e22b26e974c6c8cc75d3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a471323c4b73e22b26e974c6c8cc75d3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Organization/components/f3b59b0135057a666ac52e26fa9563e2/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'objectId')) && (getObjectProperty(___arguments.context.pageData, 'refreshSelectedItem'))) {
    if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
      ___arguments.context.pageData['selectedItemOrganization'] = ___arguments.context.dataModel;
    }
    ___arguments.context.pageData['refreshSelectedItem'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Organization/components/115cc499ccdccd29354b9dbfe8ea46c7/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshOrgTable, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshOrgTable2() {
  try {
    ___arguments.context.pageData['refreshOrgTable'] = undefined;
    ___arguments.context.pageData['isTableLoading'] = true;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', []);
    changedValue = (getObjectProperty(refreshOrgTable, 'changedValueOrg'));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null))).setSortBy('created asc').setPageSize(10))));
    ___arguments.context.pageData['orgTableCount'] = (await Backendless.Data.of('Organizations').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null)))));
    if ((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) == 0) {
      ___arguments.context.pageData['noResultsScreen'] = true;
    }
    ___arguments.context.pageData['isTableLoading'] = false;

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load organization table.', 'failure');

  }
}


  refreshOrgTable = (getObjectProperty(___arguments.context.pageData, 'refreshOrgTable'));
  if (refreshOrgTable) {
    await refreshOrgTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Organization/components/ef0369450001da75ed6a3fab82b4b1e9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, orgTableCount, navbarSelection, residentTableCount, userTableCount, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgTableCount = (getObjectProperty(___arguments.context.pageData, 'orgTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (orgTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef0369450001da75ed6a3fab82b4b1e9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef0369450001da75ed6a3fab82b4b1e9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Organization/components/e043a5d6c6ad06ef7644c761d53f1a8c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'billingPhone')), 2)), 'create'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/20eaf23d912d475ec8cdcb052d526fab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['recordsSelection'] = 'organizations';
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['selectedItemOrganization'] = ___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2');
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['isViewDetailsOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Organization/components/670e853979052a938b2655a1be3e1735/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Organization/components/ef76a121df79136d15f133152da6c91b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Organization/components/de28962d3afa2b78ca3fcf3c63245d95/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Organization/components/e61bc9e3525fa93317a17a33e51780ef/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'logo')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'logo'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Organization/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user, emailKey, emailCache;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['viewingPassword'] = undefined;
  ___arguments.context.pageData['viewingCofirmPassword'] = undefined;
  ___arguments.context.pageData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email = \'',(getObjectProperty(___arguments.context.pageData, 'userEmail')),'\''].join(''))).setRelationsDepth(2).setPageSize(10)))[0]);
  user = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  emailCache = (await Backendless.Hive('emailCache').KeyValueStore.get([(getObjectProperty(___arguments.context.pageData, 'userEmail'))]));
  try {
    emailKey = (getObjectProperty((getObjectProperty(emailCache, (getObjectProperty(___arguments.context.pageData, 'userEmail')))), 'key'));
    if (emailKey == null) {
      ___arguments.context.pageData['pageVariant'] = 'expiredLink';
    } else if ((getObjectProperty(___arguments.context.pageData, 'key')) == emailKey) {
      if (!user) {
        ___arguments.context.pageData['pageVariant'] = 'expiredLink';
      } else if (user && (getObjectProperty(user, 'status')) == 'ACTIVE') {
        ___arguments.context.pageData['pageVariant'] = 'expiredLink';
      } else {
        ___arguments.context.pageData['phoneNumber'] = (getObjectProperty(user, 'currentUser.phoneNumber'));
        ___arguments.context.pageData['pageVariant'] = 'createAccount';
      }
    } else {
      ___arguments.context.pageData['pageVariant'] = 'expiredLink';
    }

  } catch (error) {
    ___arguments.context.pageData['pageVariant'] = 'expiredLink';

  }
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Register/components/9069acce85badb2f6183b315aae3420f/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    return (getObjectProperty(currentUser, 'name'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['fullName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Register/components/eb74efff1db954116c7a19b50075eafb/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    return (getObjectProperty(currentUser, 'email'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['userName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Register/components/ee9455ef0cb3db71904419e15b732014/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['contactError'] = false;
  ___arguments.context.pageData['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'contactError')) {
    classesList = ['input_red_border'];
  } else {
    classesList = ['input_grey_border'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/7bb3ffc7a9794e36e9654b41704ab002/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
      return 'Admin Role';
    } else if ((getObjectProperty(currentUser, 'userRole')) == 'manager') {
      return 'Manager Role';
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/Register/components/c5813152a625d7e94da6039ff70db7ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, profilePicture, isLoading, buttonLogin, error, loggedInUser, password, updatedUserData, changedAttributes, index, phoneNumber, fullName, attribute, inputs, re_enterPassword, key;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function createPictureUrl() {
  try {
    profilePicture = (await Backendless.Files.upload(
        ((getObjectProperty(___arguments.context.pageData, 'profilePicture'))[0]),
        encodePath((['/profile_pictures/',(getObjectProperty(currentUser, 'objectId')),'/'].join(''))),
        false
      ).then(result => result.fileURL));

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was a problem uploading your profile picture.' }));

  }
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'));
  buttonLogin['label'] = (isLoading ? 'Please wait...' : 'Continue');
}

/**
 * Describe this function...
 */
async function setToast(error) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(error, 'message')), (getObjectProperty(error, 'status')));
}


  await isButtonLoading(true);
  key = String(getObjectProperty(___arguments.context.pageData, 'key'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  fullName = (getObjectProperty(___arguments.context.pageData, 'fullName'));
  phoneNumber = String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'phoneNumber')), 'remove')));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  re_enterPassword = (getObjectProperty(___arguments.context.pageData, 're-enterPassword'));
  inputs = [fullName, phoneNumber];
  index = 0;
  if (phoneNumber) {
    if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'](phoneNumber))) {
      ___arguments.context.pageData['contactError'] = true;
      await isButtonLoading(false);
      return ;
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'profilePicture')) {
    await createPictureUrl();
    changedAttributes = ({ [`objectId`]: (getObjectProperty(currentUser, 'objectId')),[`inviteStatus`]: 'ACCEPTED',[`status`]: 'ACTIVE',[`password`]: password,[`profilePicture`]: profilePicture });
  } else {
    changedAttributes = ({ [`objectId`]: (getObjectProperty(currentUser, 'objectId')),[`inviteStatus`]: 'ACCEPTED',[`status`]: 'ACTIVE',[`password`]: password });
  }
  for (var attribute_index in inputs) {
    attribute = inputs[attribute_index];
    if (attribute) {
      if (fullName && index == 0 && fullName != (getObjectProperty(currentUser, 'name'))) {
        changedAttributes['name'] = fullName;
      } else if (phoneNumber && index == 1 && phoneNumber != (getObjectProperty(currentUser, 'phoneNumber'))) {
        changedAttributes['phoneNumber'] = phoneNumber;
      }
    }
    index = (typeof index == 'number' ? index : 0) + 1;
  }
  try {
    updatedUserData = (await Backendless.UserService.update( new Backendless.User(changedAttributes) ));
    if (updatedUserData) {
      await Backendless.Hive('emailCache').KeyValueStore.delete([(getObjectProperty(updatedUserData, 'email'))]);
      if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
        if (getObjectProperty(___arguments.context.pageData, 'orgAdmin')) {
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(updatedUserData, 'email')), 'd-1a8d6cec3f3648eaa5cd43fbf95f1af6', ({ [`organization_admin`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('created', null, ___arguments.context.appData)) }));
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(___arguments.context.pageData, 'adminEmail')), 'd-2ae0a502c6574f78a7b7eb6898666ab2', ({ [`organization_admin`]: (getObjectProperty(___arguments.context.pageData, 'orgAdmin.name')),[`case_manager`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')) }));
        } else {
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(updatedUserData, 'email')), 'd-1a8d6cec3f3648eaa5cd43fbf95f1af6', ({ [`organization_admin`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('created', null, ___arguments.context.appData)) }));
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', 'engineers@cyberdive.co', 'd-c3d2e65bfd6042c3986bf58389cb2a75', ({ [`super_admin`]: 'Super Admin',[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`organization_admin`]: (getObjectProperty(updatedUserData, 'name')) }));
        }
      } else if ((getObjectProperty(currentUser, 'userRole')) == 'manager') {
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(updatedUserData, 'email')), 'd-1a8d6cec3f3648eaa5cd43fbf95f1af6', ({ [`organization_admin`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('created', null, ___arguments.context.appData)) }));
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(___arguments.context.pageData, 'adminEmail')), 'd-2ae0a502c6574f78a7b7eb6898666ab2', ({ [`organization_admin`]: (getObjectProperty(___arguments.context.pageData, 'orgAdmin.name')),[`case_manager`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')) }));
      }
      loggedInUser = (await Backendless.UserService.login( (getObjectProperty(updatedUserData, 'email')), password, true  ));
      if (loggedInUser) {
        ___arguments.context.pageData['pageVariant'] = 'success';
      } else {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
      }
    }

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was a problem registering your account.' }));
    await isButtonLoading(false);

  }
  await isButtonLoading(false);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, phoneNumber, isButtonLoading, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  phoneNumber = (getObjectProperty(___arguments.context.pageData, 'phoneNumber'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  isDisabled = false;
  if (isButtonLoading) {
    isDisabled = true;
  } else {
    if (!phoneNumber || !(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](phoneNumber, 'remove'))))) {
      isDisabled = true;
    }
  }
  ___arguments.context.pageData['isDetailsButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDetailsButtonDisabled')) {
    classes = ['disabled_button'];
  } else {
    classes = ['enabled_button'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/f32e18e7a8496f192e2e041cb196e14d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'details') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f32e18e7a8496f192e2e041cb196e14d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f32e18e7a8496f192e2e041cb196e14d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/727122fedc7a43dd55a5e1912b1c6424/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, confirmPassword, password, fullName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  ___arguments.context.pageData['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(currentUser, 'phoneNumber')), 2)), 'create'));
  ___arguments.context.pageData['pageVariant'] = 'details';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isDisabled, confirmPassword, password, fullName, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  fullName = (getObjectProperty(___arguments.context.pageData, 'fullName'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  isDisabled = false;
  if (!(typeof fullName === 'undefined') && ((new RegExp('^\\s*$', '')).test(fullName))) {
    isDisabled = true;
  } else if (password != confirmPassword) {
    isDisabled = true;
  } else if ((typeof password === 'undefined') || ((new RegExp('^\\s*$', '')).test(password))) {
    isDisabled = true;
  } else if ((typeof confirmPassword === 'undefined') || ((new RegExp('^\\s*$', '')).test(confirmPassword))) {
    isDisabled = true;
  } else if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    isDisabled = true;
  }
  ___arguments.context.pageData['isCreateAccountButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isCreateAccountButtonDisabled')) {
    classes = ['disabled_button'];
  } else {
    classes = ['enabled_button'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/eda9a85230075edcd876bc91c2bea2f4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var confirmPassword, password;


  ___arguments.context.pageData['passwordError'] = false;
  ___arguments.context.pageData['password'] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](___arguments.value))) {
    ___arguments.context.pageData['passwordError'] = true;
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    classesList = ['input_red_border'];
  } else {
    classesList = ['input_grey_border'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/40ecb9a8528c6955f3395e0d97f8749b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var confirmPassword, password;


  ___arguments.context.pageData['confirmPassword'] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](___arguments.value))) {
    ___arguments.context.pageData['passwordError'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Register/components/856b5a2cc132c423f35e116180995679/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('856b5a2cc132c423f35e116180995679', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('856b5a2cc132c423f35e116180995679', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/5f26533173d433e2e07a557a73993d17/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'contactError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5f26533173d433e2e07a557a73993d17', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5f26533173d433e2e07a557a73993d17', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/e267796cb35203c40adc7cb715999aea/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'picture'))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  if (getObjectProperty(___arguments.context.pageData, 'profilePicture')) {
    ___arguments.context.pageData['picture'] = (await readBrowserFileAs(((getObjectProperty(___arguments.context.pageData, 'profilePicture'))[0]), 'base64'));
    ___arguments.context.pageData['imageName'] = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'profilePicture'))[0]), 'name'));
  } else {
    ___arguments.context.pageData['picture'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/5bbd9d9eb26e9a2bf2ec6cb263f98c06/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
      ___arguments.context.pageData['profilePicture'] = ___arguments.selectedFiles;
  ___arguments.context.pageData['hasUploadedPhoto'] = true;

  },
  /* handler:onSelectFiles */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasUploadedPhoto')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5bbd9d9eb26e9a2bf2ec6cb263f98c06', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5bbd9d9eb26e9a2bf2ec6cb263f98c06', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/f4bb32b9d32257d2699ce53c6ec18823/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'imageName'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/Register/components/6f8f85e203697f748146538515f6fbb3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasUploadedPhoto')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6f8f85e203697f748146538515f6fbb3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6f8f85e203697f748146538515f6fbb3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['profilePicture'] = undefined;
  ___arguments.context.pageData['imageName'] = undefined;
  ___arguments.context.pageData['hasUploadedPhoto'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/78b93de5b385f65153796f5fc3affe4c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'success') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('78b93de5b385f65153796f5fc3affe4c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('78b93de5b385f65153796f5fc3affe4c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/36f95f0724e9b9d6422effed44359092/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/d275aaf4ebd64fbfbe490acf97bb5442/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'expiredLink') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d275aaf4ebd64fbfbe490acf97bb5442', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d275aaf4ebd64fbfbe490acf97bb5442', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/fedbf4bbf26d51d50a8b40512ba16e82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://mail.google.com/mail/u/0/?f=1&source=mailto&to=support@aquax.co&fs=1&tf=cm', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/c4835269178f1549371e0a543d293886/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ___arguments.context.pageData['viewingPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('610d142419973fd3db3672f232c51f11'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('eda9a85230075edcd876bc91c2bea2f4'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('610d142419973fd3db3672f232c51f11'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('eda9a85230075edcd876bc91c2bea2f4'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    classesList = ['view_password_red'];
  } else {
    classesList = ['view_password'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/7668585de0d4ccc17b8c3db584490098/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('83881cec1a77fc345aed4a011bfc4e31'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('40ecb9a8528c6955f3395e0d97f8749b'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('83881cec1a77fc345aed4a011bfc4e31'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('40ecb9a8528c6955f3395e0d97f8749b'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ___arguments.context.pageData['viewingConfirmPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingConfirmPassword'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/9991e77e90765b2192493e93f1ed6e25/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'createAccount') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9991e77e90765b2192493e93f1ed6e25', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9991e77e90765b2192493e93f1ed6e25', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/007b961899db9acd1ec89c2d56cece42/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Welcome! Complete your account set up to join ',(getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')),'.'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Register/components/4d03c5560f58408d43a9919f3ea4ecc6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['You can now access ',(getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')),' Aqua X portal.'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Register/components/87c6e9bcd88306bc15a6904cc7e429ba/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var confirmPassword, password;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  if (password != confirmPassword) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('87c6e9bcd88306bc15a6904cc7e429ba', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('87c6e9bcd88306bc15a6904cc7e429ba', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/4dd10e74dd8ae0db2f536e31edba2b95/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dd10e74dd8ae0db2f536e31edba2b95', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dd10e74dd8ae0db2f536e31edba2b95', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Resident/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_c022336da0dd1fcbccef0e5929a35855'](___arguments.context.appData);
  if (!(await BackendlessUI.Functions.Custom['fn_3552157be2fff99d18ceeb6b2715c8b3'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')));
  }
  await BackendlessUI.Functions.Custom['fn_98e6d7676a68625bb37c0ec89d66dff7'](___arguments.context.pageData);
  ___arguments.context.pageData['currentPage'] = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  ___arguments.context.pageData['refreshResidentTable'] = true;
  ___arguments.context.pageData['refreshActiveCurfews'] = true;
  ___arguments.context.pageData['refreshToken'] = true;
  await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Resident/components/6e5a3178017cc0c3fcedab916b8f3896/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e5a3178017cc0c3fcedab916b8f3896', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e5a3178017cc0c3fcedab916b8f3896', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Resident/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getCurrentUserInfo() {
  try {
    ___arguments.context.appData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'There was a problem loading the user information.', 'failure');

  }
}

/**
 * Describe this function...
 */
async function refreshMDMTokenInBackend() {
  try {
    await BackendlessUI.Functions.Custom['fn_f15eaeb413dd69197945a3bae68dcbde']();

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error refreshing MDM token.', 'failure');

  }
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', true);
  }
  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if (currentUser) {
    if (getObjectProperty(___arguments.context.pageData, 'refreshUser')) {
      await getCurrentUserInfo();
      ___arguments.context.pageData['refreshUser'] = undefined;
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'refreshActiveCurfews')) {
    ___arguments.context.pageData['refreshActiveCurfews'] = undefined;
    try {
      ___arguments.context.pageData['activeCurfews'] = (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('allActiveCurfews', null, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')), null, null, null, null))).setPageSize(100)));

    } catch (error) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error loading curfews.', 'failure');

    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'refreshToken')) {
    ___arguments.context.pageData['refreshToken'] = undefined;
    try {
      await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/getConfigurations`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify((getObjectProperty(((await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'mdmToken\'').setPageSize(10)))[0]), 'value'))) }).send();

    } catch (error) {
      await refreshMDMTokenInBackend();

    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;


  if ((function () { return BackendlessUI.isProd })()) {
    classes = ['dashboardPage', 'dashboard_display_none'];
  } else {
    classes = ['dashboardPage'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Resident/components/3ffbfa061eb6962af60b836dfca4e15d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ffbfa061eb6962af60b836dfca4e15d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ffbfa061eb6962af60b836dfca4e15d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Resident/components/799abf183752a5d17c5e6ce2c3eb381f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799abf183752a5d17c5e6ce2c3eb381f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799abf183752a5d17c5e6ce2c3eb381f', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Resident/components/d1ee767a225b09c8be56b395c7158a5b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1ee767a225b09c8be56b395c7158a5b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1ee767a225b09c8be56b395c7158a5b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Resident/components/5e1eda18457d1214140137363c96422d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    ___arguments.context.pageData['isSupportDrawerOneOpen'] = false;
  } else {
    ___arguments.context.pageData['isSupportDrawerOneOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Resident/components/d6c3b549b345eb750d61e05da694d8f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['supportGuideSelection'] = 'admin';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Resident/components/d14cc726548d75559a5f88d25078b35f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['supportGuideSelection'] = 'manager';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Resident/components/f4e3431a8ee5f175dafc9ca1c95e09ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Resident/components/9bfc106e638c9404219eca1d442c5f7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Resident/components/b654715969d283f96f591c87c71d1aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Resident/components/a34f8d73b6e88304bf151a46865e54ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Resident/components/9632a44ca7adb2c6b6f8be192ea6af28/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'supportGuide' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9632a44ca7adb2c6b6f8be192ea6af28', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9632a44ca7adb2c6b6f8be192ea6af28', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Resident/components/f22ffaf8518d876867984e9a50eb1a1d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f22ffaf8518d876867984e9a50eb1a1d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f22ffaf8518d876867984e9a50eb1a1d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Resident/components/2c3c38c5109a029b53d41f141b471bfc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c3c38c5109a029b53d41f141b471bfc', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c3c38c5109a029b53d41f141b471bfc', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/User/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_c022336da0dd1fcbccef0e5929a35855'](___arguments.context.appData);
  if (!(await BackendlessUI.Functions.Custom['fn_3552157be2fff99d18ceeb6b2715c8b3'](___arguments.context.appData))) {
    await BackendlessUI.Functions.Custom['fn_d50ff552eac48bf53ee4dedc6cfda438'](___arguments.context.appData, ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')));
  }
  await BackendlessUI.Functions.Custom['fn_98e6d7676a68625bb37c0ec89d66dff7'](___arguments.context.pageData);
  ___arguments.context.pageData['refreshUserTable'] = true;
  ___arguments.context.pageData['currentPage'] = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_c66ba9f6afd59bbb0a0624bbe1fb4ed9'](___arguments.context.pageData);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/User/components/39677fcdcabe89bcccbf7579ed510819/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39677fcdcabe89bcccbf7579ed510819', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39677fcdcabe89bcccbf7579ed510819', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/User/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51278f5d59b7d2def311de9cbd62d77f', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/6fd2ae514584121d81a69d1e02d4a4a4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var tableCount, response, currentUser, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'userTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'userTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'userTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/06dc27e50ba5576541ae82e5c1c9fd82/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  changedValue = ___arguments.value.split('\'').join('');
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  ___arguments.context.pageData['changedValueUser'] = ___arguments.value;
  ___arguments.context.pageData['noSearchResultsUser'] = false;
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', []);
  if (currentUser) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', (await Backendless.Data.of('ManagementUserRecords').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
    ___arguments.context.pageData['userTableCount'] = (await Backendless.Data.of('ManagementUserRecords').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null)))));
    ___arguments.context.pageData['isTableLoading'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/fa69bb2c96e74fe43017e2ba90cbee0e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['recordTableVariant'] = 'addUser';
  ___arguments.context.pageData['userUpdateObject'] = ({  });
  ___arguments.context.pageData['selectedItemUserRole'] = 'User Role';
  ___arguments.context.pageData['selectedItemUser'] = undefined;
  ___arguments.context.pageData['userRoleState'] = undefined;
  ___arguments.context.pageData['organizationState'] = undefined;
  ___arguments.context.pageData['selectedItemUser.orgName'] = 'Organization';
  ___arguments.context.pageData['isUserButtonDisabledObject'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`organization`]: true });
  ___arguments.context.pageData['scrollToTop'] = true;
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    return 'Add User';
  } else {
    return 'Add Team Member';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/29a3c4a2eacb179cf15ca70b7f79829b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'email')), 20))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/50d9e8e500d74bc4f5c7d286596d0ba3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var inviteStatus;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  inviteStatus = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus'));
  if (inviteStatus == 'ACCEPTED') {
    return 'Accepted';
  } else if (inviteStatus == 'PENDING' || inviteStatus == 'RESENT') {
    return 'Pending';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'ACCEPTED') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/261c8d46b0832dc8f76b74e45bf687cd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'created')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'created')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/11bc3ea9a7b67c3227a44b0562f3d144/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('11bc3ea9a7b67c3227a44b0562f3d144', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('11bc3ea9a7b67c3227a44b0562f3d144', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/7a7927e52b023c594efe426ff47276c5/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    var userSearchCount, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'userTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'userTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'userTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var currentUser, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', []);
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueUser'));
  if (currentUser) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', (await Backendless.Data.of('ManagementUserRecords').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/b6f38bb44f15f006f1378a84732925aa/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser, changedValue, refreshUserTable;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshUserTable2() {
  try {
    ___arguments.context.pageData['refreshUserTable'] = undefined;
    ___arguments.context.pageData['isTableLoading'] = true;
    currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
    changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueUser'));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', []);
    if (currentUser) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', (await Backendless.Data.of('ManagementUserRecords').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['userTableCount'] = (await Backendless.Data.of('ManagementUserRecords').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null)))));
      if ((getObjectProperty(___arguments.context.pageData, 'userTableCount')) == 0) {
        ___arguments.context.pageData['noResultsScreen'] = true;
      }
    }
    ___arguments.context.pageData['selectedItemArrayUser'] = [];
    ___arguments.context.pageData['isTableLoading'] = false;

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load user table.', 'failure');

  }
}


  refreshUserTable = (getObjectProperty(___arguments.context.pageData, 'refreshUserTable'));
  if (refreshUserTable) {
    await refreshUserTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/b77e42abc126fd5335c7fa7123ac4e61/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, orgTableCount, navbarSelection, residentTableCount, userTableCount, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userTableCount = (getObjectProperty(___arguments.context.pageData, 'userTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (userTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b77e42abc126fd5335c7fa7123ac4e61', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b77e42abc126fd5335c7fa7123ac4e61', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/9dcf41b401f59ff3646a9c5b29d91654/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'phoneNumber')), 2)), 'create'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/08ff3935143191fe44a53dc53a28fe33/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteSent')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteSent')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/fa1c20b2fac9b83e9e6551afbd958ffd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'userRole')) == 'admin') {
    return 'Admin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'userRole')) == 'manager') {
    return 'Manager';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/d82cd2ce922de5d95816fb8a30444e8b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/35931dd7b8a149d9723573ee2cc2977e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    classesList = ['incomplete_status_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    return 'Invite Resent';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING') {
    return 'Invite Sent';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    return 'Locked';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/947cb392074a6d82bb8b1a9488543f37/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('947cb392074a6d82bb8b1a9488543f37', false);
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('947cb392074a6d82bb8b1a9488543f37', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/9335eddd260e709439b74c4577e878a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'ACCEPTED') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/d6f6f584d05a70052404b3e53ddb9218/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'profilePicture')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/8447c014e21bcca39a6ff786460f89cf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemUser'] = ___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab');
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['recordsSelection'] = 'userData';
  ___arguments.context.pageData['isViewDetailsOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/46058a9560670eb82e3ecce59fa840f3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46058a9560670eb82e3ecce59fa840f3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46058a9560670eb82e3ecce59fa840f3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/b079aaf6bce25e640d6799afe66eedbc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemUser'] = ___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab');
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['recordsSelection'] = 'userData';
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['isViewDetailsOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/1af3ed0b63275c6887a1085f84a9505a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    return 'User Records';
  } else {
    return 'Team Records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/bbd670ac909779f6736f3655981c8ae1/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
  residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueResident'));
  if (currentUser && !(getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter'))) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
    ___arguments.context.pageData['isTableLoading'] = false;
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueResident'));
  if (getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter')) {
    ___arguments.context.pageData['isResidentRecordFilterDrawerOpen'] = false;
    ___arguments.context.pageData['hasClickedApplyFilter'] = false;
    ___arguments.context.pageData['isTableLoading'] = true;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
    if (currentUser) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['isTableLoading'] = false;
      ___arguments.context.pageData['residentTableCount'] = (await Backendless.Data.of('ResidentInformation').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null)))));
    }
    ___arguments.context.pageData['isTableLoading'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/80c7e9324455f0620ca672e70558689e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80c7e9324455f0620ca672e70558689e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80c7e9324455f0620ca672e70558689e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/ceedae56aca9857b5cdf3292d47a8094/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'residentTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/cf21deee1441481bf3d3a19f1f8271ce/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  changedValue = ___arguments.value.split('\'').join('');
  residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
  ___arguments.context.pageData['changedValueResident'] = changedValue;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['residentTableCount'] = (await Backendless.Data.of('ResidentInformation').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null)))));
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/9ad8462142dd3943bab636487518c838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    ___arguments.context.pageData['isResidentRecordActionDrawerOpen'] = false;
    ___arguments.context.pageData['userClickedResidentAction'] = false;
  } else {
    ___arguments.context.pageData['isResidentRecordActionDrawerOpen'] = true;
    ___arguments.context.pageData['userClickedResidentAction'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/4db83ef9ca89b79b5275a4ab68f71a86/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4db83ef9ca89b79b5275a4ab68f71a86', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4db83ef9ca89b79b5275a4ab68f71a86', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/02d79d2f7f6b67f6c447e9d7fda5d77c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'profilePicture')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/5a77fb599fd92f9f30d3d44e1733a7c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedArray = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayResident'));
  ___arguments.context.pageData['isCurfewModalOpen'] = true;
  if (selectedArray && (getObjectProperty((selectedArray[0]), 'curfew'))) {
    ___arguments.context.pageData['curfewArray'] = (JSON.parse((getObjectProperty((selectedArray[0]), 'curfew'))));
    ___arguments.context.pageData['curfewModalSteps'] = 4;
  } else {
    ___arguments.context.pageData['curfewModalSteps'] = 1;
    ___arguments.context.pageData['curfewArray'] = [];
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var selectedItemArrayResident;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayResident = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayResident'));
  if (selectedItemArrayResident && selectedItemArrayResident.length == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8d42e7e69c46fdb56b22f25cb3236ea'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c093041f0d2956d1e01921dc1c0a2c49'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8d42e7e69c46fdb56b22f25cb3236ea'))['color'] = 'rgb(0,0,0,0.3)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c093041f0d2956d1e01921dc1c0a2c49'))['color'] = 'rgb(0,0,0,0.3)';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/49b18622d00f56dec3eabe384e5a9a8e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshResidentTable, currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshResidentTable2() {
  try {
    ___arguments.context.pageData['refreshResidentTable'] = undefined;
    ___arguments.context.pageData['isTableLoading'] = true;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
    currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
    changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueResident'));
    residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
    ___arguments.context.pageData['isTableLoading'] = false;
    ___arguments.context.pageData['residentTableCount'] = (await Backendless.Data.of('ResidentInformation').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null)))));
    if ((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) == 0) {
      ___arguments.context.pageData['noResultsScreen'] = true;
    }

  } catch (error) {
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (['Failed to load ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),' table.'].join('')), 'failure');
    } else {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load table.', 'failure');
    }

  }
}


  refreshResidentTable = (getObjectProperty(___arguments.context.pageData, 'refreshResidentTable'));
  if (refreshResidentTable) {
    await refreshResidentTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['residentFilterArray'] = [];
  ___arguments.context.pageData['residentTableFilter'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/d34853610e1b715f8f0fd1bd474b627c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'nameInUse')), 20))

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemResident'] = ___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c');
  ___arguments.context.pageData['selectedItemDeviceIMEI'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceIMEI'));
  ___arguments.context.pageData['residentIntakeForm'] = (JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'residentIntakeForm'))));
  ___arguments.context.pageData['recordSelection'] = 'residentRecords';
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['isViewDetailsOpen'] = true;
  ___arguments.context.pageData['loadDeviceDetails'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/40a8c152f26f671d40bef9723be82425/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, isFilerDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isFilerDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordFilterDrawerOpen'));
  if (isFilerDrawerOpen) {
    ___arguments.context.pageData['residentFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).map(async item => {  if ((getObjectProperty(___arguments.context.pageData, 'residentTableFilter')).includes(item)) {
        return item;
      }
    ; return ;})));
    ___arguments.context.pageData['isResidentRecordFilterDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['residentFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'residentTableFilter')).map(async item => {; return item;})));
    ___arguments.context.pageData['isResidentRecordFilterDrawerOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/861fbdccb0b74c8cf36f674da186c486/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isFilterDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isFilterDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordFilterDrawerOpen'));
  if (isFilterDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('861fbdccb0b74c8cf36f674da186c486', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('861fbdccb0b74c8cf36f674da186c486', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/889b70b36329145268e9930aeeee61d9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'1\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'1\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'1\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/c14e0776464e9179d421dd6c833c3748/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'2\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'2\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'2\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/cfd5972870c2827598e37a4f774e33ba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'3\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'3\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'3\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/857af952d29c4c38896d5a0b8b3f7aa1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'4\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'4\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'4\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/2535743c6ea66666763f27f5a18d1b32/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['recordTableVariant'] = 'resident';
  ___arguments.context.pageData['residentIntakeVariant'] = 'intake';
  ___arguments.context.pageData['residentIntakeStep'] = 1;
  ___arguments.context.pageData['isNewResident'] = true;
  ___arguments.context.pageData['selectedResidentImage'] = undefined;
  ___arguments.context.pageData['selectedResidentImageFile'] = undefined;
  ___arguments.context.pageData['residentIntakeForm'] = ({ [`residentObjectId`]: undefined,[`pillarInfo`]: undefined,[`profilePicture`]: undefined,[`residentIntakeStep`]: undefined,[`personalDetails`]: ({ [`race`]: ({ [`answer`]: 'default',[`other`]: undefined }),[`genderIdentity`]: ({ [`answer`]: undefined,[`other`]: undefined }),[`pronouns`]: ({ [`answer`]: undefined,[`other`]: undefined }) }),[`emergencyContact`]: ({ [`relationship`]: ({ [`answer`]: undefined,[`other`]: undefined }) }) });
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  ___arguments.context.pageData['scrollToTop'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/de16b2149bf4d8ee51b3767904858d01/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, orgTableCount, navbarSelection, residentTableCount, userTableCount, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentTableCount = (getObjectProperty(___arguments.context.pageData, 'residentTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (residentTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('de16b2149bf4d8ee51b3767904858d01', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('de16b2149bf4d8ee51b3767904858d01', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/43ef7d12266ba0cd487a51ff8e29152e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['selectedItemResident'] = ___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c');
  ___arguments.context.pageData['selectedItemDeviceIMEI'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceIMEI'));
  ___arguments.context.pageData['residentIntakeForm'] = (JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'residentIntakeForm'))));
  ___arguments.context.pageData['recordSelection'] = 'residentRecords';
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['isViewDetailsOpen'] = true;
  ___arguments.context.pageData['loadDeviceDetails'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/e3787089737a91825c38bb77f46d5556/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'status = \'ACTIVE\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'status = \'ACTIVE\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('status = \'ACTIVE\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/279dd6edae63568c8aec736f68e61a99/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'status = \'DEACTIVED\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'status = \'DEACTIVED\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('status = \'DEACTIVED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/0088d46d1e9540d9458fcb341b7c9ef7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'status = \'ONBOARDSTARTED\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'status = \'ONBOARDSTARTED\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('status = \'ONBOARDSTARTED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/0aeb2854b7d90687b69e90f29ec4dfeb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceId')), 10));
  } else {
    return 'Assign Device';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    classesList = ['resident_device_text'];
  } else {
    classesList = ['resident_assign_device_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/f818188a32db356b0879ed92a389eaab/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var pillar;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pillar = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'pillar'));
  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return ([(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',pillar].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/b9e9ff39776add88908a66b9b8ef3058/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    classesList = ['resident_device_icon'];
  } else {
    classesList = ['resident_assign_device_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/9a4f0e9f13b95bf6044ee74367145739/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    classesList = ['resident_device_block'];
  } else {
    classesList = ['resident_assign_device_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_b9b8c396542304a434a3b2fe738b799a'](___arguments.context.appData, ___arguments.context.pageData, ___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/c8dd4866a2c82877586beb434508f385/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'pillarHistory'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c8dd4866a2c82877586beb434508f385', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c8dd4866a2c82877586beb434508f385', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/7ccef90bd13bc0610c9902dacc1a4d39/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'pillarHistory')) && (getObjectProperty(((JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'pillarHistory')))).slice(-1)[0]), 'pillar'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7ccef90bd13bc0610c9902dacc1a4d39', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7ccef90bd13bc0610c9902dacc1a4d39', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/01476da76ca22c09742d2ba8b0455751/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['hasClickedApplyFilter'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      ___arguments.context.pageData['residentTableFilter'] = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
    } else {
      ___arguments.context.pageData['residentTableFilter'] = [];
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      classes = ['filterButton', 'apply_filter_button'];
    } else {
      classes = ['filterButton', 'apply_filter_button_disabled'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/08c78f5cf781446efb68d0c7e2242345/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      ___arguments.context.pageData['hasClickedApplyFilter'] = true;
      ___arguments.context.pageData['residentTableFilter'] = [];
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      classes = ['filterText'];
    } else {
      classes = ['filterText', 'clear_filter_button_disabled'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/b73dcbffa539fb7b1fb26e11c1bbbacb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'INCOMPLETE') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/ffb5ee3828e99cbf05469ff368483803/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'INCOMPLETE') {
    classesList = ['incomplete_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/b8cd49bf508ca96c4995921668c3a283/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ONBOARDSTARTED' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ONBOARDPENDING') {
    classesList = ['incomplete_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'INCOMPLETE') {
    return 'Intake Incomplete';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/63f5fa7741354c4c709387ba7d34c4c1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'lastLocation')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'lastLocation'));
  } else {
    return 'No Location Available';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/8c7202dad808f59145e2497a040fe1b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Records'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/ce8241c3bd4fe4bb8e28228612b26452/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Details'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/04ea46979e858cae86de09f59ec8010e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String('Assigned ') + String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/e3b13ed17dbafe5e88742f161093e501/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'deviceTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/8c1404b4be23350cdeeac37e2a2b8e45/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueDevice'));
  if (deviceTableFilter && (getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter'))) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
    ___arguments.context.pageData['hasClickedApplyFilter'] = false;
    ___arguments.context.pageData['isTableLoading'] = true;
    if (currentUser) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['isTableLoading'] = false;
      ___arguments.context.pageData['deviceTableCount'] = (await Backendless.Data.of('Devices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null)))));
    }
    ___arguments.context.pageData['isTableLoading'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
  deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueDevice'));
  if (currentUser && !(getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter'))) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
    ___arguments.context.pageData['isTableLoading'] = false;
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/1a09a691d146abe25932ae6194fa5792/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshDeviceTable, deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshDeviceTable2() {
  try {
    ___arguments.context.pageData['refreshDeviceTable'] = undefined;
    ___arguments.context.pageData['isTableLoading'] = true;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
    currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
    changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueDevice'));
    deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10))));
    ___arguments.context.pageData['isTableLoading'] = false;
    ___arguments.context.pageData['deviceTableCount'] = (await Backendless.Data.of('Devices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null)))));
    if ((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) == 0) {
      ___arguments.context.pageData['noResultsScreen'] = true;
    }

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load device table.', 'failure');

  }
}


  refreshDeviceTable = (getObjectProperty(___arguments.context.pageData, 'refreshDeviceTable'));
  if (refreshDeviceTable) {
    await refreshDeviceTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['deviceFilterArray'] = [];
  ___arguments.context.pageData['deviceTableFilter'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/f39b23d4d26d7cec995b049ca37e7ff9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f39b23d4d26d7cec995b049ca37e7ff9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f39b23d4d26d7cec995b049ca37e7ff9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/cd8ce0c2828f8e74beb2302a39ee23ce/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, deviceTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceTableCount = (getObjectProperty(___arguments.context.pageData, 'deviceTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (deviceTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cd8ce0c2828f8e74beb2302a39ee23ce', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cd8ce0c2828f8e74beb2302a39ee23ce', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/9418bb35cc2651211f0baffa1756bd8b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return ([(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.pillar'))].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/a56ea43892389d119d2cbb0c7b4c6b97/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.profilePicture')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/38ac316743a84f55662cdaeab9c4362d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  changedValue = ___arguments.value.split('\'').join('');
  deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
  ___arguments.context.pageData['changedValueDevice'] = changedValue;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['deviceTableCount'] = (await Backendless.Data.of('Devices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null)))));
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/6c99021fb63346a7ecdc67ea04399829/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, isDeviceRecordFilterDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isDeviceRecordFilterDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDeviceRecordFilterDrawerOpen'));
  if (isDeviceRecordFilterDrawerOpen) {
    ___arguments.context.pageData['deviceFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'deviceFilterArray')).map(async item => {  if ((getObjectProperty(___arguments.context.pageData, 'deviceTableFilter')).includes(item)) {
        return item;
      }
    ; return ;})));
    ___arguments.context.pageData['isDeviceRecordFilterDrawerOpen'] = false;
    ___arguments.context.pageData['userClickedDeviceFilter'] = false;
  } else {
    ___arguments.context.pageData['deviceFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'deviceTableFilter')).map(async item => {; return item;})));
    ___arguments.context.pageData['isDeviceRecordFilterDrawerOpen'] = true;
    ___arguments.context.pageData['userClickedDeviceFilter'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/694ffadbb2f51d0d196b70ee69333c84/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isFilterDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isFilterDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDeviceRecordFilterDrawerOpen'));
  if (isFilterDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('694ffadbb2f51d0d196b70ee69333c84', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('694ffadbb2f51d0d196b70ee69333c84', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/c2078ad1a81caf3e0a6d038f55ae18f3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (___arguments.value) {
    addItemToList(deviceFilterArray, 'deviceStatus = \'ASSIGNED\'');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  } else {
    removeItemInList(deviceFilterArray, 'deviceStatus = \'ASSIGNED\'', '');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (deviceFilterArray && (deviceFilterArray.includes('deviceStatus = \'ASSIGNED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/5e3139ef82be6bf1d342d7b4408dfa89/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (___arguments.value) {
    addItemToList(deviceFilterArray, 'deviceStatus = \'DEACTIVATED\'');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  } else {
    removeItemInList(deviceFilterArray, 'deviceStatus = \'DEACTIVATED\'', '');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (deviceFilterArray && (deviceFilterArray.includes('deviceStatus = \'DEACTIVATED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/5994bad587fc136537c374977fd79ed8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['hasClickedApplyFilter'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'deviceFilterArray')).length > 0) {
      ___arguments.context.pageData['deviceTableFilter'] = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
    } else {
      ___arguments.context.pageData['deviceTableFilter'] = [];
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/303fb0063948a44b14830d0e260e74ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var resident;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  resident = (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.objectId')),'\''].join(''))).setPageSize(10)))[0];
  if (resident) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Resident', ({ [`loadDeviceDetails`]: true,[`loadLocationMap`]: true,[`recordSelection`]: 'deviceRecords',[`selectedItemDeviceIMEI`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'deviceIMEI')),[`selectedItemDetailsSelection`]: 'details',[`isViewDetailsOpen`]: true,[`selectedItemResident`]: resident,[`residentIntakeForm`]: (JSON.parse((getObjectProperty(resident, 'residentIntakeForm')))),[`selectedItemDevice`]: ___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'),[`isMapLoading`]: true }));
  }

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.nameInUse')), 10))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/ce7f08059a8dc71def2c731b2f8cc116/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDevice'] = ___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e');
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['isViewDetailsOpen'] = true;
  ___arguments.context.pageData['loadDeviceDetails'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/2a2161338b16644a9ea3dfd70f5fee86/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDevice'] = ___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e');
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['loadLocationMap'] = true;
  ___arguments.context.pageData['isViewDetailsOpen'] = true;
  ___arguments.context.pageData['loadDeviceDetails'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/9c5f436b90efcab1065c4992b331436d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'created')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'created')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_573384045ca814e1fb830ae43d034f55/logic/36f7d69ff6d3a2cdb53868ee96be3157/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isToastVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasToastError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36f7d69ff6d3a2cdb53868ee96be3157', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36f7d69ff6d3a2cdb53868ee96be3157', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_573384045ca814e1fb830ae43d034f55/logic/56d4a14f0364823937fc867de6bb6145/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var toasts, firstItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isToastVisible')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('56d4a14f0364823937fc867de6bb6145', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('56d4a14f0364823937fc867de6bb6145', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_573384045ca814e1fb830ae43d034f55/logic/8e42d14edea8249e307f6fe107d61f18/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasToastError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8e42d14edea8249e307f6fe107d61f18', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8e42d14edea8249e307f6fe107d61f18', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/222153f2495c59f937e8e0b8564793f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isConfirmationModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/9fd4a8dc0c547afd009b2cbfa8427434/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    /**
 * Describe this function...
 */
async function clearInputs() {
  ___arguments.context.pageData['isDeactivatingOrganization'] = undefined;
  ___arguments.context.pageData['isDeactivatingUser'] = undefined;
  ___arguments.context.pageData['isCancellingInvite'] = undefined;
  ___arguments.context.pageData['isResendingInvite'] = undefined;
}


  ___arguments.context.pageData['isConfirmationModalOpen'] = false;
  await clearInputs();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/ed3805d4409eac6f71e284448efe62f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    /**
 * Describe this function...
 */
async function clearInputs() {
  ___arguments.context.pageData['isDeactivatingOrganization'] = undefined;
  ___arguments.context.pageData['isDeactivatingUser'] = undefined;
  ___arguments.context.pageData['isCancellingInvite'] = undefined;
  ___arguments.context.pageData['isResendingInvite'] = undefined;
}


  ___arguments.context.pageData['isConfirmationModalOpen'] = false;
  await clearInputs();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/43782f368d547ff3517ad9de48364a8e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43782f368d547ff3517ad9de48364a8e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43782f368d547ff3517ad9de48364a8e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/06d7dd1c011e42a79e0bb26d0ef17ff3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06d7dd1c011e42a79e0bb26d0ef17ff3', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06d7dd1c011e42a79e0bb26d0ef17ff3', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/d515bb036dac369987aba1977af812bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var confirmationOption;


  ___arguments.context.pageData['isConfirmationModalOpen'] = false;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(___arguments.context.pageData, 'confirmationOption')) == 'deactivateFirst')) {
    confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
    if (confirmationOption == 'saveResidentForm') {
      return 'Don\'t Save';
    } else if (confirmationOption == 'deactivateDevice') {
      return 'Cancel';
    } else {
      return 'Back';
    }
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(___arguments.context.pageData, 'confirmationOption')) == 'deactivateFirst')) {
    confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
    if (confirmationOption == 'saveResidentForm' || confirmationOption == 'activateUser' || confirmationOption == 'activateCurfew' || confirmationOption == 'resendUser' || confirmationOption == 'activateOrganization') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', true);
      classes = ['red_button_outlined'];
    } else if (confirmationOption == 'deleteActiveCurfew') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', true);
      classes = ['enabled_button_outlined'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'confirmationOption')) == 'deactivateFirst') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/a9d41a5b78c58c0d93fbc6c8bd52de1d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var toast, confirmationOption, fcmResponse, newContactArray, curfew, deviceObjectId, adminContacts, FCMToken, currentUser, selectedItemCurfew, selectedItemUser, sendGridResponse, uniqueID, sendEmailResponse, userTableResponse, selectedItemOrganization, organizationTableResponse, user, updateUserResponse, usersOfOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
  ___arguments.context.pageData['isLoadingAction'] = false;
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function deactivateAllUsers() {
  try {
    usersOfOrganization = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['organizationId.objectId =','\'',(getObjectProperty(selectedItemOrganization, 'objectId')),'\''].join(''))).setRelationsDepth(2).setPageSize(10)));
    for (var user_index in usersOfOrganization) {
      user = usersOfOrganization[user_index];
      updateUserResponse = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`status`]: 'DEACTIVATED' })) ));
      if (updateUserResponse) {
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(user, 'email')), 'd-a410c96816e6428ba745f8fbc404484c', ({ [`organization_name`]: (getObjectProperty(selectedItemOrganization, 'orgName')) }));
      }
    }

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was an issue deactivating the users.' }));

  }
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  selectedItemCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew'));
  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  ___arguments.context.pageData['isLoadingAction'] = true;
  if (confirmationOption == 'deactivateOrganization') {
    try {
      organizationTableResponse = (await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(selectedItemOrganization, 'objectId')),[`status`]: 'DEACTIVATED' }) ));
      await deactivateAllUsers();
      (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'))['status'] = 'DEACTIVATED';
      ___arguments.context.pageData['refreshOrgTable'] = true;
      ___arguments.context.pageData['isOrganizationRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayOrg'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String(getObjectProperty(selectedItemOrganization, 'orgName')) + String(' successfully deactivated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error deactivating the organization.' }));

    }
  } else if (confirmationOption == 'activateOrganization') {
    try {
      organizationTableResponse = (await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(selectedItemOrganization, 'objectId')),[`status`]: 'ACTIVE' }) ));
      (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'))['status'] = 'ACTIVE';
      ___arguments.context.pageData['refreshOrgTable'] = true;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isOrganizationRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayOrg'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String(getObjectProperty(selectedItemOrganization, 'orgName')) + String(' successfully activated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error activating the organization.' }));

    }
  } else if (confirmationOption == 'deactivateUser') {
    try {
      userTableResponse = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(selectedItemUser, 'objectId')),[`status`]: 'DEACTIVATED' })) ));
      if (getObjectProperty(userTableResponse, 'objectId')) {
        if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
          if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
            sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItemUser, 'email')), 'd-3088a12d29154a149afda7319b4aaa1f', ({ [`case_manager`]: (getObjectProperty(selectedItemUser, 'name')),[`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')) })));
          } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'admin') {
            sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItemUser, 'email')), 'd-1b4ba1b7ba7747d3a1e73426cc7c26f9', ({ [`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItemUser, 'name')) })));
          } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'super') {
            sendEmailResponse = true;
          }
        } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
          if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
            sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItemUser, 'email')), 'd-55de7fc773a0475f8b6bee559d3dbe2c', ({ [`case_manager`]: (getObjectProperty(selectedItemUser, 'name')),[`organization_name`]: (getObjectProperty(currentUser, 'orgName')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')) })));
          }
        }
      }
      (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'))['status'] = 'DEACTIVATED';
      ___arguments.context.pageData['refreshUserTable'] = true;
      ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String(getObjectProperty(selectedItemUser, 'name')) + String(' successfully deactivated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue deactivating user.' }));

    }
  } else if (confirmationOption == 'activateUser') {
    try {
      userTableResponse = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(selectedItemUser, 'objectId')),[`status`]: 'ACTIVE' })) ));
      if (getObjectProperty(userTableResponse, 'objectId')) {
        if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
          if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
            sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItemUser, 'email')), 'd-251836f13fe34b33a164b7c57791abe5', ({ [`case_manager`]: (getObjectProperty(selectedItemUser, 'name')),[`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')) })));
          } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'admin') {
            sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItemUser, 'email')), 'd-20d900830c6740dfbe264ed215b3c5bd', ({ [`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItemUser, 'name')) })));
          }
        } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
          if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
            sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItemUser, 'email')), 'd-2387f288fb314359889799c9146a3f16', ({ [`case_manager`]: (getObjectProperty(selectedItemUser, 'name')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')) })));
          }
        }
      }
      (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'))['status'] = 'ACTIVE';
      ___arguments.context.pageData['refreshUserTable'] = true;
      ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayUser'] = false;
      ___arguments.context.pageData['refreshUserTable'] = true;
      await setToast(({ [`status`]: 'success',[`message`]: String(getObjectProperty(selectedItemUser, 'name')) + String(' successfully activated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue activating user.' }));

    }
  } else if (confirmationOption == 'cancelUser') {
    try {
      if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
        sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItemUser, 'email')), 'd-07b9b52103aa4505994d7a9554d8e8c6', ({ [`case_manager`]: (getObjectProperty(selectedItemUser, 'name')),[`organization_admin`]: 'Cyber Dive Team' })));
      } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'admin') {
        sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItemUser, 'email')), 'd-f38f08cf334d4f3c80356947b37a2a12', ({ [`organization_admin`]: (getObjectProperty(selectedItemUser, 'name')) })));
      }
      await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(selectedItemUser, 'objectId')) });
      ___arguments.context.pageData['isViewDetailsOpen'] = false;
      ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['refreshUserTable'] = true;
      await setToast(({ [`status`]: 'success',[`message`]: String(getObjectProperty(selectedItemUser, 'name')) + String(' successfully cancelled.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue cancelling invite.' }));

    }
  } else if (confirmationOption == 'resendUser') {
    uniqueID = (generateUUID().long());
    await BackendlessUI.Functions.Custom['fn_e1b4ed7b0fa7f3872bddf226a82a4b7e'](___arguments.context.pageData, (getObjectProperty(selectedItemUser, 'email')), uniqueID);
    try {
      if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
        if ((getObjectProperty(selectedItemUser, 'userRole')) == 'admin') {
          sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItemUser, 'email')), 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItemUser, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(selectedItemUser, 'email')),[`key`]: uniqueID }), ___arguments.context.appData)) })));
        } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
          sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItemUser, 'email')), 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')),[`organization_admin`]: 'Cyber Dive Team',[`case_manager`]: (getObjectProperty(selectedItemUser, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(selectedItemUser, 'email')),[`key`]: uniqueID }), ___arguments.context.appData)) })));
        }
      } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
        if ((getObjectProperty(selectedItemUser, 'userRole')) == 'admin') {
          sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItemUser, 'email')), 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItemUser, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(selectedItemUser, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')),[`key`]: uniqueID }), ___arguments.context.appData)) })));
        } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
          sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItemUser, 'email')), 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: (getObjectProperty(selectedItemUser, 'orgName')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`case_manager`]: (getObjectProperty(selectedItemUser, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(selectedItemUser, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')),[`key`]: uniqueID }), ___arguments.context.appData)) })));
        }
      }
      if (sendGridResponse) {
        await Backendless.UserService.update( new Backendless.User(({ [`inviteSent`]: (new Date()),[`objectId`]: (getObjectProperty(selectedItemUser, 'objectId')),[`inviteStatus`]: 'RESENT' })) );
        (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'))['inviteStatus'] = 'RESENT';
        ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
        ___arguments.context.pageData['isConfirmationModalOpen'] = false;
        ___arguments.context.pageData['refreshUserTable'] = true;
        await setToast(({ [`status`]: 'success',[`message`]: 'Invite successfully sent.' }));
      }

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Problem resending the invite.' }));

    }
  } else if (confirmationOption == 'deactivateCurfew') {
    try {
      await Backendless.Data.of('Curfews').save( ({ [`objectId`]: (getObjectProperty(selectedItemCurfew, 'objectId')),[`status`]: 'DEACTIVATED' }) );
      await BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235']((getObjectProperty(currentUser, 'organizationId.orgName')), (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')), ___arguments.context.pageData);
      ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isViewDetailsModalOpen'] = false;
      ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: (getObjectProperty(selectedItemCurfew, 'pillar')) });
      await setToast(({ [`status`]: 'success',[`message`]: String('Curfew successfully deactivated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue deactivating curfew.' }));

    }
  } else if (confirmationOption == 'activateCurfew') {
    try {
      await Backendless.Data.of('Curfews').save( ({ [`objectId`]: (getObjectProperty(selectedItemCurfew, 'objectId')),[`status`]: 'ACTIVE' }) );
      await BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235']((getObjectProperty(currentUser, 'organizationId.orgName')), (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')), ___arguments.context.pageData);
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isViewDetailsModalOpen'] = false;
      ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: (getObjectProperty(selectedItemCurfew, 'pillar')) });
      await setToast(({ [`status`]: 'success',[`message`]: String('Curfew successfully activated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue activating curfew.' }));

    }
  } else if (confirmationOption == 'deleteCurfew') {
    try {
      await Backendless.Data.of('Curfews').remove( (getObjectProperty(selectedItemCurfew, 'objectId')) );
      await BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235']((getObjectProperty(currentUser, 'organizationId.orgName')), (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')), ___arguments.context.pageData);
      ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: (getObjectProperty(selectedItemCurfew, 'pillar')) });
      ___arguments.context.pageData['isViewDetailsModalOpen'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String('Curfew successfully deleted.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue deleting curfew.' }));

    }
  } else if (confirmationOption == 'cancel') {
    ___arguments.context.pageData['isConfirmationModalOpen'] = false;
    ___arguments.context.pageData['isAddRecordModalOpen'] = false;
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      ___arguments.context.pageData['residentIntakeForm'] = (JSON.parse((getObjectProperty((await Backendless.Data.of('Residents').findById((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')), )), 'residentIntakeForm'))));
    }
  } else if (confirmationOption == 'deactivateResident') {
    try {
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'DEACTIVATED';
      await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'DEACTIVATED',[`residentIntakeForm`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')) }) );
      ___arguments.context.pageData['refreshResidentTable'] = true;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String((await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')), 10))) + String(' has been deactivated') }));

    } catch (error) {
      if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
        await setToast(({ [`status`]: 'failure',[`message`]: String('Error deactivating ') + String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) }));
      }

    }
  } else if (confirmationOption == 'activateResident') {
    try {
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'ACTIVE';
      await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'ACTIVE',[`residentIntakeForm`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')) }) );
      ___arguments.context.pageData['refreshResidentTable'] = true;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String((await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')), 10))) + String(' has been activated') }));

    } catch (error) {
      if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
        await setToast(({ [`status`]: 'failure',[`message`]: String('Error activating ') + String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) }));
      }

    }
  } else if (confirmationOption == 'deleteContact') {
    try {
      newContactArray = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')), async (curfew) => {
         if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) != curfew) {
          return true;
        }


       return ;
      }));
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['contactArray'] = newContactArray;
      fcmResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleB2BFireBaseMessaging`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'token': (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.FCMToken')),'contactArray': newContactArray,'variant': 'deviceEnrollment' }));
      if ((getObjectProperty(fcmResponse, 'statusCode')) == '200') {
        await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
        ___arguments.context.pageData['selectedItemContact'] = undefined;
        ___arguments.context.pageData['refreshResidentTable'] = true;
        ___arguments.context.pageData['isConfirmationModalOpen'] = false;
        ___arguments.context.pageData['isAddRecordModalOpen'] = false;
        await setToast(({ [`status`]: 'success',[`message`]: 'Successfully deleted contact' }));
      }

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error deleting contact' }));

    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'confirmationOption')) == 'deactivateFirst') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Device', ({ [`recordSelection`]: 'residentRecords',[`selectedItemDeviceIMEI`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI')),[`selectedItemDetailsSelection`]: 'details',[`isViewDetailsOpen`]: true,[`selectedItemDevice`]: (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((['residentId.objectId = \'',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),'\''].join(''))).setRelationsDepth(2).setPageSize(10)))[0],[`loadLocationMap`]: true,[`loadDeviceDetails`]: true }));
  } else if (confirmationOption == 'deactivateDevice') {
    ___arguments.context.pageData['isConfirmationModalOpen'] = false;
  }
  ___arguments.context.pageData['isLoadingAction'] = false;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'deactivateDevice' || confirmationOption == 'deactivateResident' || confirmationOption == 'deactivateUser' || confirmationOption == 'deactivateCurfew' || confirmationOption == 'deactivateOrganization') {
    return 'Yes, Deactivate';
  } else if (confirmationOption == 'resendUser') {
    return 'Send Invite';
  } else if (confirmationOption == 'cancelUser' || confirmationOption == 'cancel') {
    return 'Yes, Cancel';
  } else if (confirmationOption == 'deleteContact' || confirmationOption == 'deleteCurfew') {
    return 'Yes, Delete';
  } else if (confirmationOption == 'saveResidentForm') {
    return 'Save';
  } else if (confirmationOption == 'deleteActiveCurfew') {
    return 'Okay';
  } else if (confirmationOption == 'deactivateFirst') {
    return 'Go To Device Management';
  } else {
    return 'Okay';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'deleteContact' || confirmationOption == 'deactivateResident' || confirmationOption == 'cancel' || confirmationOption == 'cancelUser' || confirmationOption == 'deleteCurfew' || confirmationOption == 'deactivateUser' || confirmationOption == 'deactivateCurfew' || confirmationOption == 'deactivateOrganization') {
    classes = ['red_button'];
  } else if (confirmationOption == 'deactivateDevice') {
    if (getObjectProperty(___arguments.context.pageData, 'confirmationCheckedState')) {
      classes = ['red_button'];
    } else {
      classes = ['disabled_red_button'];
    }
  } else {
    classes = ['enabled_button'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'confirmationOption')) == 'deactivateDevice') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9d41a5b78c58c0d93fbc6c8bd52de1d', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9d41a5b78c58c0d93fbc6c8bd52de1d', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/ef9f2f4e57120713b6bebadc18471b12/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var confirmationOption, selectedItemUser, selectedItemOrganization, selectedItemCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew'));
  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (selectedItemCurfew) {
    if (confirmationOption == 'deactivateCurfew') {
      return (['Are you sure you want to deactivate the ',(getObjectProperty(selectedItemCurfew, 'name')),' curfew?'].join(''));
    } else if (confirmationOption == 'activateCurfew') {
      return (['Are you sure you want to activate the ',(getObjectProperty(selectedItemCurfew, 'name')),' curfew?'].join(''));
    } else if (confirmationOption == 'deleteActiveCurfew') {
      return (['You can\'t delete an ',(await (async function() {
      	return "<strong>active</strong>"
      })()),' curfew. Please select a ',(await (async function() {
      	return "<strong>deactivated</strong>"
      })()),' curfew.'].join(''));
    } else if (confirmationOption == 'deleteCurfew') {
      return (['Are you sure you want to delete the ',(getObjectProperty(selectedItemCurfew, 'name')),' curfew?'].join(''));
    }
  } else if (selectedItemOrganization) {
    if (confirmationOption == 'deactivateOrganization') {
      return (['Are you sure you want to deactivate ',(getObjectProperty(selectedItemOrganization, 'orgName')),' Organization?'].join(''));
    } else if (confirmationOption == 'activateOrganization') {
      return (['Are you sure you want to activate ',(getObjectProperty(selectedItemOrganization, 'orgName')),' Organization?'].join(''));
    }
  } else if (selectedItemUser) {
    if (confirmationOption == 'deactivateUser') {
      return (['Are you sure you want to deactivate ',(getObjectProperty(selectedItemUser, 'name')),'\'s account?'].join(''));
    } else if (confirmationOption == 'activateUser') {
      return (['Are you sure you want to activate  ',(getObjectProperty(selectedItemUser, 'name')),'\'s account?'].join(''));
    } else if (confirmationOption == 'cancelUser') {
      return (['Are you sure you want to cancel ',(getObjectProperty(selectedItemUser, 'name')),'\'s invite?'].join(''));
    } else if (confirmationOption == 'resendUser') {
      return (['Are you sure you want to resend an invite to ',(getObjectProperty(selectedItemUser, 'name')),'?'].join(''));
    }
  } else if (confirmationOption == 'activateResident') {
    return (['Are you sure you want to activate ',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')),'?'].join(''));
  } else if (confirmationOption == 'deactivateResident') {
    return (['Are you sure you want to deactivate ',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')),'?'].join(''));
  } else if (confirmationOption == 'deleteContact') {
    return (['Are you sure you want to delete ',(getObjectProperty(___arguments.context.pageData, 'selectedItemContact.fullName')),' as a contact?'].join(''));
  } else if (confirmationOption == 'deactivateFirst') {
    return 'Deactivate Device First';
  } else if (confirmationOption == 'deactivateDevice') {
    return 'Please contact support!';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/887cc68e4bd1bf6dafeafcbc7edd4a9a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'confirmationOption')) == 'cancel') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('887cc68e4bd1bf6dafeafcbc7edd4a9a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('887cc68e4bd1bf6dafeafcbc7edd4a9a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/ccab5a862b6c30289e83e1a305d5de96/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'deactivateCurfew' || confirmationOption == 'deactivateOrganization' || confirmationOption == 'deactivateUser' || confirmationOption == 'deactivateResident') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-deactivate.png';
  } else if (confirmationOption == 'activateCurfew' || confirmationOption == 'activateOrganization' || confirmationOption == 'activateUser' || confirmationOption == 'activateResident') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-activate.png';
  } else if (confirmationOption == 'activateCurfew') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-deactivate.png';
  } else if (confirmationOption == 'deactivateCurfew') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-deactivate.png';
  } else if (confirmationOption == 'deleteCurfew') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-deactivate.png';
  } else if (confirmationOption == 'resendUser') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-sendInvite.png';
  } else if (confirmationOption == 'cancel') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-cancel.png';
  } else if (confirmationOption == 'cancelUser') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-cancelInvite.png';
  } else if (confirmationOption == 'deactivateFirst') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-deactivateDevice.png';
  } else if (confirmationOption == 'deactivateDevice') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-deactivateDeviceRed.png';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/dff885b2ec0cd083f413b079ffea0e88/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'deactivateFirst') {
    return (['To proceed with deactivating ',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.name')),', you must first deactivate their device.'].join(''));
  } else if (confirmationOption == 'deactivateDevice') {
    return 'Deactivating your device is currently not available. Please contact our customer service by clicking "Chat With Us" on the side bar or email support@cyberdive.co. ';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/c419a6c3ea9201a745d6a1ede472d46a/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    
  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/de07b02c092bdcd57bcec6714f7885ad/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts') {
    return 'The device will still be assigned to the resident and you may add the contacts in at another time.';
  } else {
    return 'If you cancel, all information you entered till now will be lost.';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/cd26f0fd4c3c193325fd3083c64b9c86/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isSuccessModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/18edac382e932661b1f4cf0d70010a2c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var userRole;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'addOrganization') {
    return (String(getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.orgName')) + String(' Added Successfully'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'editOrganization') {
    return (String(getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.orgName')) + String(' Updated Successfully'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'addUser') {
    if ((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.userRole')) == 'admin') {
      userRole = 'Admin ';
    } else if ((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.userRole')) == 'manager') {
      userRole = 'Case Manager ';
    }
    return ([userRole,(getObjectProperty(___arguments.context.pageData, 'userUpdateObject.name')),' Successfully Added'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'assignedDevice') {
      return 'Device Assigned Successfully';
    } else if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'addResident') {
      return (String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) + String(' Added Successfully'));
    } else if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'addContacts') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 1) {
        return (String(' contact has been added for ') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')));
      } else {
        return (String(' contacts have been added for ') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/0bbf7201d5368d4cf11b85b10c9d782a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var currentPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'Organization') {
    return 'The organization records table has been updated to reflect the new changes.';
  } else if (currentPage == 'User') {
    return 'An invitation has been sent to their inbox.';
  } else if (currentPage == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'assignedDevice') {
      return 'Your device has been successfully assigned. You’re now ready to get started.';
    } else if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'addResident') {
      if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
        return (['You can manage the details from the ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' records!'].join(''));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/b761fa26a7c90fd56a9d4942db3752dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'assignedDevice') {
    try {
      ___arguments.context.pageData['selectedItemResident'] = ((await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),'\''].join(''))).setPageSize(10)))[0]);
      ___arguments.context.pageData['setAdminContacts'] = true;
      ___arguments.context.pageData['setResidentContacts'] = true;
      ___arguments.context.pageData['isAdminContactsLocked'] = true;
      ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
      ___arguments.context.pageData['residentIntakeVariant'] = 'contacts';
      ___arguments.context.pageData['isSuccessModalOpen'] = false;

    } catch (error) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error getting resident info', 'failure');

    }
  } else {
    ___arguments.context.pageData['isSuccessModalOpen'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'assignedDevice') {
    return 'Add Contacts';
  } else {
    return 'Okay';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/51855f267a91ac95cb8f23bead89706e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSuccessModalOpen'] = false;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'assignedDevice') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51855f267a91ac95cb8f23bead89706e', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('51855f267a91ac95cb8f23bead89706e', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/56c244f2eb35c84da19a61ffbea305c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) != 'assignedDevice') {
    ___arguments.context.pageData['isSuccessModalOpen'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/ee7d7589b89179e647a6c433407933c9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'assignedDevice') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ee7d7589b89179e647a6c433407933c9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ee7d7589b89179e647a6c433407933c9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var deviceObjectId, fcmResponse, adminContacts, FCMToken, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isLoadingIntakeForm'] = true;
  ___arguments.context.pageData['isSuccessModalOpen'] = false;
  try {
    adminContacts = (JSON.parse((getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.adminData'))));
    currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
    deviceObjectId = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceObjectId'));
    FCMToken = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.FCMToken'));
    if (adminContacts) {
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['contactArray'] = [];
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['pillarInfo'] = ({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined });
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'ACTIVE';
      await BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235']((getObjectProperty(currentUser, 'organizationId.orgName')), (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')), ___arguments.context.pageData);
      fcmResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleB2BFireBaseMessaging`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'token': FCMToken,'contactArray': adminContacts,'variant': 'deviceEnrollment' }));
      if ((getObjectProperty(fcmResponse, 'statusCode')) == '200') {
        await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'ACTIVE',[`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarHistory`]: (JSON.stringify([({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined })])),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
        await Backendless.Data.of('Devices').save( ({ [`objectId`]: deviceObjectId,[`deviceStatus`]: 'ASSIGNED' }) );
        ___arguments.context.pageData['refreshResidentTable'] = true;
        ___arguments.context.pageData['isLoadingIntakeForm'] = false;
        ___arguments.context.pageData['isAddRecordModalOpen'] = false;
        await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Successfully assigned device.', 'success');
      } else {
        throw (new Error(''))
      }
    }

  } catch (error) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error updating contacts', 'failure');
    } else {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error completing device assignment.', 'failure');
    }
    ___arguments.context.pageData['isLoadingIntakeForm'] = false;

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/6748c0ee2f7f00dc833a768cbb22070c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'successModalVariant')) == 'addContacts') {
    return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length);
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/81538f0be1da4e9cec7ed8d629a8b488/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isUpdateUserModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/53a52b7b3e2365a5db8fb3ccac5892fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isUpdateUserModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/2009d68b8b0eb8c8d29ef3c5c861813a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2009d68b8b0eb8c8d29ef3c5c861813a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2009d68b8b0eb8c8d29ef3c5c861813a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/75bb4aff4c68ac476776beb1110e7876/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('75bb4aff4c68ac476776beb1110e7876', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('75bb4aff4c68ac476776beb1110e7876', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/4d0d4516c7778dfde96275d544f6354d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isUpdateUserModalOpen'] = false;
  ___arguments.context.pageData['isLoadingUserButton'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/6d0e2ddfd8db162c01a3d3888e5325c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var isDone, currentUser, toastError, itemToUpdate, isUpdatingOrganization, updatedOrganizationData, changedAttributes, selectedItemOrganization, confirmPasswordResponse, updateConfirmPassword, isAgreeingToUpdate, recordTableVariant, isUpdatingProfile, uniqueID, updatedOrganizationRelation, updateUserData, updatedOrgName, selectedItemUser, menuOptionSelection, selectedImageUrl, variant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function updateCurrentUserInfo() {
  try {
    ___arguments.context.appData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was a problem loading the user information.' }));

  } finally {
    isDone = true;

  }
}

/**
 * Describe this function...
 */
async function setToast(toastError) {
  ___arguments.context.pageData['isLoadingAction'] = false;
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function createImageUrl(variant) {
  if (variant == 'editUser') {
    if ((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.profilePicture')) != (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.profilePicture')) && (getObjectProperty(___arguments.context.pageData, 'userUpdateObject.profilePicture')) != 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg') {
      if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedItemUserImageFile'))[0]),
            encodePath((['/profile_pictures/',(getObjectProperty(itemToUpdate, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.UserService.update( new Backendless.User(({ [`profilePicture`]: selectedImageUrl,[`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) })) );
        }
      } else {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedItemUserImageFile'))[0]),
            encodePath((['/profile_pictures/',(getObjectProperty(currentUser, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.UserService.update( new Backendless.User(({ [`profilePicture`]: selectedImageUrl,[`objectId`]: (getObjectProperty(currentUser, 'objectId')) })) );
        }
      }
    }
  } else if (variant == 'editOrganization') {
    if ((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.logo')) != (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo'))) {
      if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile'))[0]),
            encodePath((['/organization_logos/',(getObjectProperty(itemToUpdate, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.Data.of('Organizations').save( ({ [`logo`]: selectedImageUrl,[`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) }) );
        }
      } else {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile'))[0]),
            encodePath((['/organization_logos/',(getObjectProperty(currentUser, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.Data.of('Organizations').save( ({ [`logo`]: selectedImageUrl,[`objectId`]: (getObjectProperty(currentUser, 'organizationId.objectId')) }) );
        }
      }
    }
  }
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  updateConfirmPassword = (getObjectProperty(___arguments.context.pageData, 'updateConfirmPassword'));
  isUpdatingProfile = recordTableVariant == 'viewProfile';
  isUpdatingOrganization = recordTableVariant == 'viewOrganization';
  isAgreeingToUpdate = (getObjectProperty(___arguments.context.pageData, 'isAgreeingToUpdate'));
  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  if (recordTableVariant == 'editUser' || isUpdatingProfile) {
    isDone = false;
    if (!isAgreeingToUpdate && isUpdatingProfile) {
      ___arguments.context.pageData['isAgreeingToUpdate'] = true;
      return ;
    } else if (isAgreeingToUpdate && isUpdatingProfile) {
      if (updateConfirmPassword) {
        ___arguments.context.pageData['isLoadingAction'] = true;
        confirmPasswordResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/PasswordVerification/VerifyPassword`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'password': updateConfirmPassword,'token': (Backendless.getCurrentUserToken()) }));
        if (!(getObjectProperty(confirmPasswordResponse, 'valid'))) {
          ___arguments.context.pageData['isLoadingAction'] = false;
          await setToast(({ [`status`]: 'failure',[`message`]: 'The password you entered is incorrect.' }));
          return ;
        }
      }
    }
    ___arguments.context.pageData['isLoadingAction'] = true;
    itemToUpdate = (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'));
    changedAttributes = ({ [`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) });
    if (selectedItemUser || isUpdatingProfile) {
      if ((getObjectProperty(itemToUpdate, 'orgName')) != (getObjectProperty(selectedItemUser, 'orgName'))) {
        updatedOrgName = (getObjectProperty(itemToUpdate, 'orgName'));
      }
      if ((getObjectProperty(itemToUpdate, 'name')) != (getObjectProperty(selectedItemUser, 'name'))) {
        changedAttributes['name'] = (getObjectProperty(itemToUpdate, 'name'));
      }
      if ((getObjectProperty(itemToUpdate, 'phoneNumber')) != (getObjectProperty(selectedItemUser, 'phoneNumber'))) {
        changedAttributes['phoneNumber'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(itemToUpdate, 'phoneNumber')), 'remove'))));
      }
      if ((getObjectProperty(itemToUpdate, 'userRole')) != (getObjectProperty(selectedItemUser, 'userRole'))) {
        changedAttributes['userRole'] = (getObjectProperty(itemToUpdate, 'userRole'));
      }
      if ((getObjectProperty(itemToUpdate, 'email')) != (getObjectProperty(selectedItemUser, 'email'))) {
        changedAttributes['email'] = (getObjectProperty(itemToUpdate, 'email'));
      }
      if (changedAttributes) {
        try {
          updateUserData = (await Backendless.UserService.update( new Backendless.User(changedAttributes) ));
          if (updatedOrgName) {
            updatedOrganizationRelation = (await Backendless.Data.of('Users').setRelation(itemToUpdate, 'organizationId', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['orgName = \'',updatedOrgName,'\''].join(''))).setPageSize(10)))));
          }
          if (updateUserData || updatedOrganizationRelation) {
            (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['phoneNumber'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(itemToUpdate, 'phoneNumber')), 'remove'))));
            await createImageUrl('editUser');
            if ((getObjectProperty(changedAttributes, 'email')) && (getObjectProperty(itemToUpdate, 'inviteStatus')) == 'PENDING') {
              uniqueID = (generateUUID().long());
              await Backendless.Cache.put((getObjectProperty(changedAttributes, 'email')), ({ [`key`]: uniqueID }), 7200);
              await Backendless.Cache.expireIn((getObjectProperty(changedAttributes, 'email')), 7200);
              if ((getObjectProperty(itemToUpdate, 'userRole')) == 'admin') {
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(changedAttributes, 'email')), 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(changedAttributes, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')) }), null)),[`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')),[`organization_admin`]: 'Cyber Dive Team' }));
              } else if ((getObjectProperty(itemToUpdate, 'userRole')) == 'manager') {
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(changedAttributes, 'email')), 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(changedAttributes, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')) }), null)),[`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')),[`organization_admin`]: 'Cyber Dive Team',[`case_manager`]: (getObjectProperty(itemToUpdate, 'name')) }));
              }
            }
            if (isUpdatingProfile) {
              if ((getObjectProperty(itemToUpdate, 'userRole')) == 'admin') {
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(itemToUpdate, 'email')), 'd-e3d0f9eb7bae4cc5a877d00e80bc9e84', ({ [`organization_admin`]: (getObjectProperty(itemToUpdate, 'name')) }));
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', 'engineers@cyberdive.co', 'd-eee48d5acc6c4d8a88be92e2d1c66fa0', ({ [`organization_name`]: (getObjectProperty(itemToUpdate, 'organizationId.orgName')),[`organization_admin`]: (getObjectProperty(itemToUpdate, 'name')),[`super_admin`]: 'Cyber Dive' }));
              }
            }
            ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
            ___arguments.context.pageData['isUpdateUserModalOpen'] = false;
            ___arguments.context.pageData['isAddRecordModalOpen'] = false;
            ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
            ___arguments.context.pageData['selectedItemUser'] = itemToUpdate;
            ___arguments.context.pageData['isUserMenuOpen'] = false;
            ___arguments.context.pageData['isLoadingUserButton'] = false;
            ___arguments.context.pageData['refreshUserTable'] = true;
            await updateCurrentUserInfo();
            if (isUpdatingProfile) {
              await setToast(({ [`status`]: 'success',[`message`]: 'You\'ve successfully updated your information' }));
            } else {
              await setToast(({ [`status`]: 'success',[`message`]: [(getObjectProperty(itemToUpdate, 'name')),' from ',(getObjectProperty(itemToUpdate, 'orgName')),'\'s have been successfully updated.'].join('') }));
            }
          }

        } catch (error) {
          ___arguments.context.pageData['isUpdateUserModalOpen'] = false;
          ___arguments.context.pageData['isLoadingAction'] = false;
          ___arguments.context.pageData['isLoadingUserButton'] = false;
          if (isUpdatingProfile) {
            await setToast(({ [`status`]: 'failure',[`message`]: 'There was an error updating you information.' }));
          } else {
            await setToast(({ [`status`]: 'failure',[`message`]: 'There was an error updating this user.' }));
          }

        } finally {
          ___arguments.context.pageData['isLoadingAction'] = false;

        }
      }
    }
  }
  if (recordTableVariant == 'editOrganization' || isUpdatingOrganization) {
    if (!isAgreeingToUpdate && isUpdatingOrganization) {
      ___arguments.context.pageData['isAgreeingToUpdate'] = true;
      return ;
    } else if (isAgreeingToUpdate && isUpdatingOrganization) {
      if (updateConfirmPassword) {
        ___arguments.context.pageData['isLoadingAction'] = true;
        confirmPasswordResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/PasswordVerification/VerifyPassword`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'password': updateConfirmPassword,'token': (Backendless.getCurrentUserToken()) }));
        if (!(getObjectProperty(confirmPasswordResponse, 'valid'))) {
          ___arguments.context.pageData['isLoadingAction'] = false;
          await setToast(({ [`status`]: 'failure',[`message`]: 'The password you entered is incorrect.' }));
          return ;
        }
      }
    }
    ___arguments.context.pageData['isLoadingAction'] = true;
    if (selectedItemOrganization || isUpdatingOrganization) {
      if (isUpdatingOrganization && currentUser) {
        itemToUpdate = (getObjectProperty(currentUser, 'organizationId'));
        changedAttributes = ({ [`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) });
      } else {
        itemToUpdate = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
        changedAttributes = ({ [`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) });
      }
      if ((getObjectProperty(selectedItemOrganization, 'billingPhone')) != String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(itemToUpdate, 'billingPhone')), 'remove')))) {
        changedAttributes['billingPhone'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(itemToUpdate, 'billingPhone')), 'remove'))));
      }
      if ((getObjectProperty(selectedItemOrganization, 'orgName')) != (getObjectProperty(itemToUpdate, 'orgName'))) {
        changedAttributes['orgName'] = (getObjectProperty(itemToUpdate, 'orgName'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'taxId')) != (getObjectProperty(itemToUpdate, 'taxId'))) {
        changedAttributes['taxId'] = (getObjectProperty(itemToUpdate, 'taxId'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'billingEmail')) != (getObjectProperty(itemToUpdate, 'billingEmail'))) {
        changedAttributes['billingEmail'] = (getObjectProperty(itemToUpdate, 'billingEmail'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'billingName')) != (getObjectProperty(itemToUpdate, 'billingName'))) {
        changedAttributes['billingName'] = (getObjectProperty(itemToUpdate, 'billingName'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'websiteLink')) != (getObjectProperty(itemToUpdate, 'websiteLink'))) {
        changedAttributes['websiteLink'] = (getObjectProperty(itemToUpdate, 'websiteLink'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'address')) != (getObjectProperty(itemToUpdate, 'address'))) {
        changedAttributes['address'] = (getObjectProperty(itemToUpdate, 'address'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'city')) != (getObjectProperty(itemToUpdate, 'city'))) {
        changedAttributes['city'] = (getObjectProperty(itemToUpdate, 'city'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'state')) != (getObjectProperty(itemToUpdate, 'state'))) {
        changedAttributes['state'] = (getObjectProperty(itemToUpdate, 'state'));
      }
      if ((getObjectProperty(selectedItemOrganization, 'zipCode')) != (getObjectProperty(itemToUpdate, 'zipCode'))) {
        changedAttributes['zipCode'] = (getObjectProperty(itemToUpdate, 'zipCode'));
      }
      if (changedAttributes) {
        try {
          await createImageUrl('editOrganization');
          updatedOrganizationData = (await Backendless.Data.of('Organizations').save( changedAttributes ));
          if (updatedOrganizationData) {
            if (isUpdatingOrganization) {
              await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(currentUser, 'email')), 'd-3007e3b999db4973a22e5018a5ecd676', ({ [`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')) }));
              await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', 'engineers@cyberdive.co', 'd-6f15d8bbf6b345d1a9988d64ca18f3ff', ({ [`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`super_admin`]: 'Cyber Dive' }));
            }
            if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
              await updateCurrentUserInfo();
              itemToUpdate = (getObjectProperty(currentUser, 'organizationId'));
            } else {
              ___arguments.context.pageData['refreshOrgTable'] = true;
            }
            (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (String('+1') + String(getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')));
            ___arguments.context.pageData['selectedItemOrganization'] = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
            ___arguments.context.pageData['isUpdateUserModalOpen'] = false;
            ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
            ___arguments.context.pageData['isSelectedItemModalOpen'] = false;
            ___arguments.context.pageData['isAddRecordModalOpen'] = false;
            ___arguments.context.pageData['loadLocationMap'] = true;
            ___arguments.context.pageData['refreshOrgTable'] = false;
            await setToast(({ [`status`]: 'success',[`message`]: ['You\'ve successfully updated the ',(getObjectProperty(itemToUpdate, 'orgName')),' organization!'].join('') }));
          }

        } catch (error) {
          await setToast(({ [`status`]: 'failure',[`message`]: 'There was an error updating this organization.' }));

        }
      }
      ___arguments.context.pageData['isLoadingAction'] = false;
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'updateConfirmPassword')) && (getObjectProperty(___arguments.context.pageData, 'isUpdateConfirmPassword'))) {
    ___arguments.context.pageData['isUpdateUserButtonDisabled'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d0e2ddfd8db162c01a3d3888e5325c4'))['background-color'] = '#fbdddd';
  } else {
    ___arguments.context.pageData['isUpdateUserButtonDisabled'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d0e2ddfd8db162c01a3d3888e5325c4'))['background-color'] = '#E6654F';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/8363955a1d5aff9ed2b7c3bb91b418f9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var selectedItemUser, recordTableVariant, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  if (currentUser) {
    if (recordTableVariant == 'viewProfile') {
      return 'Are you sure you want to update this information?';
    } else if (recordTableVariant == 'viewOrganization') {
      return 'Are you sure you want to update your organization\'s information?';
    } else if (recordTableVariant == 'editOrganization') {
      if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization')) {
        return (['Are you sure you want to update ',(getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')),'\'s information?'].join(''));
      }
    } else if (recordTableVariant == 'editUser') {
      if (selectedItemUser) {
        return (['Are you sure you want to update ',(getObjectProperty(selectedItemUser, 'name')),'\'s information?'].join(''));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isAgreeingToUpdate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isAgreeingToUpdate')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8363955a1d5aff9ed2b7c3bb91b418f9', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8363955a1d5aff9ed2b7c3bb91b418f9', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/e05400c2ed9b5551c3ada884bd7dbc6a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isAgreeingToUpdate')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e05400c2ed9b5551c3ada884bd7dbc6a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e05400c2ed9b5551c3ada884bd7dbc6a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cbd9bbf2cbaa79385815ce9a54c13ecc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isAddRecordModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b039316bd64b7269ddc23064d8acba97/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var addOrganizationStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addOrganizationStep = (getObjectProperty(___arguments.context.pageData, 'addOrganizationStep'));
  if (addOrganizationStep == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b039316bd64b7269ddc23064d8acba97', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b039316bd64b7269ddc23064d8acba97', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f3814b44d39eac6208d15583a3b8a94b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'orgNameError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['orgName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));
  ___arguments.context.pageData['orgNameError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyOrgName'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.orgName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['orgName'] = false;
      ___arguments.context.pageData['emptyOrgName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'orgName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['orgName'] = false;
      ___arguments.context.pageData['emptyOrgName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['orgName'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6bfa60f74236e69dbdd147254f796cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'orgNameError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6bfa60f74236e69dbdd147254f796cdd', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6bfa60f74236e69dbdd147254f796cdd', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a9937d75d08e11b1f4974eb4b87e045b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['zipCode'] = (await BackendlessUI.Functions.Custom['fn_2dc3266507a474ba2f5a291d2361a812'](___arguments.value));
  ___arguments.context.pageData['zipCodeError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyZipCode'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.zipCode'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['zipCode'] = false;
      ___arguments.context.pageData['emptyZipCode'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'zipCode'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['zipCode'] = false;
      ___arguments.context.pageData['emptyZipCode'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['zipCode'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'zipCodeError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3cfea8ba4726fe56d1870fb4d7e4d6d9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'websiteError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['websiteLink'] = ___arguments.value;
  ___arguments.context.pageData['websiteError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyWebsite'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.websiteLink'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['website'] = false;
      ___arguments.context.pageData['emptyWebsite'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'websiteLink'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['website'] = false;
      ___arguments.context.pageData['emptyName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['website'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/386cad05e84b86f51db1a09a6612c9c2/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'websiteError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('386cad05e84b86f51db1a09a6612c9c2', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('386cad05e84b86f51db1a09a6612c9c2', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['websiteError'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/40215aaee6db51337d51fab3f245bbdf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var hasNoErrors, organizationUpdateObject, duplicateOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled'))) {
    organizationUpdateObject = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
    duplicateOrganization = (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['orgName = \'',(getObjectProperty(organizationUpdateObject, 'orgName')),'\''].join(''))).setPageSize(10)));
    hasNoErrors = true;
    if ((getObjectProperty(organizationUpdateObject, 'orgName')) != (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')) && (getObjectProperty(organizationUpdateObject, 'orgName')) != (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')) && duplicateOrganization.length > 0) {
      ___arguments.context.pageData['orgNameError'] = true;
      hasNoErrors = false;
    }
    if ((getObjectProperty(organizationUpdateObject, 'websiteLink')) && !(await BackendlessUI.Functions.Custom['fn_4d0b6f63e09985a12656f99b68cbc84d']((getObjectProperty(organizationUpdateObject, 'websiteLink'))))) {
      ___arguments.context.pageData['websiteError'] = true;
      hasNoErrors = false;
    }
    if ((getObjectProperty(organizationUpdateObject, 'zipCode')) && !(await BackendlessUI.Functions.Custom['fn_c16fa4da6fdc2a0ab06c37eb5691c2e2']((getObjectProperty(organizationUpdateObject, 'zipCode'))))) {
      ___arguments.context.pageData['zipCodeError'] = true;
      hasNoErrors = false;
    }
    if (hasNoErrors) {
      ___arguments.context.pageData['addOrganizationStep'] = 2;
    }
  } else {
    ___arguments.context.pageData['addOrganizationStep'] = 2;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, organizationUpdateObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'addOrganization') {
    organizationUpdateObject = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
    isDisabled = false;
    if (!(getObjectProperty(organizationUpdateObject, 'orgName')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'orgName'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'address')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'address'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'city')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'city'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'state')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'state'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'zipCode')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'zipCode'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'websiteLink')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'websiteLink'))))) {
      isDisabled = true;
    }
    ___arguments.context.pageData['isAddOrganizationNextStepDisabled'] = isDisabled;
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'editOrganization') {
    if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
      isDisabled = true;
    } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
      isDisabled = true;
    } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
      isDisabled = true;
    } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
      isDisabled = true;
    } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
      isDisabled = true;
    } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
      isDisabled = true;
    }
    ___arguments.context.pageData['isAddOrganizationNextStepDisabled'] = isDisabled;
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isAddOrganizationNextStepDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c6c73f197d0d891cea24216f00449507/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  ___arguments.context.pageData['confirmationOption'] = 'cancel';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b9f4899578450d753c6357f95cb7bf3a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var addOrganizationStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addOrganizationStep = (getObjectProperty(___arguments.context.pageData, 'addOrganizationStep'));
  if (addOrganizationStep == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b9f4899578450d753c6357f95cb7bf3a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b9f4899578450d753c6357f95cb7bf3a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7c7e342e261f795150afa84348059c14/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'taxIdError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  ___arguments.context.pageData['taxIdError'] = false;
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['taxId'] = (await BackendlessUI.Functions.Custom['fn_3c37569726d2e37c1909af054741f424'](___arguments.value, 'create', null));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyTaxId'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.taxId'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['taxId'] = false;
      ___arguments.context.pageData['emptyTaxId'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'taxId'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['taxId'] = false;
      ___arguments.context.pageData['emptyTaxId'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['taxId'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f60ed163967646c86361e88c6a46fcee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'billingPhoneError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));
  ___arguments.context.pageData['billingPhoneError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['billingPhone'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && (getObjectProperty(___arguments.context.pageData, 'selectedItemOrgBillingPhone')) != subsequenceFromStartLast((getObjectProperty(currentUser, 'organizationId.billingPhone')), 2)) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingPhone'] = false;
      ___arguments.context.pageData['emptyBillingPhone'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')), 'remove')) != subsequenceFromStartLast((getObjectProperty(selectedItemOrganization, 'billingPhone')), 2)) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingPhone'] = false;
      ___arguments.context.pageData['emptyBillingPhone'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingPhone'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5c1a7322c4bb9f5c8fcbc9f54a544bff/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'billingEmailError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingEmail'] = ___arguments.value;
  ___arguments.context.pageData['billingEmailError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyBillingEmail'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.billingEmail'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingEmail'] = false;
      ___arguments.context.pageData['emptyBillingEmail'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'billingEmail'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingEmail'] = false;
      ___arguments.context.pageData['emptyBillingEmail'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingEmail'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a3f47bb6832148cf10696b18a3689901/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'billingEmailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3f47bb6832148cf10696b18a3689901', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3f47bb6832148cf10696b18a3689901', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f8a3449104eaa2ceb8518572e9dee2bb/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'imageName')) {
    return (getObjectProperty(___arguments.context.pageData, 'imageName'));
  } else {
    return 'Upload Company Logo';
  }

  },
  /* handler:onValueAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f8a3449104eaa2ceb8518572e9dee2bb', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f8a3449104eaa2ceb8518572e9dee2bb', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e685862779b9d3915c6fdad3a4e8ceae/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemOrganizationImageFile'] = ___arguments.selectedFiles;
  ___arguments.context.pageData['imageName'] = (getObjectProperty((___arguments.selectedFiles[0]), 'name'));
  (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['selectedImage'] = false;

  },
  /* handler:onSelectFiles */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile')) || (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e685862779b9d3915c6fdad3a4e8ceae', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e685862779b9d3915c6fdad3a4e8ceae', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ecfeff0427e721d52f67aebf6712b621/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser, menuOptionSelection, organizationUpdateObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  organizationUpdateObject = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile')) {
    (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['logo'] = (await readBrowserFileAs(((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile'))[0]), 'base64'));
  } else {
    if (menuOptionSelection == 'viewOrganization') {
      if (currentUser) {
        if (getObjectProperty(currentUser, 'organizationId.logo')) {
          ___arguments.context.pageData['selectedItemlogo'] = (getObjectProperty(currentUser, 'organizationId.logo'));
        } else {
          ___arguments.context.pageData['selectedItemlogo'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
        }
      }
    } else {
      if ((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.logo')) && !(getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.logo'))) {
        (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['logo'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
      }
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5314c44a5d4ee9c39d630b378f9865cc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var hasNoGroupError, organizationApiResponse, streetAddress, state, city, zipCode, taxId, websiteLink, orgName, billingPhone, billingName, billingEmail, hasNoErrors, duplicateTaxId, mdmToken, organizationUpdateObject, uniqueID, logoPicture, addLogoResponse, mdmGroups, groups, group, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function createLogoUrl() {
  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile')) {
    if (organizationApiResponse) {
      try {
        logoPicture = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile'))[0]),
            encodePath((['/organization_logos/',(getObjectProperty(organizationApiResponse, 'objectId')),'/'].join(''))),
            false
          ).then(result => result.fileURL));
        if (logoPicture) {
          addLogoResponse = (await Backendless.Data.of('Organizations').save( ({ [`logo`]: logoPicture,[`objectId`]: (getObjectProperty(organizationApiResponse, 'objectId')) }) ));
        }

      } catch (error) {
        await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to add organization.', 'failure');

      }
    }
  }
}

/**
 * Describe this function...
 */
async function createGroup() {
  mdmGroups = (JSON.parse((getObjectProperty(((await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'mdmGroups\'').setPageSize(10)))[0]), 'value'))));
  try {
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/HeadwindMDM/createGroup`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'name': orgName,'token': mdmToken }));
    if ((getObjectProperty(response, 'status')) == 'OK') {
      groups = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/Groups`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify(mdmToken) }).send()), 'data'));
      if ((getObjectProperty((groups.slice(-1)[0]), 'name')) == orgName) {
        mdmGroups[orgName] = ({ [`id`]: (getObjectProperty((groups.slice(-1)[0]), 'id')) });
        await Backendless.Data.of('Configurations').save( ({ [`objectId`]: 'DF05F20E-A307-401F-8E36-F17D7C744A43',[`value`]: (JSON.stringify(mdmGroups)) }) );
      } else {
        for (var group_index in groups) {
          group = groups[group_index];
          if ((getObjectProperty(group, 'name')) == orgName) {
            mdmGroups[orgName] = ({ [`id`]: (getObjectProperty((groups.slice(-1)[0]), 'id')) });
            await Backendless.Data.of('Configurations').save( ({ [`objectId`]: 'DF05F20E-A307-401F-8E36-F17D7C744A43',[`value`]: (JSON.stringify(mdmGroups)) }) );
            break;
          }
        }
      }
    } else {
      throw (new Error(''))
    }

  } catch (error) {
    await refreshToken();

  }
}

/**
 * Describe this function...
 */
async function refreshToken() {
  try {
    await BackendlessUI.Functions.Custom['fn_f15eaeb413dd69197945a3bae68dcbde']();
    mdmToken = (getObjectProperty(((await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'mdmToken\'').setPageSize(10)))[0]), 'value'));
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/HeadwindMDM/createGroup`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'name': orgName,'token': mdmToken }));
    if ((getObjectProperty(response, 'status')) == 'OK') {
      groups = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/Groups`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify(mdmToken) }).send()), 'data'));
      if ((getObjectProperty((groups.slice(-1)[0]), 'name')) == orgName) {
        mdmGroups[orgName] = ({ [`id`]: (getObjectProperty((groups.slice(-1)[0]), 'id')) });
        await Backendless.Data.of('Configurations').save( ({ [`objectId`]: 'DF05F20E-A307-401F-8E36-F17D7C744A43',[`value`]: (JSON.stringify(mdmGroups)) }) );
      } else {
        for (var group_index2 in groups) {
          group = groups[group_index2];
          if ((getObjectProperty(group, 'name')) == orgName) {
            mdmGroups[orgName] = ({ [`id`]: (getObjectProperty(group, 'id')) });
            await Backendless.Data.of('Configurations').save( ({ [`objectId`]: 'DF05F20E-A307-401F-8E36-F17D7C744A43',[`value`]: (JSON.stringify(mdmGroups)) }) );
            break;
          }
        }
      }
    } else {
      throw (new Error(''))
    }

  } catch (error) {
    hasNoGroupError = false;
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to create organization group.', 'failure');

  }
}


  ___arguments.context.pageData['isLoadingOrganizationButton'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled')) {
    ___arguments.context.pageData['isAddRecordModalOpen'] = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'editOrganization') {
    ___arguments.context.pageData['isUpdateUserModalOpen'] = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'addOrganization') {
    organizationUpdateObject = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
    uniqueID = (generateUUID().long());
    orgName = (getObjectProperty(organizationUpdateObject, 'orgName'));
    streetAddress = (getObjectProperty(organizationUpdateObject, 'address'));
    city = (getObjectProperty(organizationUpdateObject, 'city'));
    state = (getObjectProperty(organizationUpdateObject, 'state'));
    zipCode = (getObjectProperty(organizationUpdateObject, 'zipCode'));
    websiteLink = (getObjectProperty(organizationUpdateObject, 'websiteLink'));
    billingEmail = (getObjectProperty(organizationUpdateObject, 'billingEmail'));
    billingPhone = String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(organizationUpdateObject, 'billingPhone')), 'remove')));
    billingName = (getObjectProperty(organizationUpdateObject, 'billingName'));
    taxId = (getObjectProperty(organizationUpdateObject, 'taxId'));
    hasNoErrors = true;
    mdmToken = (getObjectProperty(((await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'mdmToken\'').setPageSize(10)))[0]), 'value'));
    duplicateTaxId = (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['taxId = \'',taxId,'\''].join(''))).setPageSize(10)));
    if (!(await BackendlessUI.Functions.Custom['fn_a3492c0a3c3e3c5e02e3736d07768491'](taxId))) {
      ___arguments.context.pageData['isLoadingOrganizationButton'] = false;
      ___arguments.context.pageData['taxIdError'] = 'invalid';
      hasNoErrors = false;
    }
    if (duplicateTaxId.length > 0) {
      ___arguments.context.pageData['isLoadingOrganizationButton'] = false;
      ___arguments.context.pageData['taxIdError'] = 'duplicate';
      hasNoErrors = false;
    }
    if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'](billingPhone))) {
      ___arguments.context.pageData['isLoadingOrganizationButton'] = false;
      ___arguments.context.pageData['billingPhoneError'] = true;
      hasNoErrors = false;
    }
    if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'](billingEmail))) {
      ___arguments.context.pageData['isLoadingOrganizationButton'] = false;
      ___arguments.context.pageData['billingEmailError'] = true;
      hasNoErrors = false;
    }
    if (hasNoErrors) {
      try {
        organizationApiResponse = (await Backendless.Data.of('Organizations').save( ({ [`billingEmail`]: billingEmail,[`billingName`]: billingName,[`billingPhone`]: billingPhone,[`orgName`]: orgName,[`websiteLink`]: websiteLink,[`taxId`]: taxId,[`zipCode`]: zipCode,[`city`]: city,[`state`]: state,[`address`]: streetAddress,[`adminData`]: [] }) ));
        await createLogoUrl();
        if (getObjectProperty(organizationApiResponse, 'objectId')) {
          hasNoGroupError = true;
          await createGroup();
          if (hasNoGroupError) {
            ___arguments.context.pageData['noResultsScreen'] = false;
            ___arguments.context.pageData['refreshOrgTable'] = true;
            ___arguments.context.pageData['isAddRecordModalOpen'] = false;
            ___arguments.context.pageData['isSuccessModalOpen'] = true;
            ___arguments.context.pageData['isLoadingOrganizationButton'] = false;
          }
        }

      } catch (error) {
        await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to add organization.', 'failure');

      } finally {
        ___arguments.context.pageData['isLoadingOrganizationButton'] = false;

      }
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, isButtonLoading, organizationUpdateObject, selectedItem, isDisabledArray, isEditOrgButtonDisabled;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isEditOrgButtonDisabled = (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'));
  isDisabled = true;
  if (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled')) {
    ___arguments.context.pageData['isAddOrganizationButtonDisabled'] = false;
  } else if (isEditOrgButtonDisabled && (getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'editOrganization') {
    isDisabledArray = [(getObjectProperty(isEditOrgButtonDisabled, 'orgName')), (getObjectProperty(isEditOrgButtonDisabled, 'website')), (getObjectProperty(isEditOrgButtonDisabled, 'address')), (getObjectProperty(isEditOrgButtonDisabled, 'city')), (getObjectProperty(isEditOrgButtonDisabled, 'state')), (getObjectProperty(isEditOrgButtonDisabled, 'zipCode')), (getObjectProperty(isEditOrgButtonDisabled, 'taxId')), (getObjectProperty(isEditOrgButtonDisabled, 'billingPhone')), (getObjectProperty(isEditOrgButtonDisabled, 'billingName')), (getObjectProperty(isEditOrgButtonDisabled, 'billingEmail')), (getObjectProperty(isEditOrgButtonDisabled, 'selectedImage'))];
    for (var selectedItem_index in isDisabledArray) {
      selectedItem = isDisabledArray[selectedItem_index];
      if (!selectedItem) {
        isDisabled = false;
      }
    }
    if (getObjectProperty(___arguments.context.pageData, 'disableButton')) {
      isDisabled = true;
    } else if (getObjectProperty(___arguments.context.pageData, 'isLoadingOrganizationButton')) {
      isDisabled = true;
    }
    ___arguments.context.pageData['isAddOrganizationButtonDisabled'] = isDisabled;
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'addOrganization') {
    organizationUpdateObject = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
    isDisabled = false;
    if (!(getObjectProperty(organizationUpdateObject, 'billingEmail')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'billingEmail'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'billingPhone')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'billingPhone'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'taxId')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'taxId'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(organizationUpdateObject, 'billingName')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(organizationUpdateObject, 'billingName'))))) {
      isDisabled = true;
    } else if (getObjectProperty(___arguments.context.pageData, 'isLoadingOrganizationButton')) {
      isDisabled = true;
    } else if (isButtonLoading) {
      isDisabled = true;
    }
    ___arguments.context.pageData['isAddOrganizationButtonDisabled'] = isDisabled;
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isAddOrganizationButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled')) {
    return 'Done';
  } else if (getObjectProperty(___arguments.context.pageData, 'isLoadingOrganizationButton')) {
    return 'Please wait...';
  } else if (recordTableVariant == 'addOrganization') {
    return 'Add Organization';
  } else if (recordTableVariant == 'editOrganization') {
    return 'Update';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1e471ff7a513d4736cde40616e2229fd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['addOrganizationStep'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e92535079f2f12ea358c1b14d15a31f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'emailError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, currentUser, recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['email'] = ___arguments.value;
  ___arguments.context.pageData['emailError'] = false;
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyEmail'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (recordTableVariant == 'viewProfile' && ___arguments.value != (getObjectProperty(currentUser, 'email')) || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser')) && ___arguments.value != (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.email'))) {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['email'] = false;
      ___arguments.context.pageData['emptyEmail'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyName')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['email'] = true;
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/311811ac0f9f39d7514190d7944eb269/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'emailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('311811ac0f9f39d7514190d7944eb269', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('311811ac0f9f39d7514190d7944eb269', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var emailError;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  emailError = (getObjectProperty(___arguments.context.pageData, 'emailError'));
  if (emailError == 'duplicate') {
    return 'This email is already being used by another account';
  } else if (emailError == 'invalid') {
    return 'Invalid email Address';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/aac430fec1759947335d100829b572d1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, recordTableVariant, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  ___arguments.context.pageData['phoneError'] = false;
  (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['disableButton'] = true;
      ___arguments.context.pageData['emptyPhone'] = true;
    } else if (recordTableVariant == 'viewProfile' && (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'remove')) != subsequenceFromStartLast((getObjectProperty(currentUser, 'phoneNumber')), 2) || recordTableVariant == 'editUser' && ___arguments.value != (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.phoneNumber')), 2)), 'create'))) {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['phone'] = false;
      ___arguments.context.pageData['emptyPhone'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['phone'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'phoneError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e616ee22d9465347030b21141602db71/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e616ee22d9465347030b21141602db71', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e616ee22d9465347030b21141602db71', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var organization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  var organization_list = (getObjectProperty(___arguments.context.pageData, 'organizationOptions'));
  for (var organization_index in organization_list) {
    organization = organization_list[organization_index];
    if ((getObjectProperty(organization, 'value')) == ___arguments.value) {
      (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['orgName'] = (getObjectProperty(organization, 'label'));
    }
  }
  if (___arguments.value != (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.orgName'))) {
    (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['organization'] = false;
  } else {
    (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['organization'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5afbcc44e0d579d5ca87a2311aa55d5d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['userRoleSelection'] = 'admin';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var userRoleSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userRoleSelection = (getObjectProperty(___arguments.context.pageData, 'userRoleSelection'));
  if (((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5afbcc44e0d579d5ca87a2311aa55d5d')) && ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))) {
    if (userRoleSelection == 'admin') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5afbcc44e0d579d5ca87a2311aa55d5d'))['background-color'] = '#eaf4fb';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['font-weight'] = 'bold';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['color'] = 'black';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5afbcc44e0d579d5ca87a2311aa55d5d'))['background-color'] = '#FFFFFF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['font-weight'] = 'normal';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['color'] = '#636262';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/feac2eea00a41d097ee16fa7bb06324b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['userRoleSelection'] = 'manager';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var userRoleSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userRoleSelection = (getObjectProperty(___arguments.context.pageData, 'userRoleSelection'));
  if (((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('feac2eea00a41d097ee16fa7bb06324b')) && ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))) {
    if (userRoleSelection == 'manager') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('feac2eea00a41d097ee16fa7bb06324b'))['background-color'] = '#eaf4fb';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['font-weight'] = 'bold';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['color'] = 'black';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('feac2eea00a41d097ee16fa7bb06324b'))['background-color'] = '#FFFFFF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['font-weight'] = 'normal';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['color'] = '#636262';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/184ddb0e156d515f165f0036c2ad5a7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var organizationName, name2, organizationId, registeredUser, userRole, phoneNumber, randomPassword, email, uniqueID, sendGridResponse, userObjectId, hasNoErrors, selectedItemUser, alreadyExistingUser, organization, userUpdateObject, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function registerUser() {
  try {
    registeredUser = (await Backendless.UserService.register( new Backendless.User({ 'email': email,'password': randomPassword }) ));
    if (registeredUser) {
      await Backendless.UserService.update( new Backendless.User(({ [`name`]: name2,[`phoneNumber`]: phoneNumber,[`inviteStatus`]: 'PENDING',[`inviteSent`]: (new Date()),[`userRole`]: userRole,[`objectId`]: (getObjectProperty(registeredUser, 'objectId')) })) );
      await Backendless.Data.of('Users').setRelation(registeredUser, 'organizationId', [organizationId]);
    }

  } catch (error) {
    ___arguments.context.pageData['isLoadingUserButton'] = false;
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (['Invite sent to ',name2,' for ',organizationName,'failed.'].join('')), 'failure');

  }
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function sendAdminEmail() {
  try {
    if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
      organizationName = (getObjectProperty(currentUser, 'organizationId.orgName'));
    } else {
      organizationName = (getObjectProperty(userUpdateObject, 'orgName'));
    }
    if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
      if (userRole == 'admin') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', email, 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: organizationName,[`organization_admin`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email,[`key`]: uniqueID }), ___arguments.context.appData)) })));
      } else if (userRole == 'manager') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', email, 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: organizationName,[`organization_admin`]: 'Cyber Dive Team',[`case_manager`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email,[`key`]: uniqueID }), ___arguments.context.appData)) })));
      }
    } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
      if (userRole == 'admin') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), email, 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: organizationName,[`organization_admin`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email,[`admin_email`]: (getObjectProperty(currentUser, 'email')),[`key`]: uniqueID }), ___arguments.context.appData)) })));
      } else if (userRole == 'manager') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), email, 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: organizationName,[`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`case_manager`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email,[`admin_email`]: (getObjectProperty(currentUser, 'email')),[`key`]: uniqueID }), ___arguments.context.appData)) })));
      }
    }

  } catch (error) {
    ___arguments.context.pageData['isLoadingUserButton'] = false;
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (['Invitation to ',name2,' for ',organizationName,' failed to send. Please try again.'].join('')), 'failure');

  }
}


  ___arguments.context.pageData['isLoadingUserButton'] = true;
  userUpdateObject = (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  uniqueID = (generateUUID().long());
  randomPassword = (generateUUID().short());
  email = (getObjectProperty(userUpdateObject, 'email'));
  name2 = (getObjectProperty(userUpdateObject, 'name'));
  phoneNumber = String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(userUpdateObject, 'phoneNumber')), 'remove')));
  userRole = (getObjectProperty(userUpdateObject, 'userRole'));
  alreadyExistingUser = (
      await (async function(userIdentity){
        var userColumns = await Backendless.UserService.describeUserClass()
        var identityColumn = userColumns.find(column => column.identity)
        var whereClause = `${identityColumn.name} = '${userIdentity}'`
        var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
        var users = await Backendless.Data.of(Backendless.User).find(query)

        return users[0]
     })(email)
  );
  hasNoErrors = true;
  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
    organizationId = (getObjectProperty(currentUser, 'organizationId.objectId'));
  } else {
    var organization_list = (getObjectProperty(___arguments.context.pageData, 'organizationOptions'));
    for (var organization_index in organization_list) {
      organization = organization_list[organization_index];
      if ((getObjectProperty(userUpdateObject, 'orgName')) == (getObjectProperty(organization, 'label'))) {
        organizationId = (getObjectProperty(organization, 'value'));
      }
    }
  }
  if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'](email))) {
    ___arguments.context.pageData['emailError'] = 'invalid';
    hasNoErrors = false;
  }
  if (alreadyExistingUser && (getObjectProperty(alreadyExistingUser, 'email')) != (getObjectProperty(selectedItemUser, 'email'))) {
    ___arguments.context.pageData['emailError'] = 'duplicate';
    hasNoErrors = false;
  }
  if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'](phoneNumber))) {
    ___arguments.context.pageData['phoneError'] = true;
    hasNoErrors = false;
  }
  if (hasNoErrors && ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'editUser' || (getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'viewProfile')) {
    ___arguments.context.pageData['isUpdateUserModalOpen'] = true;
  } else if (hasNoErrors) {
    try {
      await registerUser();
      if (getObjectProperty(registeredUser, 'objectId')) {
        userObjectId = (getObjectProperty(registeredUser, 'objectId'));
        await sendAdminEmail();
        if (sendGridResponse) {
          await BackendlessUI.Functions.Custom['fn_e1b4ed7b0fa7f3872bddf226a82a4b7e'](___arguments.context.pageData, email, uniqueID);
          ___arguments.context.pageData['noResultsScreen'] = false;
          ___arguments.context.pageData['isAddRecordModalOpen'] = false;
          ___arguments.context.pageData['refreshUserTable'] = true;
          ___arguments.context.pageData['isSuccessModalOpen'] = true;
          ___arguments.context.pageData['isLoadingUserButton'] = false;
        }
      }

    } catch (error) {
      ___arguments.context.pageData['isLoadingUserButton'] = false;
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (['Invitation to ',name2,' for ',organizationName,' failed to send. Please try again.'].join('')), 'failure');

    }
  } else {
    ___arguments.context.pageData['isLoadingUserButton'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, userUpdateObject, selectedItem, isDisabledArray, isUserButtonDisabledObject, recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isDisabled = true;
  isUserButtonDisabledObject = (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'));
  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  userUpdateObject = (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'));
  if (isUserButtonDisabledObject) {
    if (recordTableVariant == 'editUser' || recordTableVariant == 'viewProfile') {
      isDisabledArray = [(getObjectProperty(isUserButtonDisabledObject, 'name')), (getObjectProperty(isUserButtonDisabledObject, 'phone')), (getObjectProperty(isUserButtonDisabledObject, 'email')), (getObjectProperty(isUserButtonDisabledObject, 'role')), (getObjectProperty(isUserButtonDisabledObject, 'selectedImage')), (getObjectProperty(isUserButtonDisabledObject, 'organization'))];
      for (var selectedItem_index in isDisabledArray) {
        selectedItem = isDisabledArray[selectedItem_index];
        if (!selectedItem) {
          isDisabled = false;
          break;
        }
      }
      if (getObjectProperty(___arguments.context.pageData, 'isLoadingUserButton')) {
        isDisabled = true;
      } else if (getObjectProperty(___arguments.context.pageData, 'disableButton')) {
        isDisabled = true;
      }
    } else {
      isDisabled = false;
      if (getObjectProperty(___arguments.context.pageData, 'isLoadingUserButton')) {
        isDisabled = true;
      }
      if (!(getObjectProperty(userUpdateObject, 'name')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(userUpdateObject, 'name'))))) {
        isDisabled = true;
      } else if (!(getObjectProperty(userUpdateObject, 'phoneNumber')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(userUpdateObject, 'phoneNumber'))))) {
        isDisabled = true;
      } else if (!(getObjectProperty(userUpdateObject, 'email')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(userUpdateObject, 'email'))))) {
        isDisabled = true;
      } else if (!(getObjectProperty(userUpdateObject, 'userRole')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(userUpdateObject, 'userRole'))))) {
        isDisabled = true;
      } else if (!(getObjectProperty(userUpdateObject, 'orgName')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(userUpdateObject, 'orgName'))))) {
        if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
          isDisabled = true;
        }
      }
    }
    ___arguments.context.pageData['isUserButtonDisabled'] = isDisabled;
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (getObjectProperty(___arguments.context.pageData, 'isLoadingUserButton')) {
    return 'Please wait...';
  } else if (recordTableVariant == 'addUser') {
    return 'Send Invite';
  } else if (recordTableVariant == 'editUser' || recordTableVariant == 'viewProfile') {
    return 'Update';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c995827a0cc10d23e4e2dc4ab6b4c8cf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  ___arguments.context.pageData['confirmationOption'] = 'cancel';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6dd49041865c63d9a58bc5b48c45998f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'curfew') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6dd49041865c63d9a58bc5b48c45998f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6dd49041865c63d9a58bc5b48c45998f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'))) {
    ___arguments.context.pageData['curfewPillarArray'] = [];
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9f021ada8b9c225cb39fc7d851c77b74/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) != 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f021ada8b9c225cb39fc7d851c77b74', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f021ada8b9c225cb39fc7d851c77b74', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')) {
    return (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/eeca80815c873dd8412249c16049aa39/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['userRole'] = ___arguments.value;
  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (recordTableVariant == 'viewProfile') {
    if (___arguments.value != (getObjectProperty(___arguments.context.appData, 'currentUser.userRole'))) {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['role'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['role'] = true;
    }
  } else {
    if (___arguments.value != (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole'))) {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['role'] = false;
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['role'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (recordTableVariant == 'viewProfile' || (getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) != 'super' && (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'admin') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eeca80815c873dd8412249c16049aa39', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eeca80815c873dd8412249c16049aa39', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8899112802ae7d2fb3f6be64712a3865/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, currentUser, recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['name'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyName'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (recordTableVariant == 'viewProfile' && ___arguments.value != (getObjectProperty(currentUser, 'name')) || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser')) && ___arguments.value != (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name'))) {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['name'] = false;
      ___arguments.context.pageData['emptyName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['name'] = true;
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/36b812048ae176bf00b512cc5b086ed3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (recordTableVarient == 'resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36b812048ae176bf00b512cc5b086ed3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36b812048ae176bf00b512cc5b086ed3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/99bdb68696f5ddc83938faeda578929d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var device, FCMToken, configurationId, lastPillar, pillarHistoryArray, mdmToken, mdmGroups, profilePictureUrl, resident, currentUser, hasError, deviceObjectId, adminContact, adminContacts, fcmResponse, contact, mdmResponse, groupObject, deviceIdResponse, residentContacts, listOfFilteredContacts, contacts, activeContact, contactArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

/**
 * Describe this function...
 */
async function residentContactStepTwo() {
  ___arguments.context.pageData['isLoadingIntakeForm'] = true;
  try {
    adminContacts = (getObjectProperty(___arguments.context.pageData, 'adminContacts'));
    deviceObjectId = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceObjectId'));
    FCMToken = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.FCMToken'));
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['contactArray'] = (await Promise.all((JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentContacts')))))).map(async contact => {  if (getObjectProperty(contact, 'cellPhone')) {
        contact['cellPhone'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(contact, 'cellPhone')), 'remove'))));
      }
      if (getObjectProperty(contact, 'homePhone')) {
        contact['homePhone'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(contact, 'homePhone')), 'remove'))));
      }
    ; return contact;})));
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['pillarInfo'] = ({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined });
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'ACTIVE';
    await BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235']((getObjectProperty(currentUser, 'organizationId.orgName')), (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')), ___arguments.context.pageData);
    fcmResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleB2BFireBaseMessaging`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'token': FCMToken,'contactArray': (getObjectProperty(___arguments.context.pageData, 'contactArray')),'variant': 'deviceEnrollment' }));
    if ((getObjectProperty(fcmResponse, 'statusCode')) == '200') {
      await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'ACTIVE',[`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarHistory`]: (JSON.stringify([({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined })])),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
      await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.organizationId')),[`adminData`]: (JSON.stringify((await Promise.all(adminContacts.map(async adminContact => {  if (getObjectProperty(adminContact, 'cellPhone')) {
          adminContact['cellPhone'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(adminContact, 'cellPhone')), 'remove'))));
        }
        if (getObjectProperty(adminContact, 'homePhone')) {
          adminContact['homePhone'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(adminContact, 'homePhone')), 'remove'))));
        }
      ; return adminContact;}))))) }) );
      await Backendless.Data.of('Devices').save( ({ [`objectId`]: deviceObjectId,[`deviceStatus`]: 'ASSIGNED' }) );
      ___arguments.context.pageData['refreshResidentTable'] = true;
      ___arguments.context.pageData['isLoadingIntakeForm'] = false;
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
        await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Successfully updated contacts.', 'success');
      } else {
        ___arguments.context.pageData['successModalVariant'] = 'addContacts';
        ___arguments.context.pageData['isSuccessModalOpen'] = true;
      }
    } else {
      throw (new Error(''))
    }

  } catch (error) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error updating contacts', 'failure');
    } else {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error completing device assignment.', 'failure');
    }
    ___arguments.context.pageData['isLoadingIntakeForm'] = false;

  }
}

/**
 * Describe this function...
 */
async function refreshMDMToken() {
  try {
    await BackendlessUI.Functions.Custom['fn_f15eaeb413dd69197945a3bae68dcbde']();
    mdmToken = (getObjectProperty(((await Backendless.Data.of('Configurations').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'mdmToken\'').setPageSize(10)))[0]), 'value'));
    await updatePillar();

  } catch (error) {
    if ((getObjectProperty(___arguments.context.appData, 'residentApplicationName')) && (getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (['Error updating ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')).toLowerCase(),'.'].join('')), 'failure');
    }
    ___arguments.context.pageData['isLoadingIntakeForm'] = false;

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function updatePillar() {
  deviceIdResponse = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/DeviceId`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify(mdmToken),'deviceNumber': JSON.stringify(device) }).send()), 'data.id'));
  if ((getObjectProperty(deviceIdResponse, 'status')) == 'OK') {
    if ((getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')) == 'Cyber Dive Debug DND' || (getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
      groupObject = ({ [`id`]: '2',[`name`]: 'CyberDive' });
    } else {
      groupObject = ({ [`id`]: (getObjectProperty(mdmGroups, (String(getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')) + String('.id')))),[`name`]: (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')) });
    }
    mdmResponse = (await Backendless.Request.put(`${Backendless.appPath}/services/HeadwindMDM/updateDevice`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'data': ({ [`id`]: deviceIdResponse,[`number`]: device,[`configurationId`]: configurationId,[`groups`]: [groupObject] }),'token': mdmToken }));
    if ((getObjectProperty(mdmResponse, 'status')) == 'OK') {
      fcmResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleB2BFireBaseMessaging`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'token': FCMToken,'variant': 'updatePillar','pillar': (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) }));
      if ((getObjectProperty(fcmResponse, 'statusCode')) == '200') {
        (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['pillarInfo'] = ({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined });
        await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`pillarHistory`]: (JSON.stringify((addItemToList(pillarHistoryArray, ({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined }))))),[`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
        await BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235']((getObjectProperty(currentUser, 'organizationId.orgName')), (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')), ___arguments.context.pageData);
        (getObjectProperty(___arguments.context.pageData, 'selectedItemResident'))['pillar'] = (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio'));
        ___arguments.context.pageData['isLoadingIntakeForm'] = false;
        ___arguments.context.pageData['isAddRecordModalOpen'] = false;
        if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
          await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' update successful.')), 'success');
        }
        if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 'graduated') {
          ___arguments.context.pageData['residentIntakeVariant'] = 'graduated';
        }
      } else {
        throw (new Error(''))
      }
    }
  } else {
    throw (new Error(''))
  }
}

/**
 * Describe this function...
 */
async function residentContactStepOne() {
  hasError = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c2b556a5dd7ae7caa418518916d97f87', (await BackendlessUI.Functions.Custom['fn_be3b54de9a4b651795a9bc82609b657e'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c2b556a5dd7ae7caa418518916d97f87')))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('dd5ab797902e50d9fc74ea9c04f3a6cc', (await BackendlessUI.Functions.Custom['fn_be3b54de9a4b651795a9bc82609b657e'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('dd5ab797902e50d9fc74ea9c04f3a6cc')))));
  adminContacts = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c2b556a5dd7ae7caa418518916d97f87'));
  residentContacts = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('dd5ab797902e50d9fc74ea9c04f3a6cc'));
  listOfFilteredContacts = (adminContacts.concat(residentContacts));
  for (var contacts_index in listOfFilteredContacts) {
    contacts = listOfFilteredContacts[contacts_index];
    if (getObjectProperty(contacts, 'homeError')) {
      hasError = true;
      break;
    }
    if (getObjectProperty(contacts, 'mobileError')) {
      hasError = true;
      break;
    }
    if (getObjectProperty(contacts, 'contactError')) {
      hasError = true;
      break;
    }
  }
  if (!hasError) {
    ___arguments.context.pageData['contactArray'] = (await filterContacts(listOfFilteredContacts));
    ___arguments.context.pageData['adminContacts'] = (await filterContacts(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c2b556a5dd7ae7caa418518916d97f87'))));
    ___arguments.context.pageData['residentContacts'] = (await filterContacts(residentContacts));
    ___arguments.context.pageData['residentIntakeContactsStep'] = 2;
  }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function filterContacts(contactArray) {
  return await asyncListFilter(contactArray, async (activeContact) => {
   if (!(getObjectProperty(activeContact, 'cellPhone')) && !(getObjectProperty(activeContact, 'homePhone')) && !(getObjectProperty(activeContact, 'fullName'))) {
    return false;
  } else {
    return true;
  }


 return ;
})
}


  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  if (!(getObjectProperty(___arguments.context.pageData, 'getQRCodeError'))) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 2) {
        hasError = false;
        if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email'))))) {
          ___arguments.context.pageData['residentIntakeFormEmailError'] = true;
          hasError = true;
        }
        if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber')), 'remove'))))) {
          ___arguments.context.pageData['residentIntakeFormPhoneNumberError'] = true;
          hasError = true;
        }
      }
      if (!hasError) {
        if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
          ___arguments.context.pageData['isLoadingIntakeForm'] = true;
          try {
            (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'INCOMPLETE';
            resident = (await Backendless.Data.of('Residents').save( ({ [`name`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')),[`nameInUse`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')),[`status`]: 'INCOMPLETE' }) ));
            if (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile')) {
              profilePictureUrl = (await Backendless.Files.upload(
                  ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile'))[0]),
                  encodePath((['/profile_pictures/',(getObjectProperty(resident, 'objectId')),'/'].join(''))),
                  false
                ).then(result => result.fileURL));
              (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['profilePicture'] = profilePictureUrl;
            }
            await Backendless.Data.of('Residents').addRelation(resident, 'managerId', [currentUser]);
            await Backendless.Data.of('Residents').addRelation(resident, 'organizationId', [(getObjectProperty(currentUser, 'organizationId'))]);
            (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['residentObjectId'] = (getObjectProperty(resident, 'objectId'));
            await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(resident, 'objectId')),[`profilePicture`]: profilePictureUrl,[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
            ___arguments.context.pageData['successModalVariant'] = 'addResident';
            ___arguments.context.pageData['isSuccessModalOpen'] = true;
            ___arguments.context.pageData['isAddRecordModalOpen'] = false;
            ___arguments.context.pageData['isLoadingIntakeForm'] = false;
            ___arguments.context.pageData['refreshResidentTable'] = true;
            ___arguments.context.pageData['noResultsScreen'] = false;

          } catch (error) {
            if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
              await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (String('Error Adding ') + String(getObjectProperty(___arguments.context.appData, 'residentApplicationName'))), 'failure');
            }
            ___arguments.context.pageData['isLoadingIntakeForm'] = false;

          }
        }
        if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) < 4) {
          ___arguments.context.pageData['residentIntakeStep'] = ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) + 1);
        }
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
        ___arguments.context.pageData['isLoadingIntakeForm'] = true;
        try {
          if (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile')) {
            profilePictureUrl = (await Backendless.Files.upload(
                ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile'))[0]),
                encodePath((['/profile_pictures/',(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.residentObjectId')),'/'].join(''))),
                false
              ).then(result => result.fileURL));
            (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['profilePicture'] = profilePictureUrl;
            await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.residentObjectId')),[`nameInUse`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')),[`name`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')),[`profilePicture`]: profilePictureUrl,[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
          } else {
            await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.residentObjectId')),[`nameInUse`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')),[`name`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
          }
          ___arguments.context.pageData['isAddRecordModalOpen'] = false;
          ___arguments.context.pageData['isLoadingIntakeForm'] = false;
          ___arguments.context.pageData['refreshResidentTable'] = true;
          ___arguments.context.pageData['noResultsScreen'] = false;
          if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
            await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Updated Successfully')), 'success');
          }

        } catch (error) {
          if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
            await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (String('Error Editing ') + String(getObjectProperty(___arguments.context.appData, 'residentApplicationName'))), 'failure');
          }
          ___arguments.context.pageData['isLoadingIntakeForm'] = false;

        }
      } else {
        ___arguments.context.pageData['residentIntakeStep'] = 4;
        if (getObjectProperty(___arguments.context.pageData, 'isNewResident')) {
          ___arguments.context.pageData['residentIntakeVariant'] = 'intake';
        }
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar') {
      await BackendlessUI.Functions.Custom['fn_3db5c5b391911d4a26f66632339fc5e5'](___arguments.context.appData, ___arguments.context.pageData);
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
      if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceId')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.pillar')) != (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio'))) {
        ___arguments.context.pageData['isLoadingIntakeForm'] = true;
        mdmGroups = (getObjectProperty(___arguments.context.appData, 'mdmGroups'));
        mdmToken = (getObjectProperty(___arguments.context.appData, 'mdmToken'));
        pillarHistoryArray = (JSON.parse((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.pillarHistory'))));
        lastPillar = pillarHistoryArray.slice(-1)[0];
        replaceItemInList(pillarHistoryArray, ({ [`pillar`]: (getObjectProperty(lastPillar, 'pillar')),[`pillarStart`]: (getObjectProperty(lastPillar, 'pillarStart')),[`pillarEnd`]: String(new Date()) }), 'pillarStart');
        if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1') {
          configurationId = 6;
        } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '2') {
          configurationId = 7;
        } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '3') {
          configurationId = 8;
        } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '4') {
          configurationId = 9;
        }
        device = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceId'));
        FCMToken = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.FCMToken'));
        try {
          if (device) {
            await updatePillar();
          }

        } catch (error) {
          await refreshMDMToken();

        }
      } else {
        ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 2) {
        await residentContactStepTwo();
      } else {
        ___arguments.context.pageData['addingContact'] = true;
        await residentContactStepOne();
      }
    }
  } else {
    await BackendlessUI.Functions.Custom['fn_3db5c5b391911d4a26f66632339fc5e5'](___arguments.context.appData, ___arguments.context.pageData);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, button;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isDisabled = false;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('99bdb68696f5ddc83938faeda578929d'));
  if (getObjectProperty(___arguments.context.pageData, 'isLoadingIntakeForm')) {
    isDisabled = true;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar' && (!(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) || !(getObjectProperty(___arguments.context.pageData, 'hasAcknowledged')))) {
    isDisabled = true;
  } else if (!(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    isDisabled = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 1 || (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')))) || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')).length != 10) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer'))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer'))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer'))) {
      isDisabled = true;
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.other'))))) {
        isDisabled = true;
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.other'))))) {
        isDisabled = true;
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.other'))))) {
        isDisabled = true;
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 2 || (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer'))) {
      isDisabled = true;
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) == 'Other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.other'))))) {
        isDisabled = true;
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 3 || (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong'))))) {
      isDisabled = true;
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'getQRCodeError')) {
    isDisabled = false;
  }
  ___arguments.context.pageData['isResidentIntakeButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isResidentIntakeButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingIntakeForm')) {
    return 'Please wait...';
  } else {
    if (getObjectProperty(___arguments.context.pageData, 'getQRCodeError')) {
      return 'Refresh';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
        return 'Finish Intake';
      } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 3) {
        return 'Review';
      } else {
        return 'Continue';
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
        return 'Save';
      } else {
        return 'Review';
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'graduated') {
      if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
        return (String('Updated ') + String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')));
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
      return 'Update';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar') {
      return 'Scan QR Code';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
      if (getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) {
        return 'Please wait..';
      } else {
        return 'Waiting For Device...';
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts') {
      return 'Next';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      return 'Save';
    }
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e43d840387f7ac376c4fb3664f5f9f95/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e43d840387f7ac376c4fb3664f5f9f95', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e43d840387f7ac376c4fb3664f5f9f95', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/13da7acd9cea55df725bf9077c625f7e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13da7acd9cea55df725bf9077c625f7e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13da7acd9cea55df725bf9077c625f7e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b6e6bb9cba1fff5a489d183cff3afcbc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingQRCode')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6e6bb9cba1fff5a489d183cff3afcbc', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6e6bb9cba1fff5a489d183cff3afcbc', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/bf7d9ce47e4eb80a9db9d12923d99716/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'getQRCodeError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf7d9ce47e4eb80a9db9d12923d99716', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf7d9ce47e4eb80a9db9d12923d99716', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0e7346953abad935c3ab7fb174610df9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingQRCode')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e7346953abad935c3ab7fb174610df9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e7346953abad935c3ab7fb174610df9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a9d6f6613d785f391392c816f1565e58/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9d6f6613d785f391392c816f1565e58', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9d6f6613d785f391392c816f1565e58', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/4cb5417742d1b4be670af67f06d6ecd7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  ___arguments.context.pageData['confirmationOption'] = 'cancel';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'getQRCodeError')) && !(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4cb5417742d1b4be670af67f06d6ecd7', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4cb5417742d1b4be670af67f06d6ecd7', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f194cee12a76aa92d3e98275a8ffd627/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var residentIntakeVariant, recordTableVarient, curfewTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  residentIntakeVariant = (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant'));
  curfewTableVariant = (getObjectProperty(___arguments.context.pageData, 'curfewTableVariant'));
  if (recordTableVarient == 'addOrganization') {
    return 'Add Organization';
  } else if (recordTableVarient == 'editOrganization') {
    return 'Edit Organization';
  } else if (recordTableVarient == 'addUser') {
    return 'Add User';
  } else if (recordTableVarient == 'viewProfile') {
    return 'Edit Details';
  } else if (recordTableVarient == 'viewOrganization') {
    return 'View Organization';
  } else if (recordTableVarient == 'editUser') {
    return 'Edit User';
  } else if (recordTableVarient == 'curfew') {
    if (curfewTableVariant == 'add') {
      return 'Add Curfew';
    } else if (curfewTableVariant == 'edit') {
      return 'Edit Curfew';
    }
  } else if (recordTableVarient == 'resident') {
    if (residentIntakeVariant == 'intake' || residentIntakeVariant == 'viewIntake') {
      if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
        return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Intake Form'));
      }
    } else if (residentIntakeVariant == 'updatePillar') {
      if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
        return (['Update ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),' ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))].join(''));
      }
    } else if (residentIntakeVariant == 'pillar') {
      if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
        return (['Select ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),' ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))].join(''));
      }
    } else if (residentIntakeVariant == 'device') {
      return 'Assign Device';
    } else if (residentIntakeVariant == 'contacts') {
      return 'Contact Details';
    } else if (residentIntakeVariant == 'updateContacts') {
      return 'Update Contacts';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/40d72aefff6e9c899339b36444a38d06/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio'))))) {
    return (['Contact ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8'), 'index')),'/',(getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')))))].join(''));
  } else {
    return (['Contact ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8'), 'index')),'/',((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('dd5ab797902e50d9fc74ea9c04f3a6cc')).length].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d2279151cdba0c943c4945ae322202a6/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2279151cdba0c943c4945ae322202a6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2279151cdba0c943c4945ae322202a6', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/214469d7319cdac95d13b649565c16af/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('214469d7319cdac95d13b649565c16af', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('214469d7319cdac95d13b649565c16af', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/66296db36a09d5f1d966be2dbe4233d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['confirmationOption'] = 'cancel';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'getQRCodeError')) && !(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'getQRCodeError')) && !(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    classes = ['disabled_button_cancel'];
  } else {
    classes = ['default_button'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c679f474e10a17ed48b8e5f47c778209/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['setAdminContacts'] = true;
  ___arguments.context.pageData['setResidentContactsBack'] = true;
  ___arguments.context.pageData['isAdminContactsLocked'] = true;
  ___arguments.context.pageData['residentIntakeContactsStep'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/69b8803c973e539bd8cd2356686cc793/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['fullName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1b616d58262e69a165ed31543d3db4fc/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['nameInUse'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d42b17c1cd864bba65bc7c2888459364/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['dob'] = (await BackendlessUI.Functions.Custom['fn_8d46c0c781a168eb2601b8313797f5df'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/44517b25c38ffc8b458fe3d5d3e151ee/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Asian')

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Asian';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3a61f0e3deab248addf1c8d9644fc4de/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'American Indian or Alaska Native';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'American Indian or Alaska Native')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/590ef43ae77f89b974990d6df22588ad/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Black or African American';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Black or African American')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/668b8c19d1bfa57c068c80ca81470d6d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Hispanic or Latino';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Hispanic or Latino')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/50cd6b4a4ca5effa0033e7078241c4d2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Native Hawaiian or Other Pacific Islander';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Native Hawaiian or Other Pacific Islander')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/251625c3e8a87c1b4c9b0502ca0f378c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'White';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'White')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/903bc8b60106c5a3487fd33e11e3570e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Middle Eastern or North African';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Middle Eastern or North African')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0d15d3fd47fc4eeeefb1b47be8a40541/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'default';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'default')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/92a48012735ac93fab420bd4ce451234/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Other';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Other')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1ff58c0daec647aab2dabcf9e581daba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ff58c0daec647aab2dabcf9e581daba', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ff58c0daec647aab2dabcf9e581daba', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/fc8a1b1ddd1f3e020c7a5343eb6ed713/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity'))['answer'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e5940d1a18fb5677e854430215be6d26/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e5940d1a18fb5677e854430215be6d26', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e5940d1a18fb5677e854430215be6d26', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['residentIntakeForm.personalDetails.genderIdentity.other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6b343497b6d83ab2bd3d5c73882f6c03/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['residentIntakeForm.personalDetails.pronouns.other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b343497b6d83ab2bd3d5c73882f6c03', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b343497b6d83ab2bd3d5c73882f6c03', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/eaa3b2238cb4bf5ea4b591a7dab4cb5c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns'))['answer'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/977cbe6facf5c60e14b68e6f97291f99/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other' && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) != 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('977cbe6facf5c60e14b68e6f97291f99', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('977cbe6facf5c60e14b68e6f97291f99', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/19f4004d22befe96b518932d05e71877/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other' && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) != 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19f4004d22befe96b518932d05e71877', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19f4004d22befe96b518932d05e71877', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ac8dedbbb434b2df2bbaa59664c9ed22/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac8dedbbb434b2df2bbaa59664c9ed22', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac8dedbbb434b2df2bbaa59664c9ed22', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b3f1f4227fdf11c2317c1e84f787eeb6/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 3) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3f1f4227fdf11c2317c1e84f787eeb6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3f1f4227fdf11c2317c1e84f787eeb6', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0bb12058b0e42728be7706f1bfdb2ff5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact'))['fullName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/14e50b594402ac0a4ee1ba284f89af0f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['residentIntakeFormEmailError'] = false;
  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact'))['email'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeFormEmailError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c7fc37657f22bb8769c79859e58711e3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['residentIntakeFormPhoneNumberError'] = false;
  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeFormPhoneNumberError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f6f90c9cd1cd9e35a78bef418eedab57/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship'))['answer'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cadf0939f0678e37de2296887cabcb01/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails'))['comingFrom'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1eb13b928f58fa778297ae31638be8c9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails'))['howLong'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ef2a0e33a0f825abe3230cedbb6e0c1e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeFormEmailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef2a0e33a0f825abe3230cedbb6e0c1e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef2a0e33a0f825abe3230cedbb6e0c1e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/889df8137a67fa6b4959c02b3202f1fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
  ___arguments.context.pageData['residentIntakeStep'] = 2;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/91b8042d03aa02535d927106c6613ef5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship'))['other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('91b8042d03aa02535d927106c6613ef5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('91b8042d03aa02535d927106c6613ef5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7f3d01da9ec480bbc1d9166c688d2465/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3d01da9ec480bbc1d9166c688d2465', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3d01da9ec480bbc1d9166c688d2465', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ecc36191fdd6c8a151aaca937ea9507f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecc36191fdd6c8a151aaca937ea9507f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecc36191fdd6c8a151aaca937ea9507f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a9a9ebfaa7b6e97642f60de721f17728/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9a9ebfaa7b6e97642f60de721f17728', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9a9ebfaa7b6e97642f60de721f17728', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a0b45eb5ac9f8e7fa0e703ea340538bd/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0b45eb5ac9f8e7fa0e703ea340538bd', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0b45eb5ac9f8e7fa0e703ea340538bd', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ac1d864c8127e0756ac2303dba35d824/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1' || (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '2') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac1d864c8127e0756ac2303dba35d824', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac1d864c8127e0756ac2303dba35d824', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7bbd8cace7629d93df7db3761169ec9a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1' || (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '2') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7bbd8cace7629d93df7db3761169ec9a', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7bbd8cace7629d93df7db3761169ec9a', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d451966bcf53457e31ecd2ed895acace/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d451966bcf53457e31ecd2ed895acace', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d451966bcf53457e31ecd2ed895acace', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/799731983606aff194fc1719a8a6330b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799731983606aff194fc1719a8a6330b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799731983606aff194fc1719a8a6330b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/97bcb3456ab9180e4d3570230a35b456/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentPillarRadio'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b66e60338d7af3f2cf41c57797a49daf/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b66e60338d7af3f2cf41c57797a49daf', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b66e60338d7af3f2cf41c57797a49daf', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ff5cf495e85ac75b691b98175cd30f48/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff5cf495e85ac75b691b98175cd30f48', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff5cf495e85ac75b691b98175cd30f48', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5b77db5c527d8054a8413b6a916dc8e3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5b77db5c527d8054a8413b6a916dc8e3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5b77db5c527d8054a8413b6a916dc8e3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/93aa2f8331c1f0742dbc38a186b687e4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('93aa2f8331c1f0742dbc38a186b687e4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('93aa2f8331c1f0742dbc38a186b687e4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d33150526201082984a4335310446cb8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d33150526201082984a4335310446cb8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d33150526201082984a4335310446cb8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/98f2253f5076f04d8088fee47445c3f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentPillarRadio'] = 2;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/2f429cf1322ebad6e9ad61b9d48f6b39/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentPillarRadio'] = 3;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ded148dc338e548364d5df1ec8a4a3d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;


  ___arguments.context.pageData['residentPillarRadio'] = 4;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/05ebc46da08bf4ef7d473f46ee5af39d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value) {
    ___arguments.context.pageData['hasAcknowledged'] = true;
  } else {
    ___arguments.context.pageData['hasAcknowledged'] = undefined;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3db6e2d1b6739d09b1882d6cdfb49f95/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
      ___arguments.context.pageData['selectedItemResidentImageFile'] = ___arguments.selectedFiles;

  },
  /* handler:onSelectFiles */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3db6e2d1b6739d09b1882d6cdfb49f95', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3db6e2d1b6739d09b1882d6cdfb49f95', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/46a95b64fdae72346654e295fad4682f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemResidentImageFile'] = undefined;
  ___arguments.context.pageData['selectedItemResidentImage'] = undefined;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46a95b64fdae72346654e295fad4682f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46a95b64fdae72346654e295fad4682f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/19ab6eb2fd62b21e22ef2936d25dbd3d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var selectedItemResidentImageFile;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  selectedItemResidentImageFile = (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile'));
  if (selectedItemResidentImageFile) {
    ___arguments.context.pageData['selectedItemResidentImage'] = (await readBrowserFileAs((selectedItemResidentImageFile[0]), 'base64'));
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture')) && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile'))) {
      ___arguments.context.pageData['selectedItemResidentImage'] = (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture'));
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture')) && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentImageFile'))) {
      ___arguments.context.pageData['selectedItemResidentImage'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5c29da1faad7ad9286ea050dd61db939/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['residentIntakeStep'] = ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) - 1);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b6df222f8359eef1f94e911b4a574ac1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' && !((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 1) && !((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4)) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6df222f8359eef1f94e911b4a574ac1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6df222f8359eef1f94e911b4a574ac1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/319d9df474ea0ff41d59ce8f193c8891/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('319d9df474ea0ff41d59ce8f193c8891', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('319d9df474ea0ff41d59ce8f193c8891', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/230d5362554683833b3aa60113dfc177/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
  ___arguments.context.pageData['residentIntakeStep'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3326a11a36b2deec7da8f732f66f4bee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
  ___arguments.context.pageData['residentIntakeStep'] = 3;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ccdfb107b7011e65f7957b88675a4a7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'default') {
      return 'Prefer Not To Answer';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Other') {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.other'));
    } else {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/62b7f247ce3ac01c4309effd024560c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 'graduate') {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;


  ___arguments.context.pageData['residentPillarRadio'] = 'graduate';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/34e673459c4732ae86d898b78b4e1e34/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1edc3b944504a253f18c51ffdb82ebc8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1edc3b944504a253f18c51ffdb82ebc8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1edc3b944504a253f18c51ffdb82ebc8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cde8434bb1ba7191231e946eecdb5e2a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cde8434bb1ba7191231e946eecdb5e2a', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cde8434bb1ba7191231e946eecdb5e2a', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d3cdf7aa268b9215506acb54e4a1175d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d3cdf7aa268b9215506acb54e4a1175d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d3cdf7aa268b9215506acb54e4a1175d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8ce2988c00ae0f7c0d268de149763384/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8ce2988c00ae0f7c0d268de149763384', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8ce2988c00ae0f7c0d268de149763384', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b86577b35f6672b14ebaf375053ac7c1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b86577b35f6672b14ebaf375053ac7c1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b86577b35f6672b14ebaf375053ac7c1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/265d0afba1d7ec99b7ebd4be80622fe1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'noCurfews')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265d0afba1d7ec99b7ebd4be80622fe1', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265d0afba1d7ec99b7ebd4be80622fe1', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/35370bbd1a390a0acdfa6c5216cc85b5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35370bbd1a390a0acdfa6c5216cc85b5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35370bbd1a390a0acdfa6c5216cc85b5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/88038899fba7a4259ec28b117802e3ff/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88038899fba7a4259ec28b117802e3ff', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88038899fba7a4259ec28b117802e3ff', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b474f1384d755019503c79e815539a8a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b474f1384d755019503c79e815539a8a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b474f1384d755019503c79e815539a8a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/86acd97385412c6c71ebce05521a1659/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86acd97385412c6c71ebce05521a1659', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86acd97385412c6c71ebce05521a1659', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9d1b7fd112aa980459c99582653f95ab/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d1b7fd112aa980459c99582653f95ab', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d1b7fd112aa980459c99582653f95ab', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/dd7d46c82a8d5aede09d265f11b5b58d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dd7d46c82a8d5aede09d265f11b5b58d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dd7d46c82a8d5aede09d265f11b5b58d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b222784fb22268583e1c2dbff6487c17/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b222784fb22268583e1c2dbff6487c17', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b222784fb22268583e1c2dbff6487c17', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3d516facc2b326c5e6570deb06472ca5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    return 'You are permitted to save one contact on the device.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'You are permitted to save three contacts on the device.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    return 'You are permitted to save five contacts on the device.';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1953674b118d4a2f088eb19a344bd9b1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    return 'The device will have only four apps installed.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'The device will have only six apps installed.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    return 'The device will have only eight apps installed.';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/54d7c67c647aace69402de7f9721f305/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'residentApplicationName')) && (getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))) {
    if ((getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio'))))) == 1) {
      return (['You can add only 1 contact for ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),'s'].join(''));
    } else if (!(getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')))))) {
      return (['Add contacts for this ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase()].join(''));
    } else {
      return (['You can add only ',(getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio'))))),' contacts for ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3b35073e031afc15c8de6771e9077ee5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
    return 'Contacts';
  } else {
    return 'Add Contacts';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c2b556a5dd7ae7caa418518916d97f87'));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6882032e6c3f3b5c963a24d3c1a3824f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (recordTableVarient == 'viewProfile' || recordTableVarient == 'addUser' || recordTableVarient == 'editUser') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6882032e6c3f3b5c963a24d3c1a3824f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6882032e6c3f3b5c963a24d3c1a3824f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a30cff36304a50d68388af1d4f6544da/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemUserImageFile'] = ___arguments.selectedFiles;
  (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['selectedImage'] = false;

  },
  /* handler:onSelectFiles */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemUserImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a30cff36304a50d68388af1d4f6544da', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a30cff36304a50d68388af1d4f6544da', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e70a196a6334667db5dd836249cdc61e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemUserImageFile'] = undefined;
  (getObjectProperty(___arguments.context.pageData, 'isUserButtonDisabledObject'))['selectedImage'] = true;
  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'viewProfile') {
    (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['profilePicture'] = (getObjectProperty(___arguments.context.appData, 'currentUser.profilePicture'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'editUser') {
    (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['profilePicture'] = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.profilePicture'));
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemUserImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e70a196a6334667db5dd836249cdc61e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e70a196a6334667db5dd836249cdc61e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/edb5ed4329df98b87f3b3fc70178cc5a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var selectedItemUserImageFile, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  selectedItemUserImageFile = (getObjectProperty(___arguments.context.pageData, 'selectedItemUserImageFile'));
  if (selectedItemUserImageFile) {
    (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['profilePicture'] = (await readBrowserFileAs((selectedItemUserImageFile[0]), 'base64'));
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'userUpdateObject')) && !(getObjectProperty(___arguments.context.pageData, 'userUpdateObject.profilePicture'))) {
      (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['profilePicture'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0e2d5f86da0c3a063bd2bfffbba6a5c2/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'addUser') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e2d5f86da0c3a063bd2bfffbba6a5c2', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e2d5f86da0c3a063bd2bfffbba6a5c2', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/df7b22a57e44e2352aac4775691f677f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'phoneError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df7b22a57e44e2352aac4775691f677f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df7b22a57e44e2352aac4775691f677f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9d8d176ab4fd3f23314aabef9c35eaf8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (recordTableVarient == 'viewOrganization' || recordTableVarient == 'addOrganization' || recordTableVarient == 'editOrganization') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d8d176ab4fd3f23314aabef9c35eaf8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d8d176ab4fd3f23314aabef9c35eaf8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/24919d59278259e1cd827f9b7e79492d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['address'] = ___arguments.value;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyAddress'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.address'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['address'] = false;
      ___arguments.context.pageData['emptyAddress'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'address'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['address'] = false;
      ___arguments.context.pageData['emptyAddress'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['address'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/774972b6c1ef2fa7a8faf0d7a0f81cc7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['state'] = ___arguments.value;
  if (currentUser) {
    if (menuOptionSelection == 'viewOrganization') {
      if (___arguments.value != (getObjectProperty(currentUser, 'organizationId.state'))) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = false;
      } else {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = true;
      }
    } else if (selectedItemOrganization) {
      if (___arguments.value != (getObjectProperty(selectedItemOrganization, 'state'))) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = false;
      } else {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5f6e0008f6f53147469923cb01a7e443/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['city'] = ___arguments.value;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyCity'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.city'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['city'] = false;
      ___arguments.context.pageData['emptyCity'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'city'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['city'] = false;
      ___arguments.context.pageData['emptyCity'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['city'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d5dc40ef803ce12d2a0c96cdd6f1f56e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyBillingName'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.billingName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingName'] = false;
      ___arguments.context.pageData['emptyBillingName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'billingName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingName'] = false;
      ___arguments.context.pageData['emptyBillingName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyLogo')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      ___arguments.context.pageData['disableButton'] = false;
      if (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled')) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingName'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/413ca8f504e6de476d62abd101469f4c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['logo'] = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo'));
  ___arguments.context.pageData['selectedItemOrganizationImageFile'] = undefined;
  ___arguments.context.pageData['imageName'] = undefined;
  (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['selectedImage'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('413ca8f504e6de476d62abd101469f4c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('413ca8f504e6de476d62abd101469f4c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/4a5507092737ba5f0d6169af765a0a38/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'zipCodeError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4a5507092737ba5f0d6169af765a0a38', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4a5507092737ba5f0d6169af765a0a38', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cda26426fcf385ea3e4819f95e7a1638/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'billingPhoneError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cda26426fcf385ea3e4819f95e7a1638', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cda26426fcf385ea3e4819f95e7a1638', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/90325dacbd142f8835d8e80410130ae9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'taxIdError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90325dacbd142f8835d8e80410130ae9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90325dacbd142f8835d8e80410130ae9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1171f6b62c0b116bf7cd347c7ac60961/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeFormPhoneNumberError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1171f6b62c0b116bf7cd347c7ac60961', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1171f6b62c0b116bf7cd347c7ac60961', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/29b9e56cbe8c0b5c40c7543b3612e035/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (recordTableVarient == 'curfew') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29b9e56cbe8c0b5c40c7543b3612e035', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29b9e56cbe8c0b5c40c7543b3612e035', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/16b9d471927ccd7e34ebbde1123405b2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'viewProfile') {
    return 'Your Details';
  } else {
    return 'User Details';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1f693d229c09d87ed8a51f7d852d4f17/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  if (recordTableVariant == 'viewProfile' || (getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) != 'super' && (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'admin') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1f693d229c09d87ed8a51f7d852d4f17', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1f693d229c09d87ed8a51f7d852d4f17', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'admin') {
    return 'Administrator';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'manager') {
    return 'Case Manager';
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/857b4c5db02cdf0a03d3d275751670d1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('857b4c5db02cdf0a03d3d275751670d1', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('857b4c5db02cdf0a03d3d275751670d1', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ada3c4cfbdbe196db033d8e49a7a55e9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ada3c4cfbdbe196db033d8e49a7a55e9', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ada3c4cfbdbe196db033d8e49a7a55e9', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/09c8390ac3f5cd360cc96ce414fbbe9b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOrganizationAccessDisabled')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('09c8390ac3f5cd360cc96ce414fbbe9b', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('09c8390ac3f5cd360cc96ce414fbbe9b', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9c59c9a26caa9cdd2afc98e49d5cecc4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var curfewTimes, time, item, curfewArray, curfew, timeArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function createTimeString(timeArray) {
  if (timeArray) {
    if (timeArray.length == 0) {
      ___arguments.context.pageData['noCurfews'] = true;
    } else if (timeArray.length == 1) {
      return (timeArray[0]);
    } else if (timeArray.length >= 2) {
      return ([timeArray.slice(1, timeArray.length).join(', '),' and ',timeArray[0]].join(''));
    }
  }
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeCurfews')) && (getObjectProperty(___arguments.context.pageData, 'activeCurfews')).length > 0) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
      curfewArray = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'activeCurfews')), async (curfew) => {
         if ((getObjectProperty(curfew, 'pillar')) == '1') {
          return true;
        }


       return ;
      }));
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
      curfewArray = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'activeCurfews')), async (curfew) => {
         if ((getObjectProperty(curfew, 'pillar')) == '2') {
          return true;
        }


       return ;
      }));
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
      curfewArray = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'activeCurfews')), async (curfew) => {
         if ((getObjectProperty(curfew, 'pillar')) == '3') {
          return true;
        }


       return ;
      }));
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
      curfewArray = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'activeCurfews')), async (curfew) => {
         if ((getObjectProperty(curfew, 'pillar')) == '4') {
          return true;
        }


       return ;
      }));
    }
    if (curfewArray && curfewArray.length > 0) {
      ___arguments.context.pageData['noCurfews'] = false;
      curfewTimes = await createTimeString((await Promise.all(curfewArray.map(async item => {  time = (JSON.parse((getObjectProperty(item, 'times'))));
      ; return ([(await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(time, 'startTime')))),' to ',(await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(time, 'endTime'))))].join(''));}))));
    } else {
      ___arguments.context.pageData['noCurfews'] = true;
    }
  } else {
    ___arguments.context.pageData['noCurfews'] = true;
  }
  ___arguments.context.pageData['curfewContentText'] = (String('Device will be restricted from ') + String(curfewTimes));

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/17e8c84b79fd166d8cd752b5cf8bc8bf/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'scrollToTop')) {
    ___arguments.context.pageData['scrollToTop'] = undefined;
    await new Promise(r => setTimeout(r, 100 || 0));
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c0c366ce7e115e4ab28607de48941274')));
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/2d7ac59c3ce528091d699a8986ad2e57/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isAdminContactsLocked'] = false;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a05497fd1d6f64ff0d898917cd948286/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['contactError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['fullName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'isAdminContactsLocked'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a3952a8296d256b7292ba37cb49c3594/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['contactError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['mobileError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['cellPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'isAdminContactsLocked'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3b564b0963c63024fe69b4913b4a8192/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['contactError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['homeError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('dafb406dc513ce46d34c80547b6312b2')['homePhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'isAdminContactsLocked'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c2b556a5dd7ae7caa418518916d97f87/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var contact;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.pageData, 'setAdminContacts')) {
    ___arguments.context.pageData['setAdminContacts'] = undefined;
    if ((getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.adminData')) && (JSON.parse((getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.adminData')))).length == 0) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c2b556a5dd7ae7caa418518916d97f87', [({ [`fullName`]: undefined,[`homePhone`]: undefined,[`cellPhone`]: undefined })]);
    } else {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c2b556a5dd7ae7caa418518916d97f87', (await Promise.all((JSON.parse((getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.adminData')))).map(async contact => {  if (getObjectProperty(contact, 'homePhone')) {
          contact['homePhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(contact, 'homePhone')), 2)), 'create'));
        }
        if (getObjectProperty(contact, 'cellPhone')) {
          contact['cellPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(contact, 'cellPhone')), 2)), 'create'));
        }
      ; return contact;}))));
    }
    if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c2b556a5dd7ae7caa418518916d97f87')).length == 5) {
      ___arguments.context.pageData['isLimitReached'] = true;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/23431cc287ee6c4476c1ab817dfec893/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c2b556a5dd7ae7caa418518916d97f87', (addItemToList(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c2b556a5dd7ae7caa418518916d97f87')), ({ [`fullName`]: undefined,[`cellPhone`]: undefined,[`homePhone`]: undefined }))));
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c2b556a5dd7ae7caa418518916d97f87')).length == 5) {
    ___arguments.context.pageData['isLimitReached'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/dd5ab797902e50d9fc74ea9c04f3a6cc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var contact, index;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.pageData, 'setResidentContacts')) {
    ___arguments.context.pageData['setResidentContacts'] = undefined;
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      index = 0;
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
        (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('dd5ab797902e50d9fc74ea9c04f3a6cc', [({ [`index`]: 1,[`fullName`]: undefined,[`homePhone`]: undefined,[`cellPhone`]: undefined })]);
      } else {
        (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('dd5ab797902e50d9fc74ea9c04f3a6cc', (await Promise.all((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).map(async contact => {  if (getObjectProperty(contact, 'homePhone')) {
            contact['homePhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(contact, 'homePhone')), 2)), 'create'));
          }
          if (getObjectProperty(contact, 'cellPhone')) {
            contact['cellPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(contact, 'cellPhone')), 2)), 'create'));
          }
          index = index + 1;
          contact['index'] = index;
        ; return contact;}))));
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts') {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('dd5ab797902e50d9fc74ea9c04f3a6cc', [({ [`index`]: 1,[`fullName`]: undefined,[`cellPhone`]: undefined,[`homePhone`]: undefined })]);
    }
  } else if (getObjectProperty(___arguments.context.pageData, 'setResidentContactsBack')) {
    ___arguments.context.pageData['setResidentContactsBack'] = undefined;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('dd5ab797902e50d9fc74ea9c04f3a6cc', (getObjectProperty(___arguments.context.pageData, 'residentContacts')));
  }
  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 1) {
    await new Promise(r => setTimeout(r, 10 || 0));
    if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('dd5ab797902e50d9fc74ea9c04f3a6cc')).length >= (getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')))))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('38f343c3517ad4a912a4e3de08a95772', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('38f343c3517ad4a912a4e3de08a95772', true);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 1) {
    if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('dd5ab797902e50d9fc74ea9c04f3a6cc')).length >= (getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')))))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('38f343c3517ad4a912a4e3de08a95772', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('38f343c3517ad4a912a4e3de08a95772', true);
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/38f343c3517ad4a912a4e3de08a95772/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('dd5ab797902e50d9fc74ea9c04f3a6cc', (addItemToList(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('dd5ab797902e50d9fc74ea9c04f3a6cc')), ({ [`index`]: ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('dd5ab797902e50d9fc74ea9c04f3a6cc')).length + 1,[`fullName`]: undefined,[`cellPhone`]: undefined,[`homePhone`]: undefined }))));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1a952ffc1c2676a64ebedc67beaf5722/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['contactError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['mobileError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['cellPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0ea150e35bd4fb273f6a2bd9556310d4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['contactError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['homeError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['homePhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9555c0b579a11f7dad8ee003184e4d79/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['contactError'] = undefined;
  ___arguments.context.getComponentDataStoreByUid('f5d7cbfe63e4c9c6b9689074653f14f8')['fullName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3d6c81516e54e82351352837e82d9b76/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'currentUser')) {
    try {
      await (async function(App_Data) {

      	// User information - Replace these with actual user data
      	const user = App_Data.currentUser;

      	// Function to initialize and open Intercom chat
      	function initializeIntercom() {
      	  window.intercomSettings = {
      	    api_base: "https://api-iam.intercom.io",
      	    app_id: "nuppfi6g",
      	    user_id: user.objectId,
      	    name: user.name,
      	    email: user.email,
      	    created_at: user.created
      	  };

      	  // Ensure Intercom widget is loaded
      	  (function() {
      	    var w = window;
      	    var ic = w.Intercom;

      	    if (typeof ic === "function") {
      	      // If Intercom is already available, update and show chat
      	      ic("update", window.intercomSettings);
      	      ic("show"); // Open chat immediately
      	    } else {
      	      // Load Intercom script
      	      var d = document;
      	      var i = function() {
      	        i.c(arguments);
      	      };
      	      i.q = [];
      	      i.c = function(args) {
      	        i.q.push(args);
      	      };
      	      w.Intercom = i;

      	      // Create script element
      	      var l = function() {
      	        var s = d.createElement("script");
      	        s.type = "text/javascript";
      	        s.async = true;
      	        s.src = "https://widget.intercom.io/widget/nuppfi6g";
      	        s.onload = function() {
      	          // After the script is fully loaded
      	          w.Intercom("update", window.intercomSettings);
      	          w.Intercom("show"); // Open chat after loading
      	        };
      	        var x = d.getElementsByTagName("script")[0];
      	        x.parentNode.insertBefore(s, x);
      	      };

      	      // Check when to load the script
      	      if (document.readyState === "complete") {
      	        l();
      	      } else if (w.attachEvent) {
      	        w.attachEvent("onload", l);
      	      } else {
      	        w.addEventListener("load", l, false);
      	      }
      	    }
      	  })();
      	}

      	// Initialize and open the Intercom chat
      	initializeIntercom();

      })(___arguments.context.appData);

    } catch (error) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load Intercom chat.', 'failure');

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/de73d5543c1334c9f13f98dd3a8c723b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Personal Details'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/89d122f65f61fa5df0591262f3ae142d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (['Where is the ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' coming from or who sent them?'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6c011d573b6d3403e2f2d6914f3d6b8d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (['Confirm ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),' Details'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8647bb49dcdbc937d7495dc1c12f0b82/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Personal Details'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c437f56f7098c1bc33f1552affe8be56/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (['Where is the ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' coming from or who sent them?'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9c64f671e9fcf04db4e8c812a6f2f9e3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'residentApplicationName')) && (getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))) {
    return ([(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),' ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1b3b6f74fc6947b87c120a9c295df7ff/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (['Assign ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' 01'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/73fec771a649e4b57d429b53490f3ba1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (['Assign ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' 02'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d3d66af29b54a2a9b206e72f9b251e43/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (['Assign ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' 03'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c89818ba7d24de4a1a7db94adf756702/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (['Assign ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' 04'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/22ce4905afbbbd1588f6a7fafb575947/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' are not allowed to remove the pre-installed apps.'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/404def76c52ae46e69dfcd9f03f9090e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String('’s live location will be continuously shared.'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/aec81bf59c014b73605d70397d3d50b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String('s cannot enable or disable Wi-Fi.'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/55149f2c83ec2672416df41735b1f3f9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String('s cannot disable the camera.'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1ac1f77c008cd239cc229ec720b2a161/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (['You are allowed to save an unlimited number of contacts. ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),' can add/remove contacts as per their wish.'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/fe03041ea1879c1a6b2cc618e1a9c0f1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' is allowed to install an unlimited number of apps.'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f0065783f91d3593e90262b77963b2ca/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Contacts'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e78b09e81bf5ba287daa08ab2295e99f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (['Once you move forward, you won’t be able to return to this page or change your ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')).toLowerCase(),' assignment'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8706bc551035acd887181561d182dbd8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other') {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.other'));
    } else {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/07148af587f7397429a99206863fe2be/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other') {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.other'));
    } else {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/095af3dcdabe39889a81abdc74e4a371/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) == 'Other') {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.other'));
    } else {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/ff7f35b30ccf79c740575d78f04765e4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var dayText, endTime, startTime, item, dayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  startTime = (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'))));
  endTime = (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'))));
  dayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (startTime && endTime && dayArray) {
    if (dayArray.length == 8) {
      dayText = 'Repeat every day';
    } else {
      dayText = (await Promise.all(dayArray.map(async item => {  if (item == 'Monday') {
          return 'Mon';
        } else if (item == 'Tuesday') {
          return 'Tues';
        } else if (item == 'Wednesday') {
          return 'Wed';
        } else if (item == 'Thursday') {
          return 'Thurs';
        } else if (item == 'Friday') {
          return 'Fri';
        } else if (item == 'Saturday') {
          return 'Sat';
        } else if (item == 'Sunday') {
          return 'Sun';
        }
      ; return ;}))).join(',');
    }
    return ([String(startTime.split(':')[0]) + String(startTime.slice(-2, startTime.length).toLowerCase()),' to ',String(endTime.split(':')[0]) + String(endTime.slice(-2, endTime.length).toLowerCase()),' | ',dayText].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1744f31d40d3b1943b7cadce9f616e1a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['curfewStep'] = 3;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/adb6f2429441a7995c81f753f2c94c36/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isTimeDrawerOneOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTimeDrawerOneOpen = (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen'));
  if (isTimeDrawerOneOpen) {
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  } else {
    ___arguments.context.pageData['curfewError'] = undefined;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/fb9ad416b534708849c2a9b6a2d74236/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'))));
  } else {
    return 'Choose time';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['color'] = 'rgba(0, 0, 0, 0.42)';
  }
  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'))));
  } else {
    return 'Choose time';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/140fc9750a7a1ff5f166e5f59afac0e1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('140fc9750a7a1ff5f166e5f59afac0e1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('140fc9750a7a1ff5f166e5f59afac0e1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/e2c295b7bb54e3593cdbdbba30c999ca/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e2c295b7bb54e3593cdbdbba30c999ca', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e2c295b7bb54e3593cdbdbba30c999ca', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f6bb63b154e7aab240dd48bca77ebad9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6bb63b154e7aab240dd48bca77ebad9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6bb63b154e7aab240dd48bca77ebad9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/a41b86958e6d43bbc8ce64c9fa6e8abf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 13;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 13;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 13 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 13) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3f5afcdd016cb4e985a8f5959fb9c29a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 14;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 14;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 14 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 14) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/60dc99f7db6180cf3a74d4d8d9e6a839/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 15;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 15;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 15 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 15) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/4d310dcce2dfee9dac8261219fba0de5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 16;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 16;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 16 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 16) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8d7e494bbc451e42ac6afbabc5ee6a22/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 17;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 17;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 17 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 17) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/58b24d1cedee19d8ddd1624dfff3f9a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 18;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 18;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 18 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 18) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/94a58231e43cdf7c7aa66f2a4fff5037/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 19;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 19;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 19 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 19) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/891d0847ee0d5ef3e90971b85fcff495/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 20;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 20;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 20 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 20) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8f87676dfb68cfc62f0120ae26f561a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 21;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 21;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 21 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 21) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3c8cfae477a115a9e9c36732ec37bc59/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 22;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 22;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 22 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 22) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/32a2e159ed38a63116d5c11d6c99cc99/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 23;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 23;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 23 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 23) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/beb6abe1e8d69b57c49e65e1008982ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 12;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 12;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 12 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 12) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/622e013ea52767c7c404172ef0845f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 1;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 1 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 1) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/e8af1d5238811c413a7a574eebab18f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 2;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 2;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 2 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 2) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/e79b08d6211659a5211bf7dd398d4d3a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 3;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 3;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 3 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 3) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/a0f151e7dd2c334c37637a2e135a1967/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 4;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 4;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 4 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 4) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/ff0d199aa2ebbf6beb5607aa7e216771/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 5;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 5;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 5 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 5) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/af91ffe7af479e61cf4a151b73573c21/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 6;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 6;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 6 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 6) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/645f3d94d1661cf1b481fc383188872a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 7;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 7;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 7 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 7) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/60fb2344a235483496a7ef8e16d1d6a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 8;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 8;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 8 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 8) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/16d1a77edfbd960e04536641cdfc47a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 9;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 9;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 9 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 9) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/b69bdf3fbb8c601118ef43d729992c7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 10;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 10;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 10 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 10) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d55e9475a75b2a826acfcee22d637f96/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 11;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 11;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 11 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 11) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/63a64bf1bca6c84bb096d12416c83c2a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 24;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 24;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 24 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 24) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7e57e63cbfa572922ac50ab77f33f599/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isTimeDrawerTwoOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTimeDrawerTwoOpen = (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen'));
  if (isTimeDrawerTwoOpen) {
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
  } else {
    ___arguments.context.pageData['curfewError'] = undefined;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = true;
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6fe785593caa9b0a73bca0ea1299af38/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'))));
  } else {
    return 'Choose time';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['color'] = 'rgba(0, 0, 0, 0.42)';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c3fad6decdc47b4addd294a74e311b3d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c3fad6decdc47b4addd294a74e311b3d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c3fad6decdc47b4addd294a74e311b3d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/bf2b99c416bb91b4077cd069c16e95af/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf2b99c416bb91b4077cd069c16e95af', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf2b99c416bb91b4077cd069c16e95af', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/2dcb13aa13646494796660311f8979ac/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2dcb13aa13646494796660311f8979ac', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2dcb13aa13646494796660311f8979ac', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8e9e84e74ad7b83e48d2c471e217e949/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 13;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 13;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 13) {
    addItemToList(classesList, 'time_box_selected');
  } else if (!(getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 13) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7c447e11afe91c5b2e52f24818411c64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 14;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 14;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 14 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 14) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6c6abc374b6362b33623327390572078/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 15;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 15;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 15 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 15) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1806adcf917f547ca1c46c49c7c9614a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 16;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 16;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 16 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 16) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3edf62fb97a71ff5dcbf002baf65db17/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 17;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 17;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 17 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 17) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c4dae3a2ff25c88517d302072a3631fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 18;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 18;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 18 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 18) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6cd8934e34f2ead89b03e913ae13ef98/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 19;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 19;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 19 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 19) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/0916733359a30cb25efc8965acf460d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 20;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 20;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 20 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 20) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f0e5d3f46cbd94c5d1518a7cc6b446c8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 21;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 21;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 21 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 21) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/ead3cd0fa1e286cb6ba6a807f98d677b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 22;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 22;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 22 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 22) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d0f561917f28e2e86d11c9b733947f4c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 23;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 23;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 23 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 23) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d146d775a3e8a0952d6b209276b55289/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 12;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 12;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 12 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 12) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/febd27ca9f31805c19ddc625817b374c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 1;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 1 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 1) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c111416f22266b08b44f8f8b08489014/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 2;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 2;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 2 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 2) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f9e56961271f7e3b75f42be6b7068c07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 3;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 3;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 3 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 3) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/732fb4bee82a35867bb1b41eb0c40ee4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 4;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 4;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 4 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 4) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7b53dcd6698dbbda576af97b684ba79c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 5;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 5;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 5 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 5) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c61fc47a4fe30bec9323d2ad6f9e1c07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 6;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 6;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 6 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 6) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/235a3d71fb5ca8c24c5186d81da7dbbe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 7;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 7;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 7 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 7) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/923277c3e2b89f65e344a6b6f138b9c3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 8;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 8;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 8 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 8) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8e85ca3b3507c43555636ebb16ff7b7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 9;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 9;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 9 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 9) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/61c3efefcfcbf1d8a61ca946b06c2b6b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 10;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 10;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 10 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 10) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/af8424833cfc733e605e8146627359e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 11;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 11;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 11 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 11) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1e84eb3720ea0fbc4ce87b8b197f81ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 24;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 24;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 24 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 24) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/5c8a61741168b2f9bc26186222c29842/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c8a61741168b2f9bc26186222c29842', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c8a61741168b2f9bc26186222c29842', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewError')) == 'range') {
    return 'The start time can not be higher than the end time';
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewError')) == 'duplicate') {
    return 'The start time and end time can not be the same';
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewError')) == 'empty') {
    return 'You have to select a start and end time';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/a005a2cb0ee823f17965563884c9aadb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isDayDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isDayDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen'));
  if (isDayDrawerOpen) {
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['isDayDrawerOpen'] = true;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/b1b4ccf60df445ccfeccd0a81d511ad4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).length == 0) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['font-weight'] = 'normal';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['color'] = 'rgba(0, 0, 0, 0.42)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['font-weight'] = 'bold';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['color'] = '#000000';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var numberString, dayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (dayArray) {
    if (dayArray.length == 0) {
      return 'Choose days';
    } else if (dayArray.length == 1) {
      return (dayArray[0]);
    } else if (dayArray.length == 2) {
      return ([dayArray[0],' and ',dayArray[1]].join(''));
    } else if (dayArray.length == 3) {
      return ([dayArray[0],', ',dayArray[1],' and ',dayArray[2]].join(''));
    } else {
      if (dayArray.length == 4) {
        numberString = 'Four';
      } else if (dayArray.length == 5) {
        numberString = 'Five';
      } else if (dayArray.length == 6) {
        numberString = 'Six';
      } else if (dayArray.length == 7) {
        return 'Repeat every day';
      }
      if (numberString) {
        return (String(numberString) + String(' days out of the week'));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/4d52b8bafefd874e49b547b61543dadb/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d52b8bafefd874e49b547b61543dadb', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d52b8bafefd874e49b547b61543dadb', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6b04a05a2cb5e7a31fe6a87ea3a81b79/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b04a05a2cb5e7a31fe6a87ea3a81b79', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b04a05a2cb5e7a31fe6a87ea3a81b79', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8cfe1df18433c64961e6b877f63dde72/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8cfe1df18433c64961e6b877f63dde72', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8cfe1df18433c64961e6b877f63dde72', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/da293e9ca21cb67a40e563e5f5f4f5cb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Monday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Monday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Monday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/bb0d0589a973d62c3a36272f759e38aa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Tuesday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Tuesday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Tuesday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/851796f56d3948aa8b2890cd4fc6856d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Wednesday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Wednesday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Wednesday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7fc3420c7411a8ea53c3c68a593ea72b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Thursday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Thursday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Thursday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c6e3d407326f0162770ebcc5276c2603/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Friday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Friday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Friday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d8f78ffd30776559527ef826ee3592eb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Saturday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Saturday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Saturday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3203652234256dd50bfd5b8964f62e41/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Sunday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Sunday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Sunday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/615a5c894e34288b3b660443b18b8584/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pillarBar, currentUser, curfewTableResponse, timeDrawerTwoTime, timeDrawerOneTime, curfewDayArray, item, curfewName, curfewPillarArray, pillarRadio, selectedItemCurfew, curfewStep, toast, isLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function addToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
}


  selectedItemCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew'));
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  curfewName = (getObjectProperty(___arguments.context.pageData, 'curfewName'));
  curfewStep = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  timeDrawerOneTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'));
  timeDrawerTwoTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'));
  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  pillarBar = (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'));
  pillarRadio = (getObjectProperty(___arguments.context.pageData, 'pillarRadio'));
  if (curfewStep == 1 || curfewStep == 3) {
    if (!curfewName || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](curfewName))) {
      ___arguments.context.pageData['curfewNameError'] = true;
      return ;
    } else if (!curfewDayArray || curfewDayArray.length == 0) {
      ___arguments.context.pageData['curfewDayError'] = true;
      return ;
    }
    ___arguments.context.pageData['curfewStep'] = 2;
  } else if (curfewStep == 2) {
    await isButtonLoading(true);
    if (curfewPillarArray.length == 0) {
      ___arguments.context.pageData['curfewPillarError'] = true;
      return ;
    }
    try {
      if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
        curfewTableResponse = (await Backendless.Data.of('Curfews').save( ({ [`objectId`]: (getObjectProperty(selectedItemCurfew, 'objectId')),[`name`]: curfewName,[`pillar`]: pillarRadio,[`days`]: (JSON.stringify(({ [`dayArray`]: curfewDayArray }))),[`times`]: (JSON.stringify(({ [`startTime`]: timeDrawerOneTime,[`endTime`]: timeDrawerTwoTime }))) }) ));
        await Backendless.Data.of('Curfews').addRelation(curfewTableResponse, 'organizationId', [(getObjectProperty(currentUser, 'organizationId'))]);
      } else if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
        for (var item_index in curfewPillarArray) {
          item = curfewPillarArray[item_index];
          curfewTableResponse = (await Backendless.Data.of('Curfews').save( ({ [`name`]: curfewName,[`status`]: 'DEACTIVATED',[`pillar`]: item,[`days`]: (JSON.stringify(({ [`dayArray`]: curfewDayArray }))),[`times`]: (JSON.stringify(({ [`startTime`]: timeDrawerOneTime,[`endTime`]: timeDrawerTwoTime }))) }) ));
          await Backendless.Data.of('Curfews').addRelation(curfewTableResponse, 'organizationId', [(getObjectProperty(currentUser, 'organizationId'))]);
        }
      }
      await BackendlessUI.Functions.Custom['fn_bf5f02f26768af5f26df44a3c143b235']((getObjectProperty(currentUser, 'organizationId.orgName')), pillarBar, ___arguments.context.pageData);
      ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: pillarBar });
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      ___arguments.context.pageData['isViewDetailsModalOpen'] = false;
      ___arguments.context.pageData['timeDrawerOneTime'] = undefined;
      ___arguments.context.pageData['timeDrawerTwoTime'] = undefined;
      ___arguments.context.pageData['hasNoCurfews'] = false;
      ___arguments.context.pageData['curfewName'] = undefined;
      ___arguments.context.pageData['dayArray'] = undefined;
      ___arguments.context.pageData['curfewPillarArray'] = [];
      await isButtonLoading(false);

    } catch (error) {
      await addToast(({ [`status`]: 'failure',[`message`]: 'Error adding curfew' }));
      await isButtonLoading(false);

    }
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var curfewModalSteps;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  curfewModalSteps = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  if (getObjectProperty(___arguments.context.pageData, 'isButtonLoading')) {
    return 'Please wait...';
  } else if (curfewModalSteps == 1 || curfewModalSteps == 3) {
    return 'Continue';
  } else if (curfewModalSteps == 2) {
    return 'Save';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, isDisabled, isButtonLoading, curfewPillarArray, pillarRadio, curfewStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'isAddCufewButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, isButtonLoading, curfewDayArray, curfewName, curfewStep, curfewPillarArray, timeDrawerOneTime, timeDrawerTwoTime, pillarRadio, pillarBar, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pillarRadio = (getObjectProperty(___arguments.context.pageData, 'pillarRadio'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  curfewStep = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  curfewName = (getObjectProperty(___arguments.context.pageData, 'curfewName'));
  timeDrawerOneTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'));
  timeDrawerTwoTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'));
  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  pillarBar = (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'));
  pillarRadio = (getObjectProperty(___arguments.context.pageData, 'pillarRadio'));
  isDisabled = false;
  if (curfewStep == '1') {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](curfewName)) || !curfewName) {
      isDisabled = true;
    } else if (timeDrawerTwoTime != 0 && !timeDrawerTwoTime) {
      isDisabled = true;
    } else if (timeDrawerOneTime != 0 && !timeDrawerOneTime) {
      isDisabled = true;
    } else if (curfewDayArray && curfewDayArray.length == 0) {
      isDisabled = true;
    }
  } else if (curfewStep == '2') {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
      if (curfewPillarArray && curfewPillarArray.length == 0) {
        isDisabled = true;
      }
    }
  } else if (curfewStep == '3') {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](curfewName)) || !curfewName) {
      isDisabled = true;
    } else if (curfewDayArray && curfewDayArray.length == 0) {
      isDisabled = true;
    }
  }
  if (isButtonLoading) {
    isDisabled = true;
  }
  ___arguments.context.pageData['isAddCufewButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/26c2557f7ea4f30b4bdfb5bde57b9287/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['dayArray'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/cc986fc858ad1b39f41ec7ff23490229/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 1 || (getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 3) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc986fc858ad1b39f41ec7ff23490229', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc986fc858ad1b39f41ec7ff23490229', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/15b7d3d4a9e88a8690162414a309d07d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('15b7d3d4a9e88a8690162414a309d07d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('15b7d3d4a9e88a8690162414a309d07d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1fa16df9332f248cd473948fc43dbab6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['curfewNameError'] = false;
  ___arguments.context.pageData['curfewName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewNameError')) {
    classes = ['input_red_border'];
  } else if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'curfewName')))) || !(getObjectProperty(___arguments.context.pageData, 'curfewName'))) {
    classes = [];
  } else {
    classes = ['input_bold_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3745dde80d1c1be72a6d9d39293506b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var recordTableVarient;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVarient = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  ___arguments.context.pageData['confirmationOption'] = 'cancel';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/0985f8ba5a888fbaaeb764b46156496e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['curfewStep'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c8afc95d2559bd0793b694016abc2b7e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
      return (String('Assign ') + String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
      return (['Assign ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/a271eec9691ad22c3aebeb45d70ccb8b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
      return (String('This curfew will be added to the above selected ') + String((getObjectProperty(___arguments.context.appData, 'pillarApplicationName')).toLowerCase()));
    } else if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
      return (['This curfew will be added to the above selected ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')).toLowerCase(),'s'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8298047c92d2d89c81ca1a28379bad23/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bec4b36a73b7ec2c43b3df7ce78548af'), 'index'))) {
    classesList = ['time_box_selected'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bec4b36a73b7ec2c43b3df7ce78548af'), 'index')) >= (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'))) {
    classesList = ['time_box_display_none'];
  } else {
    classesList = ['time_box'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bec4b36a73b7ec2c43b3df7ce78548af'), 'index'))) {
    ___arguments.context.pageData['timeDrawerOneTime'] = undefined;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bec4b36a73b7ec2c43b3df7ce78548af'), 'index'));
  }

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bec4b36a73b7ec2c43b3df7ce78548af'), 'index')) == 0) {
    return '12:00 AM';
  } else {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bec4b36a73b7ec2c43b3df7ce78548af'), 'index'))));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f5a6c11c558393ebf4e3937486de30d1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'))));
  } else if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 0) {
    return '12:00 AM';
  } else {
    return 'Choose time';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) {
    classes = ['selected_box_text'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7d91acd446f84a9a094a7d238594e425/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7d91acd446f84a9a094a7d238594e425', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7d91acd446f84a9a094a7d238594e425', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/e3cfd83cd204ef42c13d5833a82f6da1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e3cfd83cd204ef42c13d5833a82f6da1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e3cfd83cd204ef42c13d5833a82f6da1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7c4e5dd6442a19845be922657c1b480f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isTimeDrawerOneOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTimeDrawerOneOpen = (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen'));
  if (isTimeDrawerOneOpen) {
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  } else {
    ___arguments.context.pageData['curfewError'] = undefined;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/5d86516dbc98f88704cd9be1dba4e74d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d86516dbc98f88704cd9be1dba4e74d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d86516dbc98f88704cd9be1dba4e74d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/afea5f3223d6b46b50cbccb14cbc5f31/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) {
    classes = ['selected_box_text'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'))));
  } else if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 0) {
    return '12:00 AM';
  } else {
    return 'Choose time';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/442b7aecabfffabc3742e448e01eb358/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('442b7aecabfffabc3742e448e01eb358', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('442b7aecabfffabc3742e448e01eb358', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/dc0680215863a4e46edcdd2be636fb32/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc0680215863a4e46edcdd2be636fb32', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc0680215863a4e46edcdd2be636fb32', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/43063edaa80ab7168e19aec90c6c4b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isTimeDrawerTwoOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTimeDrawerTwoOpen = (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen'));
  if (isTimeDrawerTwoOpen) {
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
  } else {
    ___arguments.context.pageData['curfewError'] = undefined;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = true;
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f9b3fe1e9b3294f95dbef42fd0843186/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0967f9726f531d125feab8f1726ed148'), 'index'))) {
    classesList = ['time_box_selected'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0967f9726f531d125feab8f1726ed148'), 'index')) <= (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'))) {
    classesList = ['time_box_display_none'];
  } else {
    classesList = ['time_box'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0967f9726f531d125feab8f1726ed148'), 'index'))) {
    ___arguments.context.pageData['timeDrawerTwoTime'] = undefined;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0967f9726f531d125feab8f1726ed148'), 'index'));
  }

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0967f9726f531d125feab8f1726ed148'), 'index')) == 0) {
    return '12:00 A.M';
  } else {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0967f9726f531d125feab8f1726ed148'), 'index'))));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/4ef1264ee9453295e5a3f00b30973a73/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4ef1264ee9453295e5a3f00b30973a73', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4ef1264ee9453295e5a3f00b30973a73', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d1a4bd2b0d136b29ae69190a9cf4e2d8/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return [({ [`index`]: 0 }), ({ [`index`]: 1 }), ({ [`index`]: 2 }), ({ [`index`]: 3 }), ({ [`index`]: 4 }), ({ [`index`]: 5 }), ({ [`index`]: 6 }), ({ [`index`]: 7 }), ({ [`index`]: 8 }), ({ [`index`]: 9 }), ({ [`index`]: 10 }), ({ [`index`]: 11 }), ({ [`index`]: 12 }), ({ [`index`]: 13 }), ({ [`index`]: 14 }), ({ [`index`]: 15 }), ({ [`index`]: 16 }), ({ [`index`]: 17 }), ({ [`index`]: 18 }), ({ [`index`]: 19 }), ({ [`index`]: 20 }), ({ [`index`]: 21 }), ({ [`index`]: 22 }), ({ [`index`]: 23 }), ({ [`index`]: 24 })]

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d4fc97618a034a2f5effb8b5d2e5edfb/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return [({ [`index`]: 0 }), ({ [`index`]: 1 }), ({ [`index`]: 2 }), ({ [`index`]: 3 }), ({ [`index`]: 4 }), ({ [`index`]: 5 }), ({ [`index`]: 6 }), ({ [`index`]: 7 }), ({ [`index`]: 8 }), ({ [`index`]: 9 }), ({ [`index`]: 10 }), ({ [`index`]: 11 }), ({ [`index`]: 12 }), ({ [`index`]: 13 }), ({ [`index`]: 14 }), ({ [`index`]: 15 }), ({ [`index`]: 16 }), ({ [`index`]: 17 }), ({ [`index`]: 18 }), ({ [`index`]: 19 }), ({ [`index`]: 20 }), ({ [`index`]: 21 }), ({ [`index`]: 22 }), ({ [`index`]: 23 }), ({ [`index`]: 24 })]

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/88f49973b02da1e3d5c30d7eb0d61cb4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '1'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '1', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('1')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88f49973b02da1e3d5c30d7eb0d61cb4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88f49973b02da1e3d5c30d7eb0d61cb4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/763da95877947fb7faebaac61ea4f627/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isAddRecordModalOpen')) && (getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')), null, null, null, '1')))));
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      if (tableCount == 1) {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),')'].join(''));
      } else {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),'s)'].join(''));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/f1ffc60c3278408c88a247e36c5549f7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '2'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '2', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('2')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f1ffc60c3278408c88a247e36c5549f7', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f1ffc60c3278408c88a247e36c5549f7', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/5a9185ab4e06b45eb6bb0485103a4b43/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isAddRecordModalOpen')) && (getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')), null, null, null, '2')))));
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      if (tableCount == 1) {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),')'].join(''));
      } else {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),'s)'].join(''));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/f2fc2914a33bf1f693ef533c353c25d5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f2fc2914a33bf1f693ef533c353c25d5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f2fc2914a33bf1f693ef533c353c25d5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '3'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '3', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('3')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/ce873cfd05d3c92e314d90cfc8a61fc1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isAddRecordModalOpen')) && (getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')), null, null, null, '3')))));
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      if (tableCount == 1) {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),')'].join(''));
      } else {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),'s)'].join(''));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/fc09b39e9b76ce86a177ac62bff8c18d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '4'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '4', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('4')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc09b39e9b76ce86a177ac62bff8c18d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc09b39e9b76ce86a177ac62bff8c18d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/b16a87abb9175b7aba67766e682209df/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isAddRecordModalOpen')) && (getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.orgName')), null, null, null, '4')))));
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      if (tableCount == 1) {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),')'].join(''));
      } else {
        return (['(',tableCount,' ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),'s)'].join(''));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/58a2e7c3f1dcf71b35ed50810ab2f314/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewPillarError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('58a2e7c3f1dcf71b35ed50810ab2f314', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('58a2e7c3f1dcf71b35ed50810ab2f314', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String('Please select a ') + String((getObjectProperty(___arguments.context.appData, 'pillarApplicationName')).toLowerCase()));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/b792ec61b4630b1d032d131a03bef374/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b792ec61b4630b1d032d131a03bef374', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b792ec61b4630b1d032d131a03bef374', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/847e21b17e11e55cc18a454307148e05/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('847e21b17e11e55cc18a454307148e05', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('847e21b17e11e55cc18a454307148e05', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/8a58295fe0e70a674f02d696bf667e2b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a58295fe0e70a674f02d696bf667e2b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a58295fe0e70a674f02d696bf667e2b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/ff465afc2a3ec69affb2ca6da49dfbf1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff465afc2a3ec69affb2ca6da49dfbf1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff465afc2a3ec69affb2ca6da49dfbf1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/cd24bb1b20d45a2e804861f89d3a0c47/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
      return (['Select the ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')).toLowerCase(),' for curfew'].join(''));
    } else {
      return (['Select the ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')).toLowerCase(),'s for curfew (multiple selection)'].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/0947c717ce0a84d2d3ea035fed9c161f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 1'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/72da2a225d3ff6531dbed5e88854703a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 2'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/930c919fc5c300484047f829aed3f0fc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 3'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/78f8529c981f1d9741c5ef8cacc9b259/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 4'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/dabd70836ff8be2ef60d21f6f9bc1ab2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isViewDetailsModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/9a9ed92d165a41b4717cef9f482b4e67/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isViewDetailsModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/45890ff5024aa90a9bf5f05ae642997d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'ACTIVE') {
    classesList = ['active_status'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/ba2841941531d1a176c75bc17efba326/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/625795c1b9431efdc88e7dab60760e4a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var dayText, endTime, startTime, item, dayArray, timeObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew')) {
    timeObject = (JSON.parse((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.times'))));
    startTime = (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(timeObject, 'startTime'))));
    endTime = (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(timeObject, 'endTime'))));
    dayArray = (getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.days')))), 'dayArray'));
    if (startTime && endTime && dayArray) {
      if (dayArray.length == 8) {
        dayText = 'Repeat every day';
      } else {
        dayText = (await Promise.all(dayArray.map(async item => {  if (item == 'Monday') {
            return 'Mon';
          } else if (item == 'Tuesday') {
            return 'Tues';
          } else if (item == 'Wednesday') {
            return 'Wed';
          } else if (item == 'Thursday') {
            return 'Thurs';
          } else if (item == 'Friday') {
            return 'Fri';
          } else if (item == 'Saturday') {
            return 'Sat';
          } else if (item == 'Sunday') {
            return 'Sun';
          }
        ; return ;}))).join(',');
      }
      return ([String(startTime.split(':')[0]) + String(startTime.slice(-2, startTime.length).toLowerCase()),' to ',String(endTime.split(':')[0]) + String(endTime.slice(-2, endTime.length).toLowerCase()),' | ',dayText].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/31d36443f053291801b569f5ad4f84df/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isCurfewRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/2ba409865bb70398168cc512215853dc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isCurfewRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ba409865bb70398168cc512215853dc', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ba409865bb70398168cc512215853dc', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/63eba172bc4f9d3a0038e9615493d7bb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'ACTIVE') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/7b71516efc61abee43f837f3df852747/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemCurfew, classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew'));
  if (selectedItemCurfew) {
    if ((getObjectProperty(selectedItemCurfew, 'status')) == 'ACTIVE') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateCurfew';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/e802238a1cb5572faf57cf9d862e75d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'ACTIVE') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/d36985bb9a86cbf9750695a16dde2918/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'DEACTIVATED') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/fa8fab01a7a6595e47818d75cea2050e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'DEACTIVATED') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/53e1f7ccb923a4f4e92443b60ecc73f3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemCurfew, classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew'));
  if (selectedItemCurfew) {
    if ((getObjectProperty(selectedItemCurfew, 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateCurfew';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/3d33afbfe4498a52abf82cb27d2bf501/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'DEACTIVATED') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/2261965ad571f38b68cc2f16d0c1905b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew.status')) == 'DEACTIVATED') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/aa2c9db5298b91ea5929d58794edffeb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemCurfew, classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew'));
  if (selectedItemCurfew) {
    if ((getObjectProperty(selectedItemCurfew, 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deleteCurfew';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/005909c8d9de07929617580f1e07d4fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItem, times, dayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['curfewStep'] = 1;
  ___arguments.context.pageData['curfewNameError'] = undefined;
  selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemCurfew'));
  times = (JSON.parse((getObjectProperty(selectedItem, 'times'))));
  dayArray = (getObjectProperty((JSON.parse((getObjectProperty(selectedItem, 'days')))), 'dayArray'));
  ___arguments.context.pageData['curfewDayArray'] = dayArray;
  ___arguments.context.pageData['pillarRadio'] = (getObjectProperty(selectedItem, 'pillar'));
  ___arguments.context.pageData['timeDrawerOneTime'] = (getObjectProperty(times, 'startTime'));
  ___arguments.context.pageData['timeDrawerTwoTime'] = (getObjectProperty(times, 'endTime'));
  ___arguments.context.pageData['curfewName'] = (getObjectProperty(selectedItem, 'name'));
  ___arguments.context.pageData['curfewObjectId'] = (getObjectProperty(selectedItem, 'objectId'));
  ___arguments.context.pageData['curfewPillarArray'] = [(getObjectProperty(selectedItem, 'pillar'))];
  ___arguments.context.pageData['recordTableVariant'] = 'curfew';
  ___arguments.context.pageData['curfewTableVariant'] = 'edit';
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/b979e0125a53df62e78b5794ff6e4938/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isLogoutModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/23f8c4f7554ff5739842fb5427bf709a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isLogoutModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/a837274ec1e454dbc9553887260483c8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/8a383eafc76c58e9e52f65da8fcb4cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isLogoutModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/c02b109a522e843971e3b0b48e5c0b3b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c02b109a522e843971e3b0b48e5c0b3b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c02b109a522e843971e3b0b48e5c0b3b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2aa7c243ea2aea54cc725292f72b36da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordSelection')) == 'deviceRecords') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Device', undefined);
  } else {
    ___arguments.context.pageData['isViewDetailsOpen'] = false;
  }

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device' || (getObjectProperty(___arguments.context.pageData, 'recordSelection')) == 'deviceRecords') {
    return 'Device Records ';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
      return 'User Records ';
    } else {
      return 'Member Records ';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    return 'Organization Records ';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Records '));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a257f8ca1346db0fe77f8f468f581099/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')), 26));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')), 26));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name')), 26));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemDevice.deviceId')) {
      return (getObjectProperty(___arguments.context.pageData, 'selectedItemDevice.deviceId'));
    } else {
      return 'N/A';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cecdf3a6d2031853efb35e543d9f512d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2afbf387b8ab72629f99c5d740180f43/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
      return 'User Details';
    } else {
      return 'Member Details';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    return 'Profile Details';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    return 'Organization Details';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    return 'Device Details';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a58958886a62f291347d8b07479e1cbe/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details') {
    classes = ['selected_box_bar'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/db79eae928d5145486922629f4d34bc8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var response, item, listOfApplications, application, listOfSystemApps;


  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'apps';
  ___arguments.context.pageData['hasClickedInstalledApps'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db79eae928d5145486922629f4d34bc8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db79eae928d5145486922629f4d34bc8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cb3293dbf573c615b434a9eff738412b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'apps') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d8af13878de963fddd44dcc87cb6260d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'apps') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f8278ecbafd8a7b83b83757d86e95248/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDetailsSelection'] = 'contacts';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f8278ecbafd8a7b83b83757d86e95248', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f8278ecbafd8a7b83b83757d86e95248', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ab84b24474d41c960627fac6fa1f0d06/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/04b30aeadb041faf6302304d6c9ce28f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cb6bb3f8c29404cc7fd67abc8a9319ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isViewResidentActionDrawerOpen')) {
    ___arguments.context.pageData['isViewResidentActionDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['isViewResidentActionDrawerOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f25232312d9b217ff9fe2d7e59a69369/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isViewResidentActionDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25232312d9b217ff9fe2d7e59a69369', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25232312d9b217ff9fe2d7e59a69369', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2b836bdfa9ee118b9ddabedef0413d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    await BackendlessUI.Functions.Custom['fn_d48a6d3a366b0ef43b6e0b4bab07fa46'](___arguments.context.pageData);
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['residentPillarRadio'] = 1;
    ___arguments.context.pageData['hasStoppedPolling'] = undefined;
    ___arguments.context.pageData['hasAcknowledged'] = undefined;
    ___arguments.context.pageData['hasOnboardStarted'] = undefined;
    ___arguments.context.pageData['residentIntakeStep'] = undefined;
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'pillar';
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2b836bdfa9ee118b9ddabedef0413d77', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2b836bdfa9ee118b9ddabedef0413d77', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/af6a42f350dc57f984084e2d7576370e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/14e5c797e35e075b31d167edb2f20af2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, isSame, item, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/86eeaea48d42510afcbcb3173b8df062/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    ___arguments.context.pageData['residentPillarRadio'] = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.pillar'));
    ___arguments.context.pageData['residentIntakeVariant'] = 'updatePillar';
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
    ___arguments.context.pageData['scrollToTop'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86eeaea48d42510afcbcb3173b8df062', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86eeaea48d42510afcbcb3173b8df062', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/003f3859578b90890d14f9cc6bf3fcef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1a71f4b615bbff59eed31b7f2ccf3758/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String('Update ') + String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4987e84dd62ce01d5add90b8ddbc74d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['confirmationOption'] = 'activateResident';
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateOrganization';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'RESENT' && (getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateUser';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User' || (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident' || (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4987e84dd62ce01d5add90b8ddbc74d3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4987e84dd62ce01d5add90b8ddbc74d3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1d080da8c5168c1e61b1eeeb48042a84/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'RESENT' && (getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/754160d8d2b407ddc52dc5180dadedee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'RESENT' && (getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/efa8322e3f121b46f3962bafe886312b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.status')) == 'ACTIVE' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceId'))) {
      ___arguments.context.pageData['confirmationOption'] = 'deactivateResident';
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
    } else {
      ___arguments.context.pageData['confirmationOption'] = 'deactivateFirst';
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'ACTIVE') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateOrganization';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if (selectedItemUser) {
      if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'ACTIVE') {
        ___arguments.context.pageData['isConfirmationModalOpen'] = true;
        ___arguments.context.pageData['confirmationOption'] = 'deactivateUser';
      }
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User' || (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident' || (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('efa8322e3f121b46f3962bafe886312b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('efa8322e3f121b46f3962bafe886312b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/41d8517f46f740760c37a39e7f927bdb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'ACTIVE') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cd1fd7297133700654290fd21035bb1b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/9c2c3bde92c9372bd3e2512cbd1f0761/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts' && (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9c2c3bde92c9372bd3e2512cbd1f0761', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9c2c3bde92c9372bd3e2512cbd1f0761', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length < (getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))))) || !(getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')))))) {
    ___arguments.context.pageData['setAdminContacts'] = true;
    ___arguments.context.pageData['setResidentContacts'] = true;
    ___arguments.context.pageData['isAdminContactsLocked'] = true;
    ___arguments.context.pageData['addingContact'] = true;
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'updateContacts';
    ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
    ___arguments.context.pageData['residentPillarRadio'] = (Number((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))));
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2ff6e9441ae93c5b64acca493577dacc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length >= (getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')))))) {
    classes = ['inactive_action', 'actionText'];
  } else {
    classes = ['active_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ebcd0551782ca476d472125ce01937d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length >= (getObjectProperty(___arguments.context.appData, (String('contactAmounts.') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')))))) {
    classes = ['inactive_action', 'actionText'];
  } else {
    classes = ['active_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/7fe63ab99b91d1967bd559bf76f46fab/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts' && (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7fe63ab99b91d1967bd559bf76f46fab', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7fe63ab99b91d1967bd559bf76f46fab', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) && !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedItemContact.index')) === 'undefined')) {
    ___arguments.context.pageData['confirmationOption'] = 'deleteContact';
    ___arguments.context.pageData['isConfirmationModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/46bb1b287589cbc5c41e02735ac372b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) && !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedItemContact.index')) === 'undefined')) {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2fbd93f21972ac1de4a518adf8bd3e73/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) && !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedItemContact.index')) === 'undefined')) {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e9f7755bacf48d7ad0acba5716dc33ef/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9f7755bacf48d7ad0acba5716dc33ef', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9f7755bacf48d7ad0acba5716dc33ef', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/41d81c0d14da76a1a73ca942707328a1/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture')) {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo')) {
      return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.profilePicture')) {
      return (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.profilePicture'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f61122667deb6947b1a80822dbf897b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse'));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')) {
      return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName'));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name')) {
      return (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name'));
    } else {
      return 'N/A';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/c32c6bd2f9396a44a9c26a4346199fb9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.created')))).toLocaleDateString());
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.created')))).toLocaleDateString());
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.created')))).toLocaleDateString());
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/aabca0575dd9a71bebc95a477a275019/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) && (getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))) {
    return ([(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4c76ed87006fa9c7590c4cff779242ed/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var pillarDays;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillarStart')) {
    pillarDays = (await BackendlessUI.Functions.Custom['fn_7e3d62cb9ecfca7bbe1d85a6a421c66f']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillarStart'))));
    if (pillarDays == 0 || pillarDays == 1) {
      return '1 Day';
    } else {
      return (String(pillarDays) + String(' Days'));
    }
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a41dfae89119cf79e748a392c4c9d497/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d038ddb13553e364090b093a8087e44f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')) {
    return (await BackendlessUI.Functions.Custom['fn_3c26f63ed44ba06a52e69b84db7a8b7b']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob'))));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/084b889b21e8d07cfed28de8a94bd3de/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/57333d6c3f525a050c424d7a12ace7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/623991a854c50ccf08c1cc5aedd8d61e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber')) {
    return (String('+1') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber')));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/74361306029d47988564ecd1862b6456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/9bbb49415a99c5ed7b8bd57d1b8bcdfb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ac08373ccfbec129dd44f805602f9863/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/8c7cc63d150a6f0c21251cedb5686883/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedItemOrganization, currentPage, selectedItemUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'Resident') {
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['residentIntakeStep'] = 4;
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  } else if (currentPage == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
    if (selectedItemUser) {
      ___arguments.context.pageData['selectedItemUserImageFile'] = undefined;
      ___arguments.context.pageData['userUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemUser))));
      (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.phoneNumber')), 2)), 'create'));
      if ((getObjectProperty(selectedItemUser, 'userRole')) == 'admin') {
        ___arguments.context.pageData['selectedItemUserRole'] = 'Administrator';
      } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
        ___arguments.context.pageData['selectedItemUserRole'] = 'Case Manager';
      }
      ___arguments.context.pageData['recordTableVariant'] = 'editUser';
      ___arguments.context.pageData['emailError'] = false;
      ___arguments.context.pageData['isButtonLoading'] = false;
      ___arguments.context.pageData['phoneError'] = false;
      ___arguments.context.pageData['isUserButtonDisabledObject'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`selectedImage`]: true,[`organization`]: true });
    }
  } else if (currentPage == 'Organization') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if (selectedItemOrganization) {
      ___arguments.context.pageData['organizationUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemOrganization))));
      (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')), 2)), 'create'));
      ___arguments.context.pageData['isLoadingOrganizationButton'] = undefined;
      ___arguments.context.pageData['addOrganizationStep'] = 1;
      ___arguments.context.pageData['recordTableVariant'] = 'editOrganization';
      ___arguments.context.pageData['isEditOrgButtonDisabled'] = ({ [`orgName`]: true,[`address`]: true,[`website`]: true,[`taxId`]: true,[`billingName`]: true,[`billingPhone`]: true,[`billingEmail`]: true,[`state`]: true,[`zipCode`]: true,[`city`]: true,[`selectedImage`]: true });
    }
  }
  ___arguments.context.pageData['scrollToTop'] = true;
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8c7cc63d150a6f0c21251cedb5686883', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8c7cc63d150a6f0c21251cedb5686883', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/27e9d80471e22c3bedae5a7d1eaaf2cd/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'locationError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e9d80471e22c3bedae5a7d1eaaf2cd', true);
  } else if ((getObjectProperty(___arguments.context.pageData, 'isMapLoading')) || (getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation')) || (getObjectProperty(___arguments.context.pageData, 'selectedItemDeviceIMEI'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e9d80471e22c3bedae5a7d1eaaf2cd', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2884b49db05bd55c202697565be430d4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isMapLoading')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2884b49db05bd55c202697565be430d4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2884b49db05bd55c202697565be430d4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/5732a1f22f9b1ec1879e7b7c03f11914/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var uninstalledApps, preInstalledApps, listOfApplications, applicationArray, application, response, pillar;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getApplications(applicationArray, pillar) {
  for (var application_index in applicationArray) {
    application = applicationArray[application_index];
    if (!(getObjectProperty(application, 'appIcon'))) {
      response = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleApplicationDetails`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(application, 'packageName')))));
      if (response) {
        application['appIcon'] = (getObjectProperty(response, 'AppIcon'));
        application['applicationName'] = (getObjectProperty(response, 'ApplicationName'));
        application['appCategory'] = (getObjectProperty(response, 'category'));
        if (getObjectProperty(application, 'isInstalled')) {
          addItemToList(preInstalledApps, application);
        } else {
          addItemToList(uninstalledApps, application);
        }
      }
    } else {
      if (getObjectProperty(application, 'isInstalled')) {
        addItemToList(preInstalledApps, application);
      } else {
        addItemToList(uninstalledApps, application);
      }
    }
  }
  ___arguments.context.pageData['applicationArray'] = applicationArray;
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'apps') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5732a1f22f9b1ec1879e7b7c03f11914', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5732a1f22f9b1ec1879e7b7c03f11914', false);
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI')) && (getObjectProperty(___arguments.context.pageData, 'hasClickedInstalledApps'))) {
    ___arguments.context.pageData['hasClickedInstalledApps'] = undefined;
    ___arguments.context.pageData['isLoadingPreinstalledApps'] = true;
    ___arguments.context.pageData['preinstalledAppsError'] = false;
    ___arguments.context.pageData['installedAppsError'] = false;
    listOfApplications = (JSON.parse((getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.preinstalledApps'))));
    try {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) != 4) {
        preInstalledApps = [];
        uninstalledApps = [];
        if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 1) {
          await getApplications((getObjectProperty(listOfApplications, 'pillar1')), 1);
          if ((getObjectProperty(listOfApplications, 'pillar1')) != (getObjectProperty(___arguments.context.pageData, 'applicationArray'))) {
            listOfApplications['pillar1'] = (getObjectProperty(___arguments.context.pageData, 'applicationArray'));
            await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.objectId')),[`preinstalledApps`]: (JSON.stringify(listOfApplications)) }) );
          }
        } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 2) {
          await getApplications((getObjectProperty(listOfApplications, 'pillar2')), 2);
          if ((getObjectProperty(listOfApplications, 'pillar2')) != (getObjectProperty(___arguments.context.pageData, 'applicationArray'))) {
            listOfApplications['pillar2'] = (getObjectProperty(___arguments.context.pageData, 'applicationArray'));
            await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.objectId')),[`preinstalledApps`]: (JSON.stringify(listOfApplications)) }) );
          }
        } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 3) {
          await getApplications((getObjectProperty(listOfApplications, 'pillar3')), 3);
          if ((getObjectProperty(listOfApplications, 'pillar3')) != (getObjectProperty(___arguments.context.pageData, 'applicationArray'))) {
            listOfApplications['pillar3'] = (getObjectProperty(___arguments.context.pageData, 'applicationArray'));
            await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.appData, 'currentUser.organizationId.objectId')),[`preinstalledApps`]: (JSON.stringify(listOfApplications)) }) );
          }
        }
        if (preInstalledApps.length == 0) {
          ___arguments.context.pageData['preinstalledAppsError'] = true;
        } else {
          (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('da05b65a5c76c363a5ce3b73a51d85a7', preInstalledApps);
        }
        if (uninstalledApps.length == 0) {
          ___arguments.context.pageData['uninstalledAppsError'] = true;
        } else {
          (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('212ff173c315b254f2934463dff4ed0e', uninstalledApps);
        }
        ___arguments.context.pageData['isLoadingPreinstalledApps'] = false;
      }

    } catch (error) {
      ___arguments.context.pageData['hasClickedInstalledApps'] = undefined;
      ___arguments.context.pageData['installedAppsError'] = true;
      ___arguments.context.pageData['preinstalledAppsError'] = true;
      ___arguments.context.pageData['isLoadingInstalledApps'] = false;
      ___arguments.context.pageData['isLoadingPreinstalledApps'] = false;

    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'today';

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1e3f58db2afd6ab147449b6e3e4d0fb3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentDateFilter')) == 'today') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1e3f58db2afd6ab147449b6e3e4d0fb3'))['color'] = '#3871FF';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1e3f58db2afd6ab147449b6e3e4d0fb3'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'today';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/0357e5ffcc7897da1fbeb0b784253da3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentDateFilter')) == 'week') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0357e5ffcc7897da1fbeb0b784253da3'))['color'] = '#3871FF';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0357e5ffcc7897da1fbeb0b784253da3'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'week';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6febdf1b8e2550758c183b219ca1a502/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentDateFilter')) == 'month') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6febdf1b8e2550758c183b219ca1a502'))['color'] = '#3871FF';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6febdf1b8e2550758c183b219ca1a502'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'month';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ec69ce74495a100a49224b64811d2ad8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ec69ce74495a100a49224b64811d2ad8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ec69ce74495a100a49224b64811d2ad8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4e19b48ed96b959f170677ba1da7bf9b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var response, item, listOfApplications, application, listOfSystemApps;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) != 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4e19b48ed96b959f170677ba1da7bf9b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4e19b48ed96b959f170677ba1da7bf9b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/da05b65a5c76c363a5ce3b73a51d85a7/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isLoadingPreinstalledApps')) || (getObjectProperty(___arguments.context.pageData, 'preinstalledAppsError'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('da05b65a5c76c363a5ce3b73a51d85a7', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('da05b65a5c76c363a5ce3b73a51d85a7', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6eee2875172c425e22a1d5d38691db6a/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c7a9230b7186f3120e270fba592c5259'), 'appIcon')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c7a9230b7186f3120e270fba592c5259'), 'appIcon'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/9fbc9cf8e2a5f02e39e428c599be537d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingPreinstalledApps')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9fbc9cf8e2a5f02e39e428c599be537d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9fbc9cf8e2a5f02e39e428c599be537d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/eaf66cf710130a0ada54e3fbe3ba92ff/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'preinstalledAppsError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eaf66cf710130a0ada54e3fbe3ba92ff', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eaf66cf710130a0ada54e3fbe3ba92ff', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/521f2e550186f0732367944fdad935e7/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('521f2e550186f0732367944fdad935e7', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('521f2e550186f0732367944fdad935e7', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/826d9ff5deeb64ff616b0cebe6b34fd1/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/b08a05891a12d62a7357e0a58e4b2475/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingUnistalledApps')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b08a05891a12d62a7357e0a58e4b2475', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b08a05891a12d62a7357e0a58e4b2475', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/bb1d72a3c53197ecede374efe9c3459e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'isLoadingUninstalledApps')) && ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('212ff173c315b254f2934463dff4ed0e')).length == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bb1d72a3c53197ecede374efe9c3459e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bb1d72a3c53197ecede374efe9c3459e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/26970fbb1d7261268672b2f3865c6353/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('26970fbb1d7261268672b2f3865c6353', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('26970fbb1d7261268672b2f3865c6353', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6e6f8f28c315d619ccddaf6921f51ea9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['residentPillarRadio'] = 1;
    ___arguments.context.pageData['hasStoppedPolling'] = undefined;
    ___arguments.context.pageData['hasAcknowledged'] = undefined;
    ___arguments.context.pageData['hasOnboardStarted'] = undefined;
    ___arguments.context.pageData['residentIntakeStep'] = undefined;
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'pillar';
    ___arguments.context.pageData['scrollToTop'] = true;
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/126d089c599dc89713ed2e78933d588c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('126d089c599dc89713ed2e78933d588c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('126d089c599dc89713ed2e78933d588c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/7135c491a10484395e5eac81db8bbd97/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7135c491a10484395e5eac81db8bbd97', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7135c491a10484395e5eac81db8bbd97', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/abb86f885e4cf8beb29a3392ecd1ff74/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('abb86f885e4cf8beb29a3392ecd1ff74', (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')));
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e2b1b0b65dbae0f752464f0b46debc9c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) == ___arguments.context.dataModel) {
    ___arguments.context.pageData['selectedItemContact'] = undefined;
  } else {
    ___arguments.context.pageData['selectedItemContact'] = ___arguments.context.dataModel;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/35160cf8b5f9b037e93bbc39f636e716/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) == ___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e66c507c3e815a0ef36157d9a4d27541/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['setAdminContacts'] = true;
  ___arguments.context.pageData['addingContact'] = true;
  ___arguments.context.pageData['setResidentContacts'] = true;
  ___arguments.context.pageData['isAdminContactsLocked'] = true;
  ___arguments.context.pageData['recordTableVariant'] = 'resident';
  ___arguments.context.pageData['residentIntakeVariant'] = 'updateContacts';
  ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
  ___arguments.context.pageData['residentPillarRadio'] = (Number((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))));
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/5d9813c61fe7a2714db153121a2967b3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d9813c61fe7a2714db153121a2967b3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d9813c61fe7a2714db153121a2967b3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6768501de35166bcb4e5435779fa0116/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    return 'No Contacts Yet';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    return 'No Contacts';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/c7342965c7ef9d369bb6092ceadb7fe7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      return (['Please enroll a device for this ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' to add contacts!'].join(''));
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    return 'Please add contacts to view and edit!';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e28340a5320ac57378efb282bd63aee4/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    return 'Assign Device';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    return 'Add Contacts';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var contact;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
      ___arguments.context.pageData['isNewResident'] = false;
      ___arguments.context.pageData['residentPillarRadio'] = 1;
      ___arguments.context.pageData['hasStoppedPolling'] = undefined;
      ___arguments.context.pageData['hasAcknowledged'] = undefined;
      ___arguments.context.pageData['hasOnboardStarted'] = undefined;
      ___arguments.context.pageData['residentIntakeStep'] = undefined;
      ___arguments.context.pageData['recordTableVariant'] = 'resident';
      ___arguments.context.pageData['residentIntakeVariant'] = 'pillar';
      ___arguments.context.pageData['scrollToTop'] = true;
      ___arguments.context.pageData['isAddRecordModalOpen'] = true;
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    ___arguments.context.pageData['setAdminContacts'] = true;
    ___arguments.context.pageData['setResidentContacts'] = true;
    ___arguments.context.pageData['isAdminContactsLocked'] = true;
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'updateContacts';
    ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
    ___arguments.context.pageData['residentPillarRadio'] = (Number((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))));
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f6e6a7b51bffa296b4711e467f6d8d86/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6e6a7b51bffa296b4711e467f6d8d86', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6e6a7b51bffa296b4711e467f6d8d86', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/0adf3d9cad95727be039ba42c923b367/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0adf3d9cad95727be039ba42c923b367', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0adf3d9cad95727be039ba42c923b367', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cd1560e1fbee94fe697cf7a4d48ad57f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/630ca103c8e9a6b27deac17f1fe18a8f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a006642b4f42b491eea3a70daa1fbeb7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/43915b08f1ab1df8267ef54df45db25a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.updated'))) {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.created')))).toLocaleDateString());
  } else {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.updated')))).toLocaleDateString());
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ca55c2522abcc51a0f85d56978ca7fc5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.billingName'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/910b24e29ba2dfd7d7b7eaeaffb2e1e7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.billingPhone')) {
    return (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.billingPhone')), 2)), 'create'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/40c6e021ca5c21b11ba62ec289599962/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.taxId'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1ec9ee95d7e91ad2fbf1e6c3aa750fd9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedItemUser, menuOptionSelection, currentPage, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  ___arguments.context.pageData['menuOptionSelection'] = undefined;
  if (currentPage == 'Organization') {
    ___arguments.context.pageData['isOrganizationAccessDisabled'] = false;
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if (selectedItemOrganization) {
      ___arguments.context.pageData['organizationUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemOrganization))));
      (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')), 2)), 'create'));
      ___arguments.context.pageData['isLoadingOrganizationButton'] = undefined;
      ___arguments.context.pageData['addOrganizationStep'] = 1;
      ___arguments.context.pageData['recordTableVariant'] = 'editOrganization';
      ___arguments.context.pageData['isEditOrgButtonDisabled'] = ({ [`orgName`]: true,[`address`]: true,[`website`]: true,[`taxId`]: true,[`billingName`]: true,[`billingPhone`]: true,[`billingEmail`]: true,[`state`]: true,[`zipCode`]: true,[`city`]: true,[`selectedImage`]: true });
    }
  } else if (currentPage == 'User') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
    if (selectedItemUser) {
      ___arguments.context.pageData['selectedItemUserImageFile'] = undefined;
      ___arguments.context.pageData['userUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemUser))));
      (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.phoneNumber')), 2)), 'create'));
      if ((getObjectProperty(selectedItemUser, 'userRole')) == 'admin') {
        ___arguments.context.pageData['selectedItemUserRole'] = 'Administrator';
      } else if ((getObjectProperty(selectedItemUser, 'userRole')) == 'manager') {
        ___arguments.context.pageData['selectedItemUserRole'] = 'Case Manager';
      }
      ___arguments.context.pageData['recordTableVariant'] = 'editUser';
      ___arguments.context.pageData['emailError'] = false;
      ___arguments.context.pageData['isButtonLoading'] = false;
      ___arguments.context.pageData['phoneError'] = false;
      ___arguments.context.pageData['isUserButtonDisabledObject'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`selectedImage`]: true,[`organization`]: true });
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['residentIntakeStep'] = 4;
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }
  ___arguments.context.pageData['scrollToTop'] = true;
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident' || (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization' || (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ec9ee95d7e91ad2fbf1e6c3aa750fd9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ec9ee95d7e91ad2fbf1e6c3aa750fd9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/619201d1788f10db513bcc537df30faf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var toast, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    return 'Edit Org';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      return (String('Edit ') + String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')));
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    return 'Edit User';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/19948d546f7e920b243cc8e5f1fd0181/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    return 'Current Location';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization' || (getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    return 'Organization Location';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    return 'Device Location';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/fc3f55caa424b87cbc53749769e89ab3/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/967a123a33945fe8d872447fa8cff7ee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    classesList = ['incomplete_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING') {
    return 'Invite Sent';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT') {
    return 'Invite Resent';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    return 'Deactivated';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    return 'Locked';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e67d3d92498869872ec4b93b831b1182/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e67d3d92498869872ec4b93b831b1182', false);
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e67d3d92498869872ec4b93b831b1182', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/63b96d4514cce11d27e46f9753f28796/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63b96d4514cce11d27e46f9753f28796', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63b96d4514cce11d27e46f9753f28796', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e24196bcb5eb7dcc8c5ac69324e44233/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'admin') {
    return 'Admin';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'manager') {
    return 'Manager';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a6a56aadc2692be3e111306382399b83/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.phoneNumber')) {
    return (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.phoneNumber')), 2)), 'create'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6f0f5249fd4518699d616fe2d1b433ec/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.updated')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/8d283526ff9fd16adbeac159436d54ea/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  if (selectedItemUser) {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'resendUser';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8d283526ff9fd16adbeac159436d54ea', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8d283526ff9fd16adbeac159436d54ea', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/3de517e18c87b87b3ada14a515835feb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, currentPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'User') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4901a683a71196a4bd37ee0744c79aca/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, currentPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'User') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6917e4374a5eb9090b54b053ab1b15fc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6917e4374a5eb9090b54b053ab1b15fc', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6917e4374a5eb9090b54b053ab1b15fc', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  if (selectedItemUser) {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'cancelUser';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/70c7ad7496c753246d98b7a3e319c3da/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, currentPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'User') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/78ce383cbe8231939bd273f1c9cbecc8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, currentPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'User') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/745ee03c6109c9d65776e087f1fcf099/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('745ee03c6109c9d65776e087f1fcf099', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('745ee03c6109c9d65776e087f1fcf099', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cd9f20d199af7ed19a1fc14fa27e32a1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cd9f20d199af7ed19a1fc14fa27e32a1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cd9f20d199af7ed19a1fc14fa27e32a1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/127542953facea4fdf02767b3d079853/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('127542953facea4fdf02767b3d079853', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('127542953facea4fdf02767b3d079853', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/0706d501aae2081dbfbadf722c13447f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0706d501aae2081dbfbadf722c13447f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0706d501aae2081dbfbadf722c13447f', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/560d782ab5ab03a4e38a77d862fc91f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('560d782ab5ab03a4e38a77d862fc91f6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('560d782ab5ab03a4e38a77d862fc91f6', false);
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d22aa01496582014a8d5e39cb4d9606c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device' || (getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'apps') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d22aa01496582014a8d5e39cb4d9606c', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d22aa01496582014a8d5e39cb4d9606c', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2ee37bbcdb616e5ec1749f2b7d5737fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var pillar;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pillar = (getObjectProperty(___arguments.context.pageData, 'selectedItemDevice.residentId.pillar'));
  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return ([(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',pillar].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/bf8a28b23602535e095ee14a5d2ed8a4/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemDevice.residentId.profilePicture')) {
    return (getObjectProperty(___arguments.context.pageData, 'selectedItemDevice.residentId.profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6a584c047f041272dfb74f3c15414e83/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6a584c047f041272dfb74f3c15414e83', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6a584c047f041272dfb74f3c15414e83', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['confirmationOption'] = 'deactivateDevice';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1d09d885d501e2dc38957022ac0ad896/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://') + String(getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.websiteLink'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/24339194283b6549c9d3aa9531b84403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.address')),' ',(getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.city')),', ',(getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.state')),' ',(getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.zipCode'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6bfb7cf372da2529a3f307eb615d6327/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'homePhone')) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'homePhone')).charAt(0) == '+') {
      return (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'homePhone')), 2)), 'create'));
    } else {
      return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'homePhone'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/08a7e787dd1a81cb82bfec11b9455938/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'cellPhone')) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'cellPhone')).charAt(0) == '+') {
      return (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'cellPhone')), 2)), 'create'));
    } else {
      return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c'), 'cellPhone'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/8903717d2eedf79202d6c5391cdbc83a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDeviceInfoBoxOpen')) {
    ___arguments.context.pageData['isDeviceInfoBoxOpen'] = false;
  } else {
    ___arguments.context.pageData['isDeviceInfoBoxOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/357cd48106adc59f1893c94e0843cced/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDeviceInfoBoxOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('357cd48106adc59f1893c94e0843cced', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('357cd48106adc59f1893c94e0843cced', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4d050b9911a4b57466f38ca344b3e19b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isResidentInfoBoxOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d050b9911a4b57466f38ca344b3e19b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d050b9911a4b57466f38ca344b3e19b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2b69a27d7b0f93d8885bc7dbe15affb8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isResidentInfoBoxOpen')) {
    ___arguments.context.pageData['isResidentInfoBoxOpen'] = false;
  } else {
    ___arguments.context.pageData['isResidentInfoBoxOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/21bf8f5cb1e9234c121611d86dfe660d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isDeviceStatusLoading')) || (getObjectProperty(___arguments.context.pageData, 'deviceStatusError'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('21bf8f5cb1e9234c121611d86dfe660d', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('21bf8f5cb1e9234c121611d86dfe660d', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/03bdf42ae82a3b01f1f95935050ca002/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDeviceStatusLoading')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03bdf42ae82a3b01f1f95935050ca002', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('03bdf42ae82a3b01f1f95935050ca002', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/3df1fe746796967ee00f6943e20a2bbb/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deviceStatusError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3df1fe746796967ee00f6943e20a2bbb', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3df1fe746796967ee00f6943e20a2bbb', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d4ff1667569b3feaa09152ef6dbee1b9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Online') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Sleeping') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Offline') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/10ba71dbe4690c5dc01f302530d8debf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.lastUpdatedAt')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.lastUpdatedAt')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/492c760b57ea28f9c782dd673df1449b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Online') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Sleeping') {
    classesList = ['incomplete_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Offline') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/7b7646ddcbc57ee5e1d9744ab33e6c4c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Online') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Sleeping') {
    classesList = ['incomplete_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Offline') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/aa96c546b3cdae78ed5b990a87c316b4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident' && (getObjectProperty(___arguments.context.pageData, 'loadDeviceDetails'))) {
    ___arguments.context.pageData['loadDeviceDetails'] = undefined;
    await BackendlessUI.Functions.Custom['fn_3f169535b81df1cad6dabe419d59aab3'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceId')));
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6c9c6b543f9ef8ccdde19881b2a9936e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device' && (getObjectProperty(___arguments.context.pageData, 'loadDeviceDetails'))) {
    ___arguments.context.pageData['loadDeviceDetails'] = undefined;
    await BackendlessUI.Functions.Custom['fn_3f169535b81df1cad6dabe419d59aab3'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'selectedItemDevice.deviceId')));
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/fa81e3573b7bb29434c6a10950dc3c0f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDeviceStatusLoading')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fa81e3573b7bb29434c6a10950dc3c0f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fa81e3573b7bb29434c6a10950dc3c0f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2504c0b3f8e1a5570ee2f8c9ad3f3962/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deviceStatusError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2504c0b3f8e1a5570ee2f8c9ad3f3962', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2504c0b3f8e1a5570ee2f8c9ad3f3962', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1ce4ca27b529464b1c96d12fd3cccd46/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isDeviceStatusLoading')) || (getObjectProperty(___arguments.context.pageData, 'deviceStatusError'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ce4ca27b529464b1c96d12fd3cccd46', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ce4ca27b529464b1c96d12fd3cccd46', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e0cb1eb6fbb81494509f3b88c0c6ad0d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Online') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Sleeping') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Offline') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/946519f5203f1c05afe5fbcdb6824d5a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Online') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Sleeping') {
    classesList = ['incomplete_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Offline') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d54857c35d7f3c0f78ea9e672af7614e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Online') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Sleeping') {
    classesList = ['incomplete_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.status')) == 'Offline') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/b8a778a4418631dbc741f584219de795/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.lastUpdatedAt')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.pageData, 'deviceStatusInfo.lastUpdatedAt')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/63c46f5d72c2aef156c82d3b7965822e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String('Days In ') + String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/b8b7802ad4f3b9b323427bb318410251/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String('Current ') + String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/c7dcee36c0efdb4a455ca5a2abc9c514/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (['Where is the ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' coming from or who sent them?'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/7f31026d70787b97207247bd7c22a5e5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'loadLocationMap')) {
    ___arguments.context.pageData['loadLocationMap'] = false;
    await BackendlessUI.Functions.Custom['fn_ebd5de0ec38f9456d8b93d611c7eab5a'](___arguments.context.pageData, ___arguments.context.appData);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2608e35d4ecea5ccf348ec138fb30001/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (['Please enroll a device for this ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' to see the app records!'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_4dc186e635b9640a4ac64eac5dcaa133/logic/cb0b9eb74aa6bb21bbd534d433fc0cd4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation')) || (getObjectProperty(___arguments.context.pageData, 'isMapLoading')) || (getObjectProperty(___arguments.context.pageData, 'locationError'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cb0b9eb74aa6bb21bbd534d433fc0cd4', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cb0b9eb74aa6bb21bbd534d433fc0cd4', true);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onMapCenterAssignment */
  ['onMapCenterAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation')) {
    return ((getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation'))[0]);
  }

  },
  /* handler:onMapCenterAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/8346b4638a33474b66e4a65a60c2de7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '1') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/37dc31b43bbff1835df413f877b5cc0b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '1') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 1'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/2b4c901ae4b5e4f687ea8ca6e0196bad/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '2') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/60c46e4b8a75a7debe024b88d32faadd/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '2') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 2'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/bb8f46685c5aad1e32f846ed27265933/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '3') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/9786265e81ba6a7b0fce0fcc74da95d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '3') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 3'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/893ed7e5ee6aea4b74546026e144e6fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '4') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/de4b56a94f2a6737bd7eaf6af2216624/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '4') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) + String(' 4'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/51cea94202ab4271bf96c16fcc198b0f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var toast;

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['curfewPillarSelectionBar'] = '1';
  ___arguments.context.pageData['hasNoCurfews'] = false;
  ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: 1 });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f34f098a05246192c74b914403dbb916/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var toast;

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['hasNoCurfews'] = false;
  ___arguments.context.pageData['curfewPillarSelectionBar'] = '2';
  ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: 2 });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/219440be1c8e3c21360bb2e9c6886964/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var toast;

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['hasNoCurfews'] = false;
  ___arguments.context.pageData['curfewPillarSelectionBar'] = '3';
  ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: 3 });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/96ff3a1f4f27a88f8e24f2dcf0c3d8e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var toast;

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['hasNoCurfews'] = false;
  ___arguments.context.pageData['curfewPillarSelectionBar'] = '4';
  ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: 4 });

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/acdfee280bb6bb64995f879aed91e5fa/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  if (currentUser) {
    try {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error loading curfew table' }));

    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f598cfffee39e20acd3f1d3831af8597/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'curfewTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/a0bb0fae2fc6ef994b39fdc2b0660422/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0bb0fae2fc6ef994b39fdc2b0660422', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0bb0fae2fc6ef994b39fdc2b0660422', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/73324fa55fc9a7ee1973d302febfbd63/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classesList = [];
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/8cefc8d2ed6af35f7d1fded6abfd54a4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/1c89cf08fadc9571a10d99cb825a986c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemCurfew'] = ___arguments.context.dataModel;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/9e2137976b4147b923d6b3a8cf4b9714/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['selectedItemArrayCurfew'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/503b082906ad78c83ee87fac82c6ee1f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshCurfewTable, curfewList, pillar, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshCurfewTable2() {
  try {
    ___arguments.context.pageData['refreshCurfewTable'] = undefined;
    ___arguments.context.pageData['isTableLoading'] = true;
    currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
    pillar = (getObjectProperty(refreshCurfewTable, 'pillar'));
    if (currentUser) {
      curfewList = (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, pillar))).setSortBy('created asc').setPageSize(10)));
      ___arguments.context.pageData['curfewTableCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, pillar)))));
      if ((getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) == 0) {
        ___arguments.context.pageData['hasNoCurfews'] = true;
      } else {
        (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
        (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', curfewList);
        ___arguments.context.pageData['isTableLoading'] = false;
      }
    }

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load curfew table.', 'failure');

  }
}


  refreshCurfewTable = (getObjectProperty(___arguments.context.pageData, 'refreshCurfewTable'));
  if (refreshCurfewTable) {
    await refreshCurfewTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f207ff585a1b94cdc697a475707861f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['curfewStep'] = 1;
  ___arguments.context.pageData['curfewNameError'] = undefined;
  ___arguments.context.pageData['curfewObjectId'] = undefined;
  ___arguments.context.pageData['timeDrawerOneTime'] = undefined;
  ___arguments.context.pageData['timeDrawerTwoTime'] = undefined;
  ___arguments.context.pageData['curfewName'] = undefined;
  ___arguments.context.pageData['pillarRadio'] = undefined;
  ___arguments.context.pageData['curfewDayArray'] = [];
  ___arguments.context.pageData['curfewPillarArray'] = [];
  ___arguments.context.pageData['recordTableVariant'] = 'curfew';
  ___arguments.context.pageData['curfewTableVariant'] = 'add';
  ___arguments.context.pageData['scrollToTop'] = true;
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f3f1a243dce710bafcd8ec185f8a3c91/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var timeObject, dayText, endTime, startTime, item, dayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  timeObject = (JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'times'))));

  return ([(await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(timeObject, 'startTime')))),' to ',(await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(timeObject, 'endTime'))))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/188acd792415339a094be9e73bb77cad/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasNoCurfews')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('188acd792415339a094be9e73bb77cad', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('188acd792415339a094be9e73bb77cad', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/88479c3fbf11de8fba738cb229681cba/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var day, dayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dayArray = (await Promise.all((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'days')))), 'dayArray')).map(async day => {  if (day == 'Monday') {
      return 'Mon';
    } else if (day == 'Tuesday') {
      return 'Tues';
    } else if (day == 'Wednesday') {
      return 'Wed';
    } else if (day == 'Thursday') {
      return 'Thurs';
    } else if (day == 'Friday') {
      return 'Fri';
    } else if (day == 'Saturday') {
      return 'Sat';
    } else if (day == 'Sunday') {
      return 'Sun';
    }
  ; return ;})));
  if (dayArray.length == 7) {
    return '[Repeat Every Day]';
  } else {
    return (['[',dayArray.join(', '),']'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/1159b23de7d3d6432be423dfba17338f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isViewDetailsModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/5469b8ae47ec85bbee998ef30579b373/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) && (getObjectProperty(___arguments.context.appData, 'pillarApplicationName'))) {
    return (['List of Curfews for ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',(getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/df8ff35a27989873e9d3ec439b635a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isViewDetailsModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f275b4887149189fa2b3e04baa237397/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasNoCurfews')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f275b4887149189fa2b3e04baa237397', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f275b4887149189fa2b3e04baa237397', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/b658ec5c53e1b0cac669899719effa37/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasNoCurfews')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b658ec5c53e1b0cac669899719effa37', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b658ec5c53e1b0cac669899719effa37', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/83a60166def776bed6e75a5ebbe81ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'pillarApplicationName')) {
    return (['Click the ‘Add’ button below to define a new curfew for ',(getObjectProperty(___arguments.context.appData, 'pillarApplicationName')),' ',(getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/c9ef0caee10266e70e524ef9e5235b87/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['curfewStep'] = 1;
  ___arguments.context.pageData['curfewNameError'] = undefined;
  ___arguments.context.pageData['curfewObjectId'] = undefined;
  ___arguments.context.pageData['timeDrawerOneTime'] = undefined;
  ___arguments.context.pageData['timeDrawerTwoTime'] = undefined;
  ___arguments.context.pageData['curfewName'] = undefined;
  ___arguments.context.pageData['pillarRadio'] = undefined;
  ___arguments.context.pageData['curfewDayArray'] = [];
  ___arguments.context.pageData['curfewPillarArray'] = [];
  ___arguments.context.pageData['recordTableVariant'] = 'curfew';
  ___arguments.context.pageData['curfewTableVariant'] = 'add';
  ___arguments.context.pageData['scrollToTop'] = true;
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/b134b6bf0335848408d17df19247322e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var noResultsScreen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  noResultsScreen = (getObjectProperty(___arguments.context.pageData, 'noResultsScreen'));
  if (noResultsScreen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b134b6bf0335848408d17df19247322e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b134b6bf0335848408d17df19247322e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/9ec5c00b4c128954e45dd2e1c37899e3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var currentPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'Organization') {
    return 'No Organizations Created';
  } else if (currentPage == 'Resident') {
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      return (['No ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),'s Added'].join(''));
    }
  } else if (currentPage == 'User') {
    return 'No Users Added';
  } else if (currentPage == 'Device') {
    return 'No Device Records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/59af09a2fa337bfbb89fbccea4044508/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var currentPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'Organization') {
    return 'There are currently no organizations in your account. Please click the \'Create Organization\' button to add your first organization.';
  } else if (currentPage == 'Resident') {
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      return (String('Please add a new ') + String((getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase()));
    }
  } else if (currentPage == 'User') {
    return 'There are currently no users in your account. Please click the \'Add User\' button to add your first user.';
  } else if (currentPage == 'Device') {
    if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
      return (['No records to display. Please assign the device to a ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')).toLowerCase(),' to view the device records here.'].join(''));
    }
    return 'No records to display. Please assign the device to a resident to view the device records here.';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/b7dcc91569b221424f2cc6d63fafd549/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var currentPage, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
    if (currentPage == 'Organization') {
      return 'Create Organization';
    } else if (currentPage == 'User') {
      return 'Add User';
    }
  } else {
    if (currentPage == 'Resident') {
      return 'Start Intake';
    } else if (currentPage == 'Device') {
      if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
        return (['Go To ',(getObjectProperty(___arguments.context.appData, 'residentApplicationName')),' Page'].join(''));
      }
    } else if (currentPage == 'User') {
      return 'Add Team Member';
    }
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var currentPage, currentUser, recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  ___arguments.context.pageData['addOrganizationStep'] = 1;
  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  currentPage = (getObjectProperty(___arguments.context.pageData, 'currentPage'));
  if (currentPage == 'Organization') {
    ___arguments.context.pageData['scrollToTop'] = true;
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
    ___arguments.context.pageData['addOrganizationStep'] = 1;
    ___arguments.context.pageData['organizationUpdateObject'] = ({  });
    ___arguments.context.pageData['recordTableVariant'] = 'addOrganization';
    ___arguments.context.pageData['selectedItemOrganization.state'] = 'State';
  } else if (currentPage == 'User') {
    ___arguments.context.pageData['recordTableVariant'] = 'addUser';
    ___arguments.context.pageData['userUpdateObject'] = ({  });
    ___arguments.context.pageData['selectedItemUserRole'] = 'User Role';
    ___arguments.context.pageData['selectedItemUser'] = undefined;
    ___arguments.context.pageData['userRoleState'] = undefined;
    ___arguments.context.pageData['organizationState'] = undefined;
    ___arguments.context.pageData['selectedItemUser.orgName'] = 'Organization';
    ___arguments.context.pageData['isUserButtonDisabledObject'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`organization`]: true });
    ___arguments.context.pageData['scrollToTop'] = true;
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  } else if (currentPage == 'Device') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Resident', undefined);
  } else if (currentPage == 'Resident') {
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'intake';
    ___arguments.context.pageData['residentIntakeStep'] = 1;
    ___arguments.context.pageData['isNewResident'] = true;
    ___arguments.context.pageData['selectedResidentImage'] = undefined;
    ___arguments.context.pageData['selectedResidentImageFile'] = undefined;
    ___arguments.context.pageData['residentIntakeForm'] = ({ [`residentObjectId`]: undefined,[`pillarInfo`]: undefined,[`profilePicture`]: undefined,[`residentIntakeStep`]: undefined,[`personalDetails`]: ({ [`race`]: ({ [`answer`]: 'default',[`other`]: undefined }),[`genderIdentity`]: ({ [`answer`]: undefined,[`other`]: undefined }),[`pronouns`]: ({ [`answer`]: undefined,[`other`]: undefined }) }),[`emergencyContact`]: ({ [`relationship`]: ({ [`answer`]: undefined,[`other`]: undefined }) }) });
    ___arguments.context.pageData['scrollToTop'] = true;
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/a3f49b76991d9971da0897e9c15c357a/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'deviceRecords') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-no-device-found.png';
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-no-results.png';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/775a50b3c048a36a9453b5a432ed8e1a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'currentUser')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('775a50b3c048a36a9453b5a432ed8e1a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('775a50b3c048a36a9453b5a432ed8e1a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/4894157cc3396a8c3a8885ecb55a9aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4894157cc3396a8c3a8885ecb55a9aa8', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4894157cc3396a8c3a8885ecb55a9aa8', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/4d9f094d1731f93894372453a4a37819/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Dashboard') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/c0452a05df2e617fc2d1b532ad1f09e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('User', undefined);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'admin' || (getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0452a05df2e617fc2d1b532ad1f09e6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0452a05df2e617fc2d1b532ad1f09e6', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/fd61b8a9c7af8609e9eaf255b7b7b189/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/22fbd7decedcd6416231d35b9934e0f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'User') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'admin') {
    return 'Team Members';
  } else if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    return 'User Records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/1e1db38d74c56d4fd1a1c9474e99e766/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Resident', undefined);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1e1db38d74c56d4fd1a1c9474e99e766', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1e1db38d74c56d4fd1a1c9474e99e766', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/b4cae29ff09f11336acc3683bd1a1d62/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/f5f409b1236aaefd1bd57f616ff7b32f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Resident') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'residentApplicationName')) {
    return (String(getObjectProperty(___arguments.context.appData, 'residentApplicationName')) + String(' Records'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/917563d4b8124614d5a39ca40df6a27d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Device', undefined);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('917563d4b8124614d5a39ca40df6a27d', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('917563d4b8124614d5a39ca40df6a27d', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/afef63b4c582819c8f7a33f139433606/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Curfew', undefined);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('afef63b4c582819c8f7a33f139433606', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('afef63b4c582819c8f7a33f139433606', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/5a2910f29c23c8c8f991b0db299ec6e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Curfew') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/57ca5db577a78642ae8f06f6423b9fe8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'supportGuide';
  localStorage.setItem('navbarSelection', JSON.stringify('supportGuide'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/c8497b1a481b2f277e03e80eccf6eea9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'supportGuide') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8497b1a481b2f277e03e80eccf6eea9'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8497b1a481b2f277e03e80eccf6eea9'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/6a9cbef46aafb135f7fc7e0bd8f8d785/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'supportGuide') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6a9cbef46aafb135f7fc7e0bd8f8d785'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6a9cbef46aafb135f7fc7e0bd8f8d785'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/0a228ff669212204054b6e79fa890cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isLogoutModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/2e4f18e60dacf966dd61b1ffa7ac1bce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Organization', undefined);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var superUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2e4f18e60dacf966dd61b1ffa7ac1bce', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2e4f18e60dacf966dd61b1ffa7ac1bce', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/d7eda1d48d2dc88a169f6ab585fdc493/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/d9834bfff3311e7591c918cb85a80b34/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Organization') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/31f87d7284997ae19e53ddda03321d3c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Dashboard') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/3894d01d324783cf74c1e3522c313696/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Curfew') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/dff394caee5a55ae8ca5005a020ff013/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/2e66840e5c0ea8f51855a8936e4cf029/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentPage')) == 'Device') {
    classes = ['blue_text'];
  } else {
    classes = ['black_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/1b474d4795335c2d3448a8893c76205e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, customerID, userEmail;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userEmail = 'iorr@phxdreamcenter.org';
  try {
    customerID = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/customer-id`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(userEmail)));
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/customer-portal`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(customerID))), 'body')), true, undefined);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to get stripe information.', 'failure');

  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1b474d4795335c2d3448a8893c76205e', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1b474d4795335c2d3448a8893c76205e', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/1163b27d734e86ebf549c55ac1830c8e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'currentUser')) {
    try {
      await (async function(App_Data) {

      	// User information - Replace these with actual user data
      	const user = App_Data.currentUser;

      	// Function to initialize and open Intercom chat
      	function initializeIntercom() {
      	  window.intercomSettings = {
      	    api_base: "https://api-iam.intercom.io",
      	    app_id: "nuppfi6g",
      	    user_id: user.objectId,
      	    name: user.name,
      	    email: user.email,
      	    created_at: user.created
      	  };

      	  // Ensure Intercom widget is loaded
      	  (function() {
      	    var w = window;
      	    var ic = w.Intercom;

      	    if (typeof ic === "function") {
      	      // If Intercom is already available, update and show chat
      	      ic("update", window.intercomSettings);
      	      ic("show"); // Open chat immediately
      	    } else {
      	      // Load Intercom script
      	      var d = document;
      	      var i = function() {
      	        i.c(arguments);
      	      };
      	      i.q = [];
      	      i.c = function(args) {
      	        i.q.push(args);
      	      };
      	      w.Intercom = i;

      	      // Create script element
      	      var l = function() {
      	        var s = d.createElement("script");
      	        s.type = "text/javascript";
      	        s.async = true;
      	        s.src = "https://widget.intercom.io/widget/nuppfi6g";
      	        s.onload = function() {
      	          // After the script is fully loaded
      	          w.Intercom("update", window.intercomSettings);
      	          w.Intercom("show"); // Open chat after loading
      	        };
      	        var x = d.getElementsByTagName("script")[0];
      	        x.parentNode.insertBefore(s, x);
      	      };

      	      // Check when to load the script
      	      if (document.readyState === "complete") {
      	        l();
      	      } else if (w.attachEvent) {
      	        w.attachEvent("onload", l);
      	      } else {
      	        w.addEventListener("load", l, false);
      	      }
      	    }
      	  })();
      	}

      	// Initialize and open the Intercom chat
      	initializeIntercom();

      })(___arguments.context.appData);

    } catch (error) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Failed to load Intercom chat.', 'failure');

    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1163b27d734e86ebf549c55ac1830c8e', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1163b27d734e86ebf549c55ac1830c8e', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_159f281577a2fcad373d16f277aed34e/logic/67b4671e7a1b9e8ac034b80ad9582cbb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.open("https://help.aquax.co", "_open")
  })();

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('67b4671e7a1b9e8ac034b80ad9582cbb', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('67b4671e7a1b9e8ac034b80ad9582cbb', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/e2491ddd1fe17b2af687920b54ae1188/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isUserMenuOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isUserMenuOpen = (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen'));
  if (isUserMenuOpen) {
    ___arguments.context.pageData['isUserMenuOpen'] = false;
  } else {
    ___arguments.context.pageData['isUserMenuOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/d29296736c5cce076c660a61ba2b523f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  if (currentUser) {
    return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(currentUser, 'name')), 20));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/28d5674ccdfb6d3cea6fcae86e2e49ee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var userType, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  if (currentUser) {
    userType = (getObjectProperty(currentUser, 'userRole'));
    if (userType == 'manager') {
      return 'Case Manager';
    } else if (userType == 'admin') {
      return 'Administrator';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/6e244f679043727b2685bbfb4a59d0b4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b5ffc96e4915d7c9b89100e764c1314b', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b5ffc96e4915d7c9b89100e764c1314b', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/b5ffc96e4915d7c9b89100e764c1314b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e244f679043727b2685bbfb4a59d0b4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e244f679043727b2685bbfb4a59d0b4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/4a267f32d57867fbdc1eda5e243a84ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  ___arguments.context.pageData['residentIntakeVariant'] = undefined;
  ___arguments.context.pageData['selectedItemUserImageFile'] = undefined;
  ___arguments.context.pageData['selectedItemUserRole'] = (getObjectProperty(___arguments.context.appData, 'currentUser.userRole'));
  ___arguments.context.pageData['selectedItemUser'] = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  ___arguments.context.pageData['userUpdateObject'] = (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.appData, 'currentUser'))))));
  (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.phoneNumber')), 2)), 'create'));
  if ((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.userRole')) == 'admin') {
    ___arguments.context.pageData['selectedItemUserRole'] = 'Administrator';
  } else if ((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.userRole')) == 'manager') {
    ___arguments.context.pageData['selectedItemUserRole'] = 'Case Manager';
  }
  ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
  ___arguments.context.pageData['recordTableVariant'] = 'viewProfile';
  ___arguments.context.pageData['emailError'] = false;
  ___arguments.context.pageData['isButtonLoading'] = false;
  ___arguments.context.pageData['phoneError'] = false;
  ___arguments.context.pageData['isUserButtonDisabledObject'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`selectedImage`]: true,[`organization`]: true });
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/682e361bae28513beaa3123751045f8e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedItemOrganization, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  ___arguments.context.pageData['residentIntakeVariant'] = undefined;
  if (currentUser) {
    ___arguments.context.pageData['isOrganizationAccessDisabled'] = true;
    selectedItemOrganization = (getObjectProperty(currentUser, 'organizationId'));
    if (selectedItemOrganization) {
      ___arguments.context.pageData['organizationUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemOrganization))));
      (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')), 2)), 'create'));
      ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
      ___arguments.context.pageData['addOrganizationStep'] = 1;
      ___arguments.context.pageData['recordTableVariant'] = 'viewOrganization';
      ___arguments.context.pageData['isEditOrgButtonDisabled'] = ({ [`orgName`]: true,[`address`]: true,[`website`]: true,[`taxId`]: true,[`billingName`]: true,[`billingPhone`]: true,[`billingEmail`]: true,[`state`]: true,[`zipCode`]: true,[`city`]: true,[`selectedImage`]: true });
    }
  }
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/eedf98a4d0ec1824de22b3a34123cad6/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.appData, 'currentUser'));
  if (currentUser) {
    if (getObjectProperty(currentUser, 'profilePicture')) {
      return (getObjectProperty(currentUser, 'profilePicture'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/116ceca14d0adf6456f5fc2b73b41b5d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('116ceca14d0adf6456f5fc2b73b41b5d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('116ceca14d0adf6456f5fc2b73b41b5d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/d1280ec811ebe74f7b0e6a8154c56d67/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'currentUser')) {
    if ((getObjectProperty(___arguments.context.appData, 'currentUser.userRole')) == 'super') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3cdd46f5c3a31f344b7aac7bb7a2d7', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d21c3875569cd125f40480fdbd4865ce', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3cdd46f5c3a31f344b7aac7bb7a2d7', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d21c3875569cd125f40480fdbd4865ce', true);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;


  if ((function () { return BackendlessUI.isProd })()) {
    classes = ['header', 'header_display_none'];
  } else {
    classes = ['header'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/dac204aac16b4a4680eb1ec763a9d5ba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);
  ___arguments.context.pageData['refreshResidentGraph'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_827189cea4d96ee5531fade4feaedd05/logic/a1566914f8d492f5dcbfc4db2f145327/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'switchToDesktop')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1566914f8d492f5dcbfc4db2f145327', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1566914f8d492f5dcbfc4db2f145327', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

